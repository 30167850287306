import React from "react";
import MetaTags from 'react-meta-tags';
import {UserCompanyRoleListComponent} from "ppm-react-authentication";
import {USERS_COMPANY_ROLES_API_URL, USERS_COMPANY_API_URL} from "../../helpers/api-urls";
import { Link } from "react-router-dom";
import AuthorizationChecker from "security/AuthorizationChecker";
import { withTranslation } from "react-i18next";

const PagesCompanyUsers = (props) => {

    const authUser = JSON.parse(localStorage.getItem("authUser"));
    return (
    <>
        <div className="page-content">
            <MetaTags>
                <title>{props.t("COMPANY")} {props.t("USERS")}</title>
            </MetaTags>
                
            <UserCompanyRoleListComponent
                apiUrl={USERS_COMPANY_API_URL}
                apiUserCompanyRole={USERS_COMPANY_ROLES_API_URL}
                addUrl="/pages-company-users-roles/add"
                path="/pages-company-users-roles"
                cieId={authUser.companyId}
                Link={Link}
                showAdd = {AuthorizationChecker.isGranted(["ADD_USERS_COMPANY"])} 
                showEdit = {AuthorizationChecker.isGranted(["EDIT_USERS_COMPANY"])} 
                showView = {AuthorizationChecker.isGranted(["READ_USERS_COMPANY"])} 
                showDelete = {AuthorizationChecker.isGranted(["DELETE_USERS_COMPANY"])} 
            />
        </div>
    </>
    )
}

export default withTranslation()(PagesCompanyUsers)
