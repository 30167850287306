import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import UserProvier from "security/UserProvier";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
//import action
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//i18n
import { withTranslation } from "react-i18next";
//redux
import AuthorizationChecker from "security/AuthorizationChecker";
import CardToAssign from "pages/Supplier/card-to-assign";
import CardDownloadDoc from "pages/Users/card-download-doc";

const Dashboard = props => {

  return (
    <React.Fragment>
      <div className="page-content" >
        <MetaTags>
          <title>LGVP</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />
          
          <Row>
          {AuthorizationChecker.isGranted(["BUYER_APPROVE_VENDOR"]) ?
                      <CardToAssign /> : ""}
                        
          {AuthorizationChecker.isGranted(["VENDOR_CREATE_PROJECT"]) ?
          <CardDownloadDoc /> : ""}
          

          </Row>
         </Container>
        </div> 
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
};

export default withTranslation()(Dashboard);
