import React from "react"
import PropTypes from "prop-types"
import { Card, CardBody, CardTitle, Table } from "reactstrap"
import { Link } from "react-router-dom"
import { map } from "lodash"
import images from "assets/images"
import { withTranslation } from "react-i18next";
const TeamMembers = ( props ) => {
  const {team } = props
  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">{props.t("_PROJECT_MEMBERS")}</CardTitle>

        <div className="table-responsive">
          <Table className="table align-middle table-nowrap">
            <tbody>
              {map(team, (member, k) => (
                <tr key={"_member_" + k}>
                  <td style={{ width: "50px" }}>
                    {member.img !== "Null" ? (
                      <img
                        src={images[member.img]}
                        className="rounded-circle avatar-xs"
                        alt=""
                      />
                    ) : (
                      <div className="avatar-xs">
                        <span className="avatar-title rounded-circle bg-primary text-white font-size-16">
                          {member.skills[0].name.charAt(0).toUpperCase()}
                        </span>
                      </div>
                    )}
                  </td>
                  <td>
                    <h5 className="font-size-14 m-0">
                      <Link to="" className="text-dark">
                        {member.name}
                      </Link>
                    </h5>
                  </td>
                  <td>
                    <div>
                      {map(member.skills, (skill, key) => (
                        <Link
                          to="#"
                          className="badge bg-primary bg-soft text-primary font-size-11 me-1"
                          key={"_skill_" + key}
                        >
                          {skill.name}
                        </Link>
                      ))}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  )
}

TeamMembers.propTypes = {
  team: PropTypes.array,
  t: PropTypes.any
}

export default withTranslation()(TeamMembers)
//export default TeamMembers
