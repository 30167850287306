/*
*this page is for download  documentation from api 
*/
import React, { Component,  useState,useEffect } from 'react';
import { Card, CardBody, CardTitle, Col, Container, Row ,Table} from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import axios from "axios";
import {HTTP_RESPONSE} from "../Utility/Helpers/constants";
import fileDownload from 'js-file-download';



    const CardDownloadDoc = (props) => {
    const authUser = JSON.parse(localStorage.getItem('authUser'));
    const company = authUser.companyId;
    const [files, setFiles] = useState([]);
    //download with axios file from /api/userlg/download/:files
 useEffect(async () => {
    //get the file liste from api /api/file-explorer/VendorFiles
    const url = process.env.REACT_APP_API_BASE_URL + 'file-explorer/VendorFiles';
    await axios.get(url).then(async response => {
        console.log(response);
        if (response.status === 200) {
            const transformedData = await transformData(response.data);
            setFiles(transformedData);
        }
    }).catch(error => {
        console.log(error);
    });

 }, []);

 const getFileIcon = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    switch(extension) {

        case 'xlsx':
            return "mdi mdi-file-excel font-size-16 align-middle text-primary me-2";
        case 'pdf':
            return "mdi mdi-file-pdf font-size-16 align-middle text-primary me-2";
        case 'doc':
            return "mdi mdi-file-word font-size-16 align-middle text-primary me-2";
        case 'ppt':
            return "mdi mdi-file-powerpoint font-size-16 align-middle text-primary me-2";
        case 'zip':
            return "mdi mdi-file-zip font-size-16 align-middle text-primary me-2";
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
            return "mdi mdi-file-image font-size-16 align-middle text-primary me-2";
      
        default:
            return "mdi mdi-file-document font-size-16 align-middle text-primary me-2";
    }
  };
  
  // Usage
  
 const transformData = (data) => {
    return data.map((item, index) => {
      return {
        id: index + 1,
        icon: getFileIcon(item.name),
        file: item.name,
        path: item.path,
        date: new Date(item.createdAt).toLocaleDateString("fr-FR", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        }),
        size: (item.size / 1000).toFixed(2) + " KB", // convert size to KB
      };
    });
  };
  



    const download = async(file) => {
        const url = process.env.REACT_APP_API_BASE_URL + 'userlg/download/f=' + encodeURIComponent(file);
        //get the name of the file
        const fileName = file.split('/').pop();
        axios({
            url: url,
            method: 'GET',
            responseType: 'blob', // Important
          }).then((response) => {
            fileDownload(response.data, fileName);
          });



        
    
    }

    


    return (
        <React.Fragment>
        <div className="page-content">
            <Container fluid>
            <Row>
                <Col>
                <Card>
                    <CardBody>
                    <CardTitle className="mb-4">{props.t("DOWNLOAD_DOC")}</CardTitle>

                    <div className="table-responsive">
            <Table className="table align-middle table-nowrap table-hover mb-0">
              <thead>
                <tr>
                  <th scope="col">{props.t("_NAME_")}</th>
                  <th scope="col">{props.t("_LAST_MODIFIED_DATE")}</th>
                  <th scope="col" colSpan="2">
                    {props.t("_SIZE_")}
                  </th>
                </tr>
              </thead>
              <tbody>
              {files.map((recentfile, key) => (
                  <tr key={key}>
                    <td>
                      <Link to="#" className="text-dark fw-medium">
                        <i className={recentfile.icon}></i> {recentfile.file}
                      </Link>
                    </td>
                    <td>{recentfile.date}</td>
                    <td>{recentfile.size}</td>
                    <td>
                        <Link
                            to="#"
                            className="bx bx-download waves-effect waves-light"
                            onClick={() => download(recentfile.path)}
                        >
                            {props.t("_DOWNLOAD_")}
                        </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            </div>

                    
                    </CardBody>
                </Card>
                </Col>
            </Row>
            </Container>
        </div>
        </React.Fragment>
    );
}
export default withRouter(withTranslation()(CardDownloadDoc));