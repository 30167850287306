import axios from "axios";
import {HTTP_RESPONSE} from "../../Utility/Helpers/constants";
import UserProvier from "../../../security/UserProvier";
UserProvier.setToken();

export const DATA_API_URL = process.env.REACT_APP_API_BASE_URL + 'userlg';
export const DATA_API_URL_UCS = process.env.REACT_APP_API_BASE_URL +'user-company-suppliers'
export const DATA_API_URL_UCR = process.env.REACT_APP_API_BASE_URL +'user-company-roles'
export const VENDOR_ROLE_RENS_ID= process.env.REACT_APP_VENDOR_ROLE_RENS_ID;
export const VENDOR_ROLE_MONDOU_ID = process.env.REACT_APP_VENDOR_ROLE_MONDOU_ID;


const queries = (fkCieId = 1,enable = '') => {

    return DATA_API_URL + '?filter[where][fkCieId]='+fkCieId+'&filter[where][enabled]='+enable;

}
const queries_ucs =(userCompanyId) =>{
    return DATA_API_URL_UCS + '?filter[where][userCompanyId]='+ userCompanyId;
}

const UsersRepository = {
    //find role by name
    findRoleByName : async (name) => {
        try {
            const response = await axios
                .get(DATA_API_URL + '/role/' + name);
            return response.data;
        } catch (e) {
            if (e.response) {
                throw e.response;
            } else {
                throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND };
            }
        }
    },
    


    flush : async (item ) => {
        try {
            const response = await axios
                .post(DATA_API_URL_UCR + '', item);
                console.log(response);
            return response.data;
        } catch (e) {
            if (e.response) {
                throw e.response;
            } else {
                throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND };
            }
        }
      },


    findToEnable : async (fkCieId, enable = 0) =>{
        try {
            const response = await axios
                .get(queries(fkCieId, enable));
            return response.data;
        } catch (e) {
            if (e.response) {
                throw e.response;
            } else {
                throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND };
            }
        }

    },
    requestAccess : async (cieId) => {
        try {
        
            const response =  await axios
                .get(DATA_API_URL+ '/requestcie/'+cieId);
                console.log(response);
            return response;
        } catch (e) {
            if (e.response) {
                throw e.response;
            } else {
                throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND };
            }
        }
      },
    
    findwithCie : async (id,cieId ) => {

        try {
            console.log("on passe ici user avec cie?")
            const response =  await axios
                .get(DATA_API_URL+ '/' + id+'/'+cieId);
                console.log(response.data[0]);
            return response.data[0];
        } catch (e) {
            if (e.response) {
                throw e.response;
            } else {
                throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND };
            }
        }
      },
      findByRoleCie : async (id,cieId ) => {

        try {
            console.log("on passe ici user role avec cie?")
            const response =  await axios
                .get(DATA_API_URL+ '/getuserbyrole/' + id+'/'+cieId);
                for (let index = 0; index < response.data.length; ++index) {
                    const element = response.data[index];
                    const sup = await axios
                    .get (DATA_API_URL_UCS +'/user-company/'+element.user_company );
                    response.data[index].suppliers = sup.data;
                    // ...use `element`...
                }
            return response.data;
        } catch (e) {
            if (e.response) {
                throw e.response;
            } else {
                throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND };
            }
        }
      },

      findSupplier : async (id ) => {

        try {
            
            const response =  await axios
            .get (DATA_API_URL_UCS +'/user-company/'+id );
                //console.log(response);
            return response.data;
        } catch (e) {
            if (e.response) {
                throw e.response;
            } else {
                throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND };
            }
        }
      },
      findSuppliertoDelete : async (id ) => {

        try {
            
            const response =  await axios
            .get (queries_ucs(id) );
                //console.log(response);
            return response.data;
        } catch (e) {
            if (e.response) {
                throw e.response;
            } else {
                throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND };
            }
        }
      },


      delsupplier : async (cieId,id) => {
        try {
              const response = await axios
                  .delete(DATA_API_URL_UCS + '/' + cieId + '/'+id) .then((response) => {
                    return id
                  })
              
          } catch (e) {
              if (e.response) {
                
            
                  throw e.response;
              } else {
                  throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND };
              }
          }
      },
      //send request for lost password @param email
        lostPassword : async (email) => {
            try {
                const response = await axios
                    .post(DATA_API_URL + '/forgotpassword', {email: email});
                return response.data;
            } catch (e) {
                if (e.response) {
                    throw e.response;
                } else {
                    throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
                }
            }
        },
        //check if the loast password token is valid
        verify : async (token) => {
            try {
                const response = await axios
                    .get(DATA_API_URL + '/verify/' + token);
                  
                return response;
            } catch (e) {
                console("erreur dans repository");
                if (e.response) {
                    throw e.response;
                } else {
                    throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
                }
            }
        },

    find : async (id ) => {

        try {
            console.log("on passe ici user?")
            const response =  await axios
                .get(DATA_API_URL+ '/' + id);
                console.log(response);
            return response.data;
        } catch (e) {
            if (e.response) {
                throw e.response;
            } else {
                throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND };
            }
        }
      },
      me :  () => {
        
            return axios
                  .get(DATA_API_URL + '/me').then(response => {
                    return response.data;
                }).catch((e) => {
                    if (e.response) {
                        throw e.response;
                    } else {
                        throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
                    }
                });
              
        
      },
      edit : async (item) => {
        console.log(item);
        try {
              const response = await axios
                  .patch(DATA_API_URL + '/' + item.id, item);
              return response.data;
          } catch (e) {
              if (e.response) {
                  throw e.response;
              } else {
                  throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND };
              }
          }
      }
  
    

}
export default UsersRepository;