import {default as React} from 'react';
import Moment from "react-moment";

/**
 *
 * @param props
 * @constructor
 */
const DateComponent = (props) => {
    const {date = new Date(), format = 'YYYY-MM-DD', locale = 'fr'} = props;
    return <>
        <Moment date={date} format={format} locale={locale}/>
    </>
};

// export default withRouter(DateComponent);
export default DateComponent;
