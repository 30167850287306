import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React from "react";
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { withRouter, Link } from "react-router-dom";
import {useEffect} from "react";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { userForgetPassword } from "../../store/actions";
import {PARAMETERS_API_URL, SERVER_BASE_URL, USERS_API_URL,COMPANY_API_URL} from "../../helpers/api-urls";
// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo-ppm.png";
import {LogoComponent, CopyrightComponent} from 'ppm-skin'
import UsersRepository from "pages/Users/repository/users-repository";
import DialogBoxComponent from "../Utility/Helpers/DialogBoxComponent";
import LoadingContainer from "../Utility/Helpers/LoadingContainer";
import {DIALOG_TYPE, HTTP_RESPONSE} from "../Utility/Helpers/constants";
import {redirectTo} from "../Utility/Helpers/file-helper";
const ChangePasswordPage = props => {
  const code = useParams()
  const dispatch = useDispatch();
  const [User, setUser] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [dialog, setDialog] = React.useState({
    show: false,
    onConfirm: () => {
    },
    handleClose: () => {
    },
    message: "",
    type: DIALOG_TYPE.ALERT
});
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required("This field is required"),
      confirmPassword: Yup.string().when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Both password need to be the same"
        )
      })
    }),
    validatePassword(values) {
      let error = {};
      const passwordRegex = /(?=.*[0-9])/;
      if (!values) {
          error = "*Required";
      } else if (values.length < 8) {
          error = "*Password must be 8 characters long.";
      } else if (!passwordRegex.test(values)) {
          error = "*Invalid password. Must contain one number.";
      }
      return error;
  },
    onSubmit: (values) => {
      setLoading(true);
      console.log("test", values)
      let tmp = User;
      tmp.password = values.password;
      UsersRepository.edit(tmp).then((result) => {
        console.log("ici????")
        console.log(result);
        const currentDialog = {
          show: true,
          handleClose: () => {
              redirectTo('/login');
          },
          onConfirm: () => {
            redirectTo('/login');
        },
        message: props.t("PASSWORD_UPDATED"),
        title: '',
          type: DIALOG_TYPE.SUCCESS
      };
       setLoading(false);
      setDialog(currentDialog);
      })




      
      
    }
  });

  const { forgetError, forgetSuccessMsg } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }));
  const closeDialogModal = () => {
    setDialog({...dialog, show: false})
    redirectTo('/login');
  };

  const toggleDialogModal = () => {
    setDialog({...dialog, show: false})
    redirectTo('/login');
  };
  useEffect(()=>{
    console.log (code.code);
    UsersRepository.verify(code.code).then((e)=>{
      console.log(e);
      if (e.status == 200 && !e.data.code) {
        
        setUser(e.data);
      }else
      {
        console.log("error");
        //show popupnotification and error message
        const currentDialog = {
          show: true,
          handleClose: () => {
              redirectTo('/login');
          },
          onConfirm: () => {
            redirectTo('/login');
        },
        message: e.data.message,
        title: '',
          type: DIALOG_TYPE.ALERT
      };
      setDialog(currentDialog);


      }
        
    }).catch((e)=>{
        
    })
},[])
  return (
    <React.Fragment>
      <MetaTags>
        <title>
          {props.t("FORGET_PASSWORD")}
        </title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-softbg-soft-primary">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to Skote.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {forgetError && forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null}

                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">{props.t("NEW_PASSWORD")}</Label>
                        <Input
                          name="password"
                          className="form-control"
                          placeholder="Enter password"
                          type="password"
                          onChange={validation.handleChange}
                          onBlur={validation.validatePassword}
                          value={validation.values.password}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">{props.t("NEW_PASSWORD")}</Label>
                        <Input
                          name="confirmPassword"
                          className="form-control"
                          placeholder="Enter password"
                          type="password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.confirmPassword}
                          invalid={
                            validation.touched.confirmPassword && validation.errors.confirmPassword ? true : false
                          }
                        />
                        {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                          <FormFeedback type="invalid">{validation.errors.confirmPassword}</FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <button
                            className="btn btn-primary w-md "
                            type="submit"
                          >
                            {props.t("RESET")}
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to="login" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                <div className="text-center">
                   {new Date().getFullYear()}<CopyrightComponent apiUrl={PARAMETERS_API_URL}/>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <DialogBoxComponent
                    handleClose={() => toggleDialogModal()}
                    handleConfirm={() => dialog.onConfirm()}
                    show={dialog.show}
                    type={dialog.type}
                    message={dialog.message}
                />
    
    </React.Fragment>
  );
};

ChangePasswordPage.propTypes = {
  history: PropTypes.object,
};

//export default withRouter(ForgetPasswordPage);
export default withTranslation()(withRouter(ChangePasswordPage));
