import React from "react";
import { withTranslation } from "react-i18next";
import {useParams} from "react-router-dom";
import PagesReferences from "pages/References/pages-references";
import PagesEditReferences from "pages/References/pages-add-references";
import PagesDetailReferences from "pages/References/pages-detail-references"

import PagesReferenceLabels from "pages/ReferenceLabels/pages-reference-labels"
import PagesEditReferenceLabels from "pages/ReferenceLabels/pages-add-reference-labels"
import PagesDetailReferenceLabels from "pages/ReferenceLabels/pages-detail-reference-labels"

import PagesReferenceValues from "pages/ReferenceValues/pages-reference-values"
import PagesEditReferenceValues from "pages/ReferenceValues/pages-add-reference-values"
import PagesDetailReferenceValues from "pages/ReferenceValues/pages-detail-reference-values"

const ReferenceWrapper = (props) =>{

    const params = useParams();
    const {referenceId = null, referenceValueId = null, referenceLabelId = null, referenceLabelAction = null} = params;
    return(
        <>
        { 
            (referenceId==null) ? <PagesReferences {...props} showDelete={false}/> 
            : (referenceId=="add") ? (<PagesEditReferences {...props} id={0}/>)
            : (referenceId!=null ) && (referenceValueId=="edit") ? (<PagesEditReferences {...props} id={referenceId}/>)
            : (referenceId!=null ) && (referenceValueId=="detail") ? (<PagesDetailReferences {...props} id={referenceId}/>)
            
            : (referenceId!=null && referenceId!="add" ) && (referenceValueId==null) ? (<PagesReferenceValues {...props} referenceId={referenceId}  showDelete={false}/>)
            : (referenceId!=null ) && (referenceValueId=="add") ? (<PagesEditReferenceValues {...props} referenceId={referenceId}/>)
            : (referenceId!=null ) && (referenceValueId!=null) && (referenceLabelId=="edit") ? (<PagesEditReferenceValues {...props} referenceId={referenceId} referenceValueId={referenceValueId} />)
            : (referenceId!=null ) && (referenceValueId!=null) && (referenceLabelId=="detail") ? (<PagesDetailReferenceValues {...props} referenceId={referenceId} referenceValueId={referenceValueId}/>)


            : (referenceId!=null ) && (referenceValueId!=null && referenceValueId!="add") && (referenceLabelId==null) ? (<PagesReferenceLabels {...props} referenceId={referenceId} referenceValueId={referenceValueId} showDelete={false}/>)
            : (referenceId!=null ) && (referenceValueId!=null) && (referenceLabelId=="add")  ? (<PagesEditReferenceLabels {...props} referenceId={referenceId} referenceValueId={referenceValueId} />)
            : (referenceId!=null ) && (referenceValueId!=null) && (referenceLabelId!=null) && (referenceLabelAction=="edit") ? (<PagesEditReferenceLabels {...props} referenceId={referenceId} referenceValueId={referenceValueId} referenceLabelId={referenceLabelId}/>)
            : (referenceId!=null ) && (referenceValueId!=null) && (referenceLabelId!=null) && (referenceLabelAction=="detail") ? (<PagesDetailReferenceLabels {...props} referenceId={referenceId} referenceValueId={referenceValueId} referenceLabelId={referenceLabelId}/>)
            : ""
        }
        </>
    )
}

export default withTranslation()(ReferenceWrapper)
