import axios from "axios";
import {HTTP_RESPONSE} from "../../Utility/Helpers/constants";
import UserProvier from "../../../security/UserProvier";
import AuthorizationChecker from "../../../security/AuthorizationChecker";

UserProvier.setToken();
export const DATA_API_PROJECT_LOGS_URL = process.env.REACT_APP_API_BASE_URL + 'project-logs';

const queries = (projectId, itemPerPage = 15, page = 1, search = '', querying = '') => {

    //create the qury, we need to include the user, the project , the project log type and the product if is set
    return DATA_API_PROJECT_LOGS_URL + '?filter[where][projectId]=' + projectId +
        '&filter[include][]=user' +
        '&filter[include][]=projectLogsType' +
        '&filter[include][]=product' +
        '&filter[limit]=' + itemPerPage + '&filter[skip]=' + (((page - 1) * itemPerPage) > 0 ? ((page - 1) * itemPerPage) : 0);
}

const ProjectLogsRepository = {

    findAll: (projectId, page = 1, itemPerpage = 10) => {
        return axios
            .get(queries(projectId, itemPerpage, page))
            .then(response => {
                return response.data;
            }).catch((e) => {
                if (e.response) {
                    throw e.response;
                } else {
                    throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
                }
            });
    }
}
export default ProjectLogsRepository;
