import React from "react"
import { Container, Row, Col } from "reactstrap"
import moment from 'moment';
import preval from 'preval.macro';
import packageJson from '../../../package.json';
//import metadata
import metadata from '../../metadata.json';

const buildTimestamp = preval`module.exports = new Date().getTime();`;
const getDateString = () => {
  const lastUpdateMoment = moment.unix(buildTimestamp / 1000);
  const formattedDate    = lastUpdateMoment.format('DD.MM.YYYY HH:mm:ss');

  return formattedDate;
};

const Footer = () => {
  //get env variables
  const env = process.env.REACT_APP_BRANCH
  
  //console.log(process.env);
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={3}>{new Date().getFullYear()} © Legault Group.</Col>
            <Col md={3} style={{ color: 'red' }}>{env}</Col>
            <Col md={6}>
              <div className="text-sm-end d-sm-block">
              LGVP {packageJson.version}
                {'.'}
                {metadata.buildNumber}
                {' '}
                {'('}
                {getDateString()}
                {')'}
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
