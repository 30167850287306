import React from "react";
import { withTranslation } from "react-i18next";
import {useParams} from "react-router-dom";
import PagesProjects from "pages/Projects/pages-projects";
//import PagesProjectsList from "pages/Projects/pages-projects-list";
//import PagesProjectsList from "pages/Projects/pages-projects-list";
import PagesProjectsList from "pages/Projects/pages-projects-list";
import PagesEditProjects from "pages/Projects/pages-add-projects";
import PagesDetailProjects from "pages/Projects/pages-detail-projects"

import PagesProducts from "pages/Products/pages-products"
import PagesEditProducts from "pages/Products/pages-add-products"
import PagesDetailProducts from "pages/Products/pages-detail-products"

const ProductWrapper = (props) =>{

    const params = useParams();
    const {projectId = null, productId = null, productAction = null} = params;
    const {history} = props;

    return(
        <>
        { 
            (projectId==null) ? <PagesProjects {...props} showDelete={false}/> 
            : (projectId=="add") ? (<PagesEditProjects {...props} id={0}/>)
            : (projectId!=null ) && (productId=="edit") ? (<PagesEditProjects {...props} id={Number(projectId)}/>)
            : (projectId!=null ) && (productId=="detail") ? (<PagesDetailProjects {...props} id={Number(projectId)}/>)
            
            : (projectId!=null && projectId!="add" ) && (productId==null) ? (<PagesProducts {...props} projectId={Number(projectId)} showView={false} showDelete={true} showAdd={true} addUrl={"/pages-projects/"+projectId+"/add"}/>)
            : (projectId!=null ) && (productId=="add") ? (<PagesEditProducts {...props} projectId={Number(projectId)} onEditSuccess={(response)=>{history.push("/pages-projects/"+projectId);}} />)
            : (projectId!=null ) && (productId!=null) && (productAction=="edit") ? (<PagesEditProducts {...props} projectId={Number(projectId)} productId={Number(productId)}  onEditSuccess={(response)=>{history.push("/pages-projects/"+projectId);}}/>)
            : (projectId!=null ) && (productId!=null) && (productAction=="detail") ? (<PagesDetailProducts {...props} projectId={Number(projectId)} productId={Number(productId)}/>)
            : ""
        }
        </>
    )
}

export default withTranslation()(ProductWrapper)
