import React from "react";
import {Link, useParams} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import {EditUserCompanyRoleComponent} from "ppm-react-authentication";
import {ROLES_API_URL,USERS_COMPANY_API_URL,ROLES_PERMISSIONS_API_URL, USERS_COMPANY_ROLES_API_URL} from "../../helpers/api-urls";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next";

const PagesEditCompanyUsers  = (props) =>{

    const {history} = props;
    const params = useParams();
    const {id = 0} = params;
    const onEditSuccess = (response) => {
        history.push("/pages-company-users-roles");
    }
    return (
        <>
            <div className="page-content">
                <MetaTags>
                    <title>{props.t("USERS")} {props.t("COMPANY")}</title>
                </MetaTags>

                <div className="mb-4">
                    <Breadcrumbs title="Role" breadcrumbItem={props.t("ROLE") + " - " + ( id ? props.t("MODIFICATION") : props.t("ADD"))}/>
                    <Link className="btn btn-info" to={"/pages-company-users-roles"}>{props.t("RETURN_TO_LIST")}</Link>
                </div>
                <EditUserCompanyRoleComponent
                    id={id}
                    apiUrl={USERS_COMPANY_API_URL}
                    onEditSuccess={onEditSuccess}
                    apiRoleUrl={ROLES_API_URL}
                    apiRolePermissionUrl={ROLES_PERMISSIONS_API_URL}
                    apiUserCompanyRoleUrl={USERS_COMPANY_ROLES_API_URL}
                />
            </div>
        </>
    )
}

export default withTranslation()(PagesEditCompanyUsers)
