import axios from "axios";
import {HTTP_RESPONSE} from "../../Utility/Helpers/constants";
import UserProvier from "../../../security/UserProvier";


export const DATA_API_URL = process.env.REACT_APP_API_BASE_URL + 'config';
const queries = (itemPerPage = 15, page = 1,fkCieId ,search = '') => {

    return DATA_API_URL + '?filter[where][nom][like]=%'+search+'%' +
        '&filter[where][fkCieId]='+fkCieId+
        '&filter[limit]=' + itemPerPage + '&filter[skip]=' + (((page - 1) * itemPerPage) > 0 ? ((page - 1) * itemPerPage) : 0);
}

const queriesAll = (fkCieId = 1) => {

    return DATA_API_URL + '?filter[where][companyId]='+fkCieId
}
const ConfigRepository = {
    findAll : async (fkCieId) =>{
        try {
            const response = await axios
                .get(queriesAll(fkCieId));
                console.log(response.data);
            return response.data;
        } catch (e) {
            if (e.response) {
                throw e.response;
            } else {
                throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND };
            }
        }

    },
    delete: async (id) => {
        try {
            const response = await axios
                .delete(DATA_API_URL + '/' + id);
            return response.data;
        } catch (e) {
            if (e.response) {
                throw e.response;
            } else {
                throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND };
            }
        }
    },
    create: async (data) => {
        try {
            const response = await axios
                .post(DATA_API_URL, data);
            return response.data;
        } catch (e) {
            if (e.response) {
                throw e.response;
            } else {
                throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND };
            }
        }
    },
    update: async (data) => {
        try {
            const response = await axios
                .patch(DATA_API_URL + '/' + data.id, data);
            return response.data;
        } catch (e) {
            if (e.response) {
                throw e.response;
            } else {
                throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND };
            }
        }
    },
    updateAll: async (data) => {
        try {
            console.log(data);
            
            const response = await axios
                .put(DATA_API_URL + '/updateAll',  data); // Pass an object as the second argument
            return response.data;
        } catch (e) {
            if (e.response) {
                throw e.response;
            } else {
                throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND };
            }
        }
    }
};
export default ConfigRepository;