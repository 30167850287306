import React, { useState } from "react"
import MetaTags from 'react-meta-tags';
import {
  Row,
  Col,
  Card,
  Form,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
} from "reactstrap"
import Dropzone from "react-dropzone";
import SkuRepository from "./Repository/sku-repository"
// Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import LoadingContainer from "../Utility/Helpers/LoadingContainer";
import { Link } from "react-router-dom"
import {DIALOG_TYPE, HTTP_RESPONSE} from "../Utility/Helpers/constants";
import DialogBoxComponent from "../Utility/Helpers/DialogBoxComponent";
import AppModalComponent from "pages/Utility/Helpers/AppModalComponent";
import {formatBytes, redirectTo} from "../Utility/Helpers/file-helper";
import Push from "../Utility/Helpers/Push";
import { withTranslation } from "react-i18next"
const UpcUpload = (props) => {
  const [loading, setLoading] = useState(false);
  const [selectedFiles, setselectedFiles] = useState([])
  const [errors, setErrors] = useState([])
  const [showError, setShowError] = useState(false)
  const [backendResponse, setBackendResponse] = useState("")
  const [dialog, setDialog] = useState({
    show: false,
    onConfirm: () => {
    },
    handleClose: () => {
    },
    message: "text",
    type: DIALOG_TYPE.ALERT
});

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
/**
     * Gestion de la soumission du formulaire
     *
     * @param event
     * @returns {Promise<void>}
     */
 const handleSubmitForm = (event) => {
  event.preventDefault();
  if (selectedFiles.length < 1) {
      const errorsTmp = {};
      errorsTmp['file'] = props.t("CHOOSE_FILE");
      //setErrors(errorsTmp) //@TODO
  } else {
      setLoading(true);
      SkuRepository.uploadAttachment(selectedFiles[0]).then(response => {
        console.log(response);
          setLoading(false);
          if(response.logs.length>0)
                {
                    
                        setBackendResponse(props.t("IMPORT_FAILED") + ", " + response.logs.length + "/"+ response.totalLines + " "  + props.t("INVALID_SKU") + "." )
                        setErrors(response.logs)
                        setShowError(response.logs.length>0)
                        //setShowResponse(true)
                }
                else
                {

                  Push.success(props.t("FILES_UPLOAD_SUCCESS"));
                  const currentDialog = {
                    show: true,
                    handleClose: () => {
                        redirectTo('/dashboard');
                    },
                    onConfirm: () => {
                      redirectTo('/dashboard');
                  },
                  title: props.t("FILES_UPLOAD_SUCCESS_TITLE"),
                  message: props.t("FILES_UPLOAD_SUCCESS") +"\r\n"+ props.t("IMPORTED_LINES") + " : "+ response.importedLines + "\r\n" + props.t("IGNORED_LINES") + " : " + response.logs.length,
                  type: DIALOG_TYPE.SUCCESS
                };
              
                setDialog(currentDialog);
              }
       //   redirectTo('/dashboard');
      }).catch(response => {
          catchError(response);
          setLoading(false);
      });
  }
};
const handleFormInputChange = ({target}) => {
  const {name, value} = target;
  setTags([...value])
};

const catchError = (response) => {
  console.log(response);
  const currentDialog = {
      show: true,
      onConfirm: () => {
          redirectTo('/dashboard');
      },
      handleClose: () => toggleDialogModal(),
      type: DIALOG_TYPE.ALERT
  };
  switch (response.status) {
      case HTTP_RESPONSE.HTTP_BAD_REQUEST: {
          currentDialog.type = DIALOG_TYPE.ALERT;
          break;
      }
      case HTTP_RESPONSE.HTTP_NOT_FOUND: {
          currentDialog.type = DIALOG_TYPE.ALERT;
          break;
      }
      case HTTP_RESPONSE.HTTP_UNAUTHORIZED: {
          currentDialog.type = DIALOG_TYPE.AUTH_REQUIRED;
          break;
      }
      case HTTP_RESPONSE.HTTP_INTERNAL_SERVER_ERROR: {
          currentDialog.type = DIALOG_TYPE.SERVER_ERROR;
          currentDialog.message = '';
          if (response.data.message) {
              currentDialog.message = response.data.message;
          }
          break;
      }
      default:
  }
  setDialog(currentDialog);
};

const toggleDialogModal = () => {
  setDialog({...dialog, show: false})
};

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title></title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title={props.t("_UPLOAD_")} breadcrumbItem={props.t("UPC_SAP_UPLOAD")} />
          <LoadingContainer className="mb-4" loading={loading}>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("_UPLOAD_")}</CardTitle>
                  <CardSubtitle className="mb-3">
                    {" "}
                    <span dangerouslySetInnerHTML={{__html: props.t("_UPLOAD__TEXT_UPC_SAP_DIRECTIVE")}}></span>
                  </CardSubtitle>
                  <div>
                  {
                showError && (
                    <div className="row error-box ms-1">
                    <div className="error-box-title">
                        <p> <strong> {props.t("ERRORS_OCCURED")}</strong></p>
                        <button type="button" className="btn-close" aria-label="Close" onClick={()=>{setShowError(false)}}></button>
                    </div>
                    <ul>
                        {errors.map((error,i)=>{
                            //errors_details need to be parsed and the result need to have this structure
                            //lineIndex for the line of the error occured
                            // message for the error message nees to pass with transalation
                            // validationERROR list of message to display with ; separator

                            //show the errors in the following format  Message -> lineIndex -> validationERROR list of message to display with ; separator


                            let errors_details = JSON.parse(error);
                            /*let errorParts = error.split(",")
                           let subErrorParts = errorParts[1].split(":")
                            //we have error in json format, now let parse it
                            //let subErrorParts = JSON.parse(subErrorParts[1])*/
                            let err_mess = [];
                            if (errors_details.validationERROR)
                            {
                                err_mess = errors_details.validationERROR.split(";")
                            }
                            return (
                                <li key={i}> 
                                    <code>{props.t(errors_details.message,{lineIndex : errors_details.lineIndex})} : </code>
                                    

                                    
                                        <ul>
                                            {
                                                err_mess.map((subError, index)=> <li key={index}>
                                                        {subError}
                                                    </li>
                                                )
                                            }
                                        </ul>
                                  
                                </li>
                            )
                        })}
                    </ul>
                    </div>
                )
                }

                  </div>
                  <Form onSubmit={(e) => handleSubmitForm(e)}>
                    <Dropzone
                      onDrop={acceptedFiles => {
                        handleAcceptedFiles(acceptedFiles)
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick mt-2"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div>
                            <h4>{props.t("_UPLOAD_DIRECTIVE")}</h4>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedFiles.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                <i className="bx bx-file-blank"
                                  style={{fontSize: '50px'}}/>

                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        )
                      })}
                    </div>

                  <div className="text-center mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary "
                      >
                      {props.t("SEND_FILES")}
                    </button>
                  </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          </LoadingContainer>
        </Container>
        <DialogBoxComponent
                    handleClose={() => toggleDialogModal()}
                    handleConfirm={() => dialog.onConfirm()}
                    title={dialog.title}
                    message={dialog.message}
                    show={dialog.show}
                    type={dialog.type}
                />

      </div>
    </React.Fragment>
  )
}

export default withTranslation()(UpcUpload)