import React, {Fragment, useEffect, useState} from 'react';

/**
 *
 * @returns {*}
 * @constructor
 */
const PaginationComponent = (props) => {
    // eslint-disable-next-line react/prop-types
    const {itemsPerPage, totalItems, onPageChange, activePage = 1} = props;
    const [currentPage, setCurrentPage] = useState(activePage);
    const pagesCount = parseInt(String(Math.ceil(totalItems / itemsPerPage)));
    const enabledPagination = itemsPerPage <= totalItems && totalItems > 0;
    const pages = [];
    useEffect(() => {
        setCurrentPage(activePage);
    }, [activePage]);
    for (let i = 1; i <= pagesCount; i++)
        pages.push(i);
    const handlePageChange = (page) => {
        let p = 1;
        if (page < 1) {
            p = 1;
        } else if (page > pagesCount) {
            p = pagesCount;
        } else {
            p = page;
        }
        if (p !== currentPage) {
            setCurrentPage(p);
            onPageChange(p);
        }
    };
    return (
        <Fragment>
            {enabledPagination &&
            <div className={"display-inline d-flex flex-row-reverse"}>
                <nav className="ml-auto">
                    <ul className="pagination mb-0">
                        {/*<li className="">*/}
                        {/*    <span className="page-indicator">Aller à la page :</span>*/}
                        {/*</li>*/}
                        <li className={"page-item " + (currentPage <= 1 && "disabled")}
                            onClick={() => handlePageChange(currentPage - 1)}>
                            <button className="page-link"><i className="bx bx-chevron-left font-weight-bold"/></button>
                        </li>
                        {pages.slice(0, 7).map(page => {
                            return (
                                <li className={"page-item " + (currentPage === page && "active")} key={page}
                                    onClick={() => handlePageChange(page)}>
                                    <button className="page-link">{page}</button>
                                </li>
                            );
                        })}
                        <li className={"page-item " + (currentPage >= pagesCount && "disabled")}>
                            {pages.length > 7 && <>
                                {/*<Dropdown className={'mb-0'}>*/}
                                {/*    <Dropdown.Toggle variant={'light'} className="text-center btn btn-sm size14 pages-dropdown-btn">*/}
                                {/*        <i className="ik ik-chevrons-down font-weight-bold"/>*/}
                                {/*    </Dropdown.Toggle>*/}
                                {/*    <Dropdown.Menu alignRight={false} className="pages-dropdown">*/}
                                {/*        {pages.slice(8, pages.length).map((item, index) =>*/}
                                {/*            <Dropdown.Item*/}
                                {/*                key={index}*/}
                                {/*                href="#" className="d-flex"*/}
                                {/*                onClick={(e) => {*/}
                                {/*                    e.preventDefault();*/}
                                {/*                    handlePageChange(item);*/}
                                {/*                }}>*/}
                                {/*                <span>*/}
                                {/*                    {item}*/}
                                {/*                </span>*/}
                                {/*            </Dropdown.Item>)}*/}
                                {/*    </Dropdown.Menu>*/}
                                {/*</Dropdown>*/}
                            </>}
                        </li>
                        <li className={"page-item " + (currentPage >= pagesCount && "disabled")}
                            onClick={() => handlePageChange(currentPage + 1)}>
                            <button className="page-link"><i className="bx bx-chevron-right font-weight-bold"/>
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
            }
        </Fragment>
    );
};

export default PaginationComponent;
