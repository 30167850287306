import PropTypes from 'prop-types'
import React from "react"
import { Card, CardBody, CardTitle, Table } from "reactstrap"
import { Link } from "react-router-dom"
import { map } from "lodash"
import { withTranslation } from 'react-i18next'
//swiper
// Core modules imports are same as usual
//import { Navigation, Pagination } from 'swiper/modules';
// Direct React component imports

const VerticalTimeline = (props) => {
    const { logs } = props

    return (

        <div>
        <ul className="verti-timeline list-unstyled">
        {/* Render Horizontal Timeline Events */}
        {logs.map((log, key) => (
          <li key={key} className="event-list">
            <div className="event-timeline-dot">
              <i className="bx bx-right-arrow-circle"
              />
            </div>
            <div className="d-flex">
              <div className="me-3">
                <i
                  className={
                    "mdi " + log.projectLogsType.iconClass + " h2 text-primary"
                  }
                />
              </div>
              <div className="flex-grow-1">
                <div>
                        <div className="event-date">
                            <div className="text-primary mb-1">
                                {//display only the date and hour:minute in format AAAA-MM-DD HH:MM
                                log.createdAt.split("T")[0] + " " + log.createdAt.split("T")[1].split(".")[0]}
                            </div>
                        </div>
                        <div className="col-md-2"><h5>{props.t(log.projectLogsType.key)}</h5> </div>
                  <p className="text-muted">
                    {props.t("_BY_")} {log.user.firstname} {log.user.lastname} 
                  </p>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
                    
    )


}
VerticalTimeline.propTypes = {
    logs: PropTypes.array,
    t: PropTypes.any
}
export default withTranslation()(VerticalTimeline)