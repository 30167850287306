import React from "react";
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import {ReferenceLabelEditComponent} from "ppm-react-references";
import {REFERENCE_LABELS_API_URL, REFERENCE_VALUES_API_URL, REFERENCE_LANGUES_API_URL} from "../../helpers/api-urls";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next";

const PagesEditReferenceLabels  = (props) =>{

    const {history, referenceId, referenceValueId,referenceLabelId} = props;
    const baseUrl = "/pages-references/"+referenceId+"/"+referenceValueId
    const onEditSuccess = (response) => {
        history.push(baseUrl);
    }
    return (
        <>
            <div className="page-content">
                <MetaTags>
                    <title>{props.t("REFERENCE")} {props.t("LABEL")}</title>
                </MetaTags>

                <div className="mb-4">
                    <Breadcrumbs title={props.t("REFERENCE_LABEL")} breadcrumbItem={props.t("REFERENCE_LABEL")+ "- " + ( referenceLabelId ? props.t("EDIT") : props.t("ADD"))}/>
                    <Link className="btn btn-info" to={baseUrl}>{props.t("RETURN_TO_LIST")}</Link>
                </div>
                <ReferenceLabelEditComponent 
                    referenceLabelId={referenceLabelId}
                    referenceValueId={referenceValueId}
                    referenceId={referenceId}
                    onEditSuccess={onEditSuccess}
                    apiUrl={REFERENCE_LABELS_API_URL}
                    apiLangueUrl= {REFERENCE_LANGUES_API_URL}
                    apiReferenceValueUrl= {REFERENCE_VALUES_API_URL}
                    addUrl={baseUrl+"/add"}
                    path={baseUrl}
                />
            </div>
        </>
    )
}

export default withTranslation()(PagesEditReferenceLabels)
