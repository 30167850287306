import {HTTP_RESPONSE} from "../../Utility/Helpers/constants";
import axios from "axios";

export const DATA_API_URL = process.env.REACT_APP_API_BASE_URL+"reference-values"

const queries = (itemPerPage = 15, page = 1, search = '', associatedReferenceId=0) => {
  return (
    DATA_API_URL +'?filter[where][or][0][code][like]=%'+search+'%' +'&filter=[where][code][options]=i' +
    '&filter[where][or][1][description][like]=%'+search+'%' +'&filter=[where][description][options]=i' +
    '&filter[include][]=company&filter[include][]=reference'+
    '&filter[limit]=' + itemPerPage +
    (associatedReferenceId ? '&filter[where][and][0][referenceId]='+associatedReferenceId : '' ) +
    '&filter[skip]=' + ((page - 1) * itemPerPage > 0 ? (page - 1) * itemPerPage : 0)
  )
}

const queriesIncludeAll =  (id) => {
  return (
    this.api +'?filter[where][id]=' + id +
    '&filter[include][]=company&filter[include][]=reference'
  )
}

const  ReferenceValueRepository = {
    findAll : (page = 1, itemPerpage = 10, search = '',associatedReferenceId=0) => {
      return axios
        .get(queries(itemPerpage, page, search,associatedReferenceId))
        .then((response) => {
          return response.data
        })
        .catch((e) => {
          if (e.response) {
            throw e.response
          } else {
            throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND }
          }
        })
    },

    findIncludeAll : (id) => {
      return axios
        .get(queriesIncludeAll(id))
        .then((response) => {
          let tmp = response.data.items;
          if ( tmp.length > 0 ){
              return tmp[0];
          }else{
              return null;
          }
        })
        .catch((e) => {
          if (e.response) {
            throw e.response
          } else {
            throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND }
          }
        })
    },
  
    find : (id) => {
      return axios
        .get(DATA_API_URL + '/' + id)
        .then((response) => {
          return response.data
        })
        .catch((e) => {
          if (e.response) {
            throw e.response
          } else {
            throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND }
          }
        })
    }
}

export default ReferenceValueRepository