import React, { useEffect, useState } from "react"
import PropTypes from "prop-types";


import { EditorState, ContentState, convertToRaw} from 'draft-js';
import { Card, CardBody, CardTitle,Collapse,Label,Form,FormGroup,Input } from "reactstrap";
import { Link } from "react-router-dom";
import { add, map, set } from "lodash";
import images from "assets/images";
import { withTranslation } from "react-i18next";

// Form Editor
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import  draftToHtml  from 'draftjs-to-html';

const Comments = ( props ) => {

  const { comments,addComment} = props;
  const [col5, setCol5] = React.useState(false);
  const [comment, setComment] = useState("");
  const [isPrivate, setIsPrivate] = useState(0);
  const [toggleSwitch, setToggleSwitch] = useState(true);

  const t_col5 = () => setCol5(!col5);
 
   //the editor is the box that lets you style your text!
   const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty()
  );


  const handleSubmit = event => {
    event.preventDefault();
    let raw = convertToRaw(editorState.getCurrentContent());//raw editor content
    const rawToHTML = draftToHtml( //draftToHtml converts from raw to html
      raw
    )
    //setComment(rawToHTML);
  }

  const emptyEditor = (e) => {
    e.preventDefault();
    let raw = convertToRaw(editorState.getCurrentContent());//raw editor content
    const rawToHTML = draftToHtml( //draftToHtml converts from raw to html
      raw
    )
    
    setComment(rawToHTML);
  }

 
  useEffect(() => {
    if (comment) {
    const commentaireAdd = {
      comments: comment,
      isPrivate: isPrivate,
      isReply: 0, //TODO: add the reply functionality
    };
    addComment(commentaireAdd);
  }
  }, [comment]);

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">{props.t("_COMMENTS_")}</CardTitle>
        {map(comments, (comment, index) => (
          <div className="d-flex mb-4" key={index}>
            <div className="me-3">
              {comment.userImg ? (
                <img
                  className="media-object rounded-circle avatar-xs"
                  alt=""
                  src={images[comment.userImg]}
                />
              ) : (
                <div className="avatar-xs">
                  <span className="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-16">
                    {
                    //comment.user.username.charAt(0)
                    //display the first letter of the username as the avatar in UpperCase
                    comment.user.username.charAt(0).toUpperCase()
                  }
                  </span>
                </div>
              )}
            </div>
            <div className="flex-grow-1">
              <p className="text-muted mb-0">
                <i className="mdi mdi-calendar me-1">  </i>
                {comment.createdAt + " "}{comment.isPrivate ? (<span className="badge rounded-pill bg-danger">
                              {props.t("_PRIVATE_")}
                            </span>):""} </p>
              <h5 className="font-size-13 mb-1">{comment.user.firstname} {comment.user.lastname}</h5>
              <p className="text-muted mb-1  ms-3"><span dangerouslySetInnerHTML={{ __html: comment.comments }} /></p>
              
            </div>
            <div className="ml-3">
              <Link to="" className="text-primary">
                
              </Link>
            </div>
          </div>
        ))}
                        <div className="text-center mt-4 pt-2">
                        <button
                            onClick={t_col5}
                            className="btn btn-primary mo-mb-2"
                            type="button"
                            style={{ cursor: "pointer" }}
                            >
                          {props.t("ADD_COMMENT_")}
                        </button>
                        </div>
                     
                          <Collapse isOpen={col5}>
                            <Card>
                              <CardBody>
                              <Form onSubmit={handleSubmit}>
                              <FormGroup>
                              <div
                                  className="form-check form-switch mb-3"
                                  dir="ltr"
                                >
                                <Input
                                    type="checkbox"
                                    className="form-check-input"
                                    name="isPrivate"
                                    id="isPrivate"
                                    checked={isPrivate === 1}
                                    onChange={() => setIsPrivate(prev => (prev === 1 ? 0 : 1))}
                                    onClick={() => setIsPrivate(prev => (prev === 1 ? 0 : 1))}
                                    style={{
                                      borderColor: "darkgrey",
                                      marginRight: "5px",
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="customSwitch1"
                                    onClick={() => {
                                      setToggleSwitch(!toggleSwitch);
                                    }}
                                    >{props.t("_IS_PRIVATE_")}</label>
                                  </div>
                                <Label for="comment">{props.t('COMMENT')}</Label>
                                <Editor
                                  toolbar={{
                                    options: ['inline', 'list', 'colorPicker', 'emoji'],
                                    inline: {
                                      options: ['bold', 'italic', 'underline'],
                                    }
                                  }}
                                  editorState={editorState}
                                  onEditorStateChange={setEditorState}
                                  
                                >
                                </Editor>
                              </FormGroup>
                                <button type="button" className="btn btn-success" onClick={emptyEditor}>
                                  <i className="bx bx-check-double font-size-16 align-middle me-2"></i>{" "}
                                  {props.t("ADD")}
                                </button>
                            </Form>
                              </CardBody>
                            </Card>
                          </Collapse>
        
      </CardBody>
    </Card>
  );
};

Comments.propTypes = {
  comments: PropTypes.array,
  t: PropTypes.any,
};

//export default Comments;
export default withTranslation()(Comments);