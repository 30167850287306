import UsersRepository from "pages/Users/repository/users-repository";
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle ,Progress} from "reactstrap"
import { Link } from "react-router-dom"
import {withRouter} from 'react-router'
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux"

//test
import axios from "axios";
import {HTTP_RESPONSE} from "../Utility/Helpers/constants";
import UserProvier from "../../security/UserProvier";
import  ActionsFormatterSupplier from "pages/Utility/Helpers/ActionsFormatterSupplier"



UserProvier.setToken();

export const DATA_API_URL = process.env.REACT_APP_API_BASE_URL + 'userlg';

const queries = (fkCieId = '',enable = '') => {

    return DATA_API_URL + '?filter[where][fkCieId]='+fkCieId+'&filter[where][enabled]='+enable;

}
//end test






const CardToAssign = (props) => {
  const dispatch = useDispatch()
  const [userList, setUserList] = useState([])
  const [loading, setLoading] = useState(true);
 /*const getUserList = UsersRepository.findToEnable(1,0).then(data=>{
    console.log(data.items)
    setUserList(data.items);
    console.log(userList);
});*/
const [user, SetUser] = useState([]);
const [cieId, SetcieId] = useState();

useEffect( async () => {
  const items = await JSON.parse(localStorage.getItem('authUser'));
  if (items)
  {
    getUser(items.companyId);
  }
  
}, []);

const getUser = async(cieId) =>{
   // setLoading(true);
    const {data} = await axios.get(DATA_API_URL + '/gettoapprovebycie/'+cieId);
  //  const {data} = await UsersRepository.findToEnable(1,0);
    console.log(data);
    setUserList(data);
   // setLoading(false);
}

/*useEffect(() => {
    getUser(); 
  }, []);*/

  
/*    useEffect(function effectFunction() {
    async function fetchUsers() {
        const response = await axios.get(DATA_API_URL + '?filter[where][fkCieId]=1&filter[where][enabled]=0').then(response => {
            console.log(response);
            setUserList(response);
            console.log(userList);
    });
        //console.log(response.data.items);
       // const json = await response.json();
        //setUserList(response.data.items);
        //console.log(userList);
    }
    fetchUsers();
}, []);*/


    
            





  return (
    <React.Fragment>
        
        <Card>
        <CardBody>
          <CardTitle className="mb-4">{props.t("VENDOR_TO_APPROVE_AND_ASSIGN")}</CardTitle>
          {userList.length  > 0 ? ( 
          <table className="table table align-middle table-nowrap">
            <thead className="thead-light">
                          <tr>
                            <th tabIndex={0}>{props.t("NAME")}</th>
                            <th tabIndex={0}>{props.t("FIRST_NAME")}</th>
                            <th tabIndex={0}>{props.t("COMPANY_NAME")}</th>
                            <th tabIndex={0}>{props.t("EMAIL")}</th>
                            <th tabIndex={0}>{props.t("USERNAME")}</th>
                            <th tabIndex={0}>{props.t("STATUS")}</th>
                            <th tabIndex={0}/>
                          </tr>
                          </thead>
                        <tbody>
                        {
                        userList.map((user,index) => <tr key={index}>
                            <td>{user.lastname}</td>
                            <td>{user.firstname}</td>
                            <td>{user.companyName}</td>
                            <td>{user.email}</td>
                            <td>{user.username}</td>
                            <td>{user.enabled == 1 ? <span className="badge bg-primary p-2">{props.t("ACTIVE")}</span> :
                              <span className="badge bg-danger p-2">{props.t("INACTIVE")}</span>}
                            </td>
                            <td>
                              <ActionsFormatterSupplier
                                user={user}
                                
                                
                                showEdit = {props.showEdit} showView = {false} showDelete = {false}
                              />
                            </td>


                            </tr>
                        )  
                        }
                        </tbody>


          </table> ) : <center>{props.t("NO_USER_TO_ASSIGN")}</center> }
       
   </CardBody>
   </Card>
    </React.Fragment>
  )
}

export default withTranslation()(withRouter(CardToAssign))