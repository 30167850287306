import React from "react";
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import {ReferenceEditComponent} from "ppm-react-references";
import {REFERENCES_API_URL} from "../../helpers/api-urls";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next";

const PagesEditReferences  = (props) =>{

    const {history, id = 0} = props;
    const baseUrl = "/pages-references"

    const onEditSuccess = (response) => {
        history.push(baseUrl);
    }
    return (
        <>
            <div className="page-content">
                <MetaTags>
                    <title>{props.t("REFERENCE")}</title>
                </MetaTags>

                <div className="mb-4">
                    <Breadcrumbs title="Reference" breadcrumbItem={props.t("REFERENCE") + " - " + ( id ? props.t("EDIT") : props.t("ADD"))}/>
                    <Link className="btn btn-info" to={baseUrl}>{props.t("RETURN_TO_LIST")}</Link>
                </div>
                <ReferenceEditComponent 
                    id={id}
                    onEditSuccess={onEditSuccess}
                    apiUrl={REFERENCES_API_URL}
                    addUrl={baseUrl+"/add"}
                    path={baseUrl}
                />
            </div>
        </>
    )
}

export default withTranslation()(PagesEditReferences)
