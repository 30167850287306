import React from "react";
import {Badge, Container, Row, Col, Card, CardBody, BreadcrumbItem} from "reactstrap"
import MetaTags from 'react-meta-tags';
import {
    Input,
  } from "reactstrap"
import {Link} from 'react-router-dom';
import LoadingContainer from "../Utility/Helpers/LoadingContainer";
import {DIALOG_TYPE, HTTP_RESPONSE} from "../Utility/Helpers/constants";
import DialogBoxComponent from "../Utility/Helpers/DialogBoxComponent";
import "../Utility/Helpers/style.css";
import ProjectsRepository from "./Repository/project-repository";
import PaginationWrap from "../Utility/Helpers/PaginationWrap";
import ActionsFormatter from "../Utility/Helpers/ActionsFormatter";
import AuthorizationChecker from "../../security/AuthorizationChecker";
import UserProvier from "security/UserProvier";
import { withTranslation } from "react-i18next";
class PagesProjects extends React.Component {
    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);
       // console.log ("worker",cluster.isMaster)
        // eslint-disable-next-line react/prop-types
        this.state = {
            CORRECT_STATUS:[],
            projectStatus : {},
            items: [],
            errors: [],
            total: 0,
            entity: null, // element courant
            itemPerpage: 10,
            loading: true,
            loadingSearch: false,
            query: "",
            showConfirmDialog: false,
            companyId: UserProvier.get().companyId,
            pageSizeOptions: {
                totalSize: 0, // replace later with size(customers),
                custom: true,
                onPageChange: (page) => this.onPageChange(page),
                onSearchChange: this.handleSearchChange,
                page: 1,
                sizePerPage: 10,
                pageStartIndex: 1,
                sizePerPageList: 10,
                showTotal: true,
                onSizePerPageChange: {}
            },
            dialog: {
                show: false,
                onConfirm: () => {
                },
                handleClose: () => {
                },
                message: "",
                type: DIALOG_TYPE.ALERT,
            },
            selectedProjectsIds: [],
            unSelectableProjectsIds: []
        };
        this.onPageChange = this.onPageChange.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.selectRow = {mode: 'checkbox'};
        this.handleDelete = this.handleDelete.bind(this);
        this.sendToMondou = this.sendToMondou.bind(this)

        this.selectProject = this.selectProject.bind(this);
        this.selectAllProject = this.selectAllProject.bind(this);
        this.parseState = this.parseState.bind(this);
        this.assignProject = this.assignProject.bind(this);

        
        ProjectsRepository.getStatus().then(data=>{
            //console.log("Status:",data)
            this.setState((state)=>({...state, projectStatus: data, CORRECT_STATUS: data["CORRECT_STATUS"]}))
        }).catch(response=>{
            console.log("Failed loading status : ", response)
        })
    }

    parseState (stateId){
        //console.log("keys:",Object.keys(this.state.projectStatus))
        if( Object.keys(this.state.projectStatus).indexOf(""+stateId) >= 0){
            return this.state.projectStatus[stateId]
        }else{
            return this.state.projectStatus[0]
        }
    }
    
    componentDidMount() {
        this.findAll();
    }

    onPageChange(page) {
        this.setState(
            (state) => ({
                ...state,
                loading: true,
                page: page,
            }),
            () => {
                this.findAll(page);
            }
        );
    }

    selectAllProject (event){
        let {checked} = event.target
        let selectedProjects = []

        if(checked){
            selectedProjects = this.state.items.filter((p)=>{return this.state.unSelectableProjectsIds.indexOf(p.id)==-1}).map((e)=>{return e.id})
        }
        this.setState((state)=>({...this.state, selectedProjectsIds: selectedProjects}))
    }

    selectProject(id, event){
        if(this.state.unSelectableProjectsIds.indexOf(id)>=0){
            this.catchError({
                status : HTTP_RESPONSE.HTTP_UNAUTHORIZED,
                data : {
                    error : {
                        message: "{\"message\":\"PROJECT_UNMODIFIABLE\",\"name\":\"PROJECT_AT_INCOMPATIBLE_STATE\"}"
                    }
                }
            })
            return
        }
        //let {checked} = event.target
        let checked = (this.state.selectedProjectsIds.indexOf(id) ==-1)
        let selectedProjects = this.state.selectedProjectsIds

        if(checked){
            selectedProjects.push(id)
        }else{
            selectedProjects = selectedProjects.filter((i)=>{return i!=id})
        }
        this.setState((state)=>({...state, selectedProjectsIds: selectedProjects}))
    }

    handleDelete(e, elt) {
        e.preventDefault();
        this.setState(
            (state) => ({
                ...state,
                entity: elt,
                dialog: {
                    ...state.dialog,
                    onConfirm: () => this.performDelete(),
                    handleClose: () => this.closeDialogModal(),
                    type: DIALOG_TYPE.CONFIRM_DELETION
                }
            }),
            () => {
                this.toggleDialogModal();
            }
        );
    }

    handleSearchChange(query) {}

    findAll(page = 1) {
        ProjectsRepository.findAll(page, this.state.itemPerpage, this.state.query).then(data => {
            console.log(data.items[0].lastComment);
            this.setState(
                (state) => ({
                    ...state,
                    items: data.items,
                    unSelectableProjectsIds:  data.items.filter((p)=>this.state.CORRECT_STATUS.indexOf(p.statusId)<0).map(p=>p.id),
                    total: data.totalCount,
                    pageSizeOptions: {
                        sizePerPage: this.state.itemPerpage,
                        totalSize: 10, // replace later with size(customers),
                        custom: true,
                        onPageChange: (page) => this.onPageChange(page),
                        onSearchChange: this.handleSearchChange
                    },
                }),
                () => {
                    this.setState((state) => ({...state, loading: false, loadingSearch: false}));
                }
            );
        }).catch(response => {
            this.catchError(response);
        });
    }

    sendToMondou(){
        this.setState(
            (state) => ({ ...state, loading: true}),
            () => {
                ProjectsRepository.sendToMondo(this.state.selectedProjectsIds).then(()=>{
                    this.setState((state) =>({...this.state, selectedProjectsIds: []}))
                    this.findAll()
                }).catch(response => {
                    this.catchError(response);
                });
            }
        );
    }

    sendToRenPet(){
        this.setState(
            (state) => ({ ...state, loading: true}),
            () => {
                ProjectsRepository.sendToRenPet(this.state.selectedProjectsIds).then(()=>{
                    this.setState((state) =>({...this.state, selectedProjectsIds: []}))
                    this.findAll()
                }).catch(response => {
                    this.catchError(response);
                });
            }
        );
    }

    sendToDM(){
        this.setState(
            (state) => ({ ...state, loading: true}),
            () => {
                ProjectsRepository.sendToDM(this.state.selectedProjectsIds).then(()=>{
                    this.setState((state) =>({...this.state, selectedProjectsIds: []}))
                    this.findAll()
                }).catch(response => {
                    this.catchError(response);
                });
            }
        );
    }

    sendToEcom(){
        this.setState(
            (state) => ({ ...state, loading: true}),
            () => {
                ProjectsRepository.sendToEcom(this.state.selectedProjectsIds).then(()=>{
                    this.setState((state) =>({...this.state, selectedProjectsIds: []}))
                    this.findAll()
                }).catch(response => {
                    this.catchError(response);
                });
            }
        );
    }

    assignProject(projectId){
        this.setState(
            (state) => ({ ...state, loading: true}),
            () => {
                ProjectsRepository.assignProject(projectId).then(()=>{
                    this.findAll()
                }).catch(response => {
                    this.catchError(response);
                });
            }
        );
    }

    sendBackToVendor(){
        this.setState(
            (state) => ({ ...state, loading: true}),
            () => {
                ProjectsRepository.sendToVendor(this.state.selectedProjectsIds).then(()=>{
                    this.setState((state) =>({...this.state, selectedProjectsIds: []}))
                    this.findAll()
                }).catch(response => {
                    this.catchError(response);
                });
            }
        );
    }

    sendBackToBuyer(){
        this.setState(
            (state) => ({ ...state, loading: true}),
            () => {
                ProjectsRepository.sendToBuyer(this.state.selectedProjectsIds).then(()=>{
                    this.setState((state) =>({...this.state, selectedProjectsIds: []}))
                    this.findAll()
                }).catch(response => {
                    this.catchError(response);
                });
            }
        );
    }

    confirmSapCreation(){
        this.setState(
            (state) => ({ ...state, loading: true}),
            () => {
                ProjectsRepository.confirmSapCreation(this.state.selectedProjectsIds).then(()=>{
                    this.setState((state) =>({...this.state, selectedProjectsIds: []}))
                    this.findAll()
                }).catch(response => {
                    this.catchError(response);
                });
            }
        );
    }

    exportForSAP(){
        this.setState(
            (state) => ({ ...state, loading: true}),
            () => {
                ProjectsRepository.exportForSAP(this.state.selectedProjectsIds).then((result)=>{
                    this.setState((state) =>({...this.state, selectedProjectsIds: []}))

                    var blob=new Blob([result.data]);
                    var link=document.createElement('a');
                    link.href=window.URL.createObjectURL(blob);
                    link.download="project_exported_sap_"+(new Date()).toISOString() + ".csv";
                    link.click();

                    this.findAll()
                }).catch(response => {
                    this.catchError(response);
                });
            }
        );
    }

    exportToMicrosoft(){
        this.setState(
            (state) => ({ ...state, loading: true}),
            () => {
                ProjectsRepository.exportToMicrosoft(this.state.selectedProjectsIds).then((result)=>{
                    this.setState((state) =>({...this.state, selectedProjectsIds: []}))

                    var blob=new Blob([result.data]);
                    var link=document.createElement('a');
                    link.href=window.URL.createObjectURL(blob);
                    link.download="project_exported_to_microsoft_"+(new Date()).toISOString() + ".csv";
                    link.click();

                    this.findAll()
                }).catch(response => {
                    this.catchError(response);
                });
            }
        );
    }

    buyerBackToModification(){
        this.setState(
            (state) => ({ ...state, loading: true}),
            () => {
                ProjectsRepository.backToModification(this.state.selectedProjectsIds).then(()=>{
                    this.setState((state) =>({...this.state, selectedProjectsIds: []}))
                    this.findAll()
                }).catch(response => {
                    this.catchError(response);
                });
            }
        );
    }

    backToModification(){
        this.setState(
            (state) => ({ ...state, loading: true}),
            () => {
                ProjectsRepository.backToModification(this.state.selectedProjectsIds).then(()=>{
                    this.setState((state) =>({...this.state, selectedProjectsIds: []}))
                    this.findAll()
                }).catch(response => {
                    this.catchError(response);
                });
            }
        );
    }

    exportToEcom(finishProject=false){
        this.setState(
            (state) => ({ ...state, loading: true}),
            () => {
                ProjectsRepository.exportToEcom(this.state.selectedProjectsIds, finishProject).then((result)=>{
                    this.setState((state) =>({...this.state, selectedProjectsIds: []}))

                    if(!finishProject){
                        var blob=new Blob([result.data]);
                        var link=document.createElement('a');
                        link.href=window.URL.createObjectURL(blob);
                        link.download="project_ecom_exported_"+(new Date()).toISOString() + ".csv";
                        link.click();
                    }

                    this.findAll()
                }).catch(response => {
                    this.catchError(response);
                });
            }
        );
    }

    handleTableChange = (type, {searchText, page, sizePerPage, filters, sortField, sortOrder, cellEdit}) => {
        if (type === 'pagination') {
        } else if (type === 'sort') {

        } else if (type === 'filter') {

        } else if (type === 'search') {
            this.setState(
                (state) => ({
                    ...state,
                    query: searchText
                }),
                () => {
                    this.findAll();
                }
            );
        }
    }

    toggleDialogModal = () => {
        this.setState((state) => ({
            ...state,
            loading: false,
            dialog: {...state.dialog, show: !state.show}
        }));
    }

    closeDialogModal = () => {
        this.setState((state) => ({
            ...state,
            loading: false,
            dialog: {...state.dialog, show: false}
        }));
    }

    performDelete = () => {
        if (this.state.entity.id) {
            this.setState(
                (state) => ({
                    ...state,
                    loading: true,
                }),
                () => {
                    const idToRemove = this.state.entity.id;
                    ProjectsRepository.remove(this.state.entity.id).then(data => {
                        console.log(data);
                        this.setState((state) => ({
                            ...state,
                            loading: false,
                            id: null,
                            total: state.total - 1,
                            items: state.items.filter(elt => elt.id !== idToRemove)
                        }));
                        this.closeDialogModal();
                        this.onAllDoneSuccessfully();
                    }).catch(response => {
                        console.log(response)
                        this.catchError(response);
                       // this.closeDialogModal();
                    });
                }
            );
        }
    }

    onAllDoneSuccessfully = ()=>{
        const dialog = {
            ...this.state.dialog,
            show: true,
            handleClose: () => this.closeDialogModal(),
            type: DIALOG_TYPE.SUCCESS,
            message: this.props.t("SUCCESSFULLY_DELETED")
        }
        this.setState((state) => ({
            ...state,
            dialog: dialog,
        }));
    }
    catchError = (response) => {
        const dialog = {
            ...this.state.dialog,
            show: true,
            onConfirm: () => this.closeDialogModal(),
            handleClose: () => this.closeDialogModal(),
            type: DIALOG_TYPE.ALERT,
            message: ""
        }
        switch (response.status) {
            case HTTP_RESPONSE.HTTP_BAD_REQUEST: {
                dialog.type = DIALOG_TYPE.ALERT;
                break;
            }
            case HTTP_RESPONSE.HTTP_NOT_FOUND: {
                dialog.type = DIALOG_TYPE.ALERT;
                if (response.data.message) {
                    dialog.message = response.data.message;
                }else{
                    dialog.message = response.data.error.message;
                }
                break;
            }
            case HTTP_RESPONSE.HTTP_NOT_ACCEPTABLE : {
                dialog.type = DIALOG_TYPE.NOT_ACCEPTABLE
                dialog.message = response.data.error.message
                break;
            }
            case HTTP_RESPONSE.HTTP_UNAUTHORIZED: {
              
                dialog.type = DIALOG_TYPE.UNAUTHORIZED;
                try {
                    const err = JSON.parse(response.data.error.message)
                    console.log("Error:",err)
                    dialog.message = this.props.t(err.message) + ( err.name ?  "->" + err.name : "")
                    //adding log to message
                    /*if(err.logs){
                        //loop trough log and slpit message with ; separator to display each line in a new line the first line is the name
                       for (let index = 0; index < err.logs.length; index++) {
                           const element = err.logs[index];
                           dialog.message += "<br> " + element.name + "<br>"
                           dialog.message += "\r\n" + element.message.split(";").join("\r\n")
                       } */
                      //  dialog.message += "<br/>" + err.logs.message.split(";").join("<br/>")
                    //}
                }catch(e){
                    dialog.message = this.props.t(err.message ? err.message : "")
                }
                break;
            }
            case HTTP_RESPONSE.HTTP_INTERNAL_SERVER_ERROR: {
                if (response.data.message) {
                    dialog.message = response.data.message;
                }else{
                    dialog.message = response.data.error.message;
                }
                dialog.type = DIALOG_TYPE.SERVER_ERROR;
                break;
            }
            default:
        }
        this.setState((state) => ({
            ...state,
            dialog: dialog,
        }));
    }

    /**
     *  Methode appelée quand la valeur d'un champ de formulaire change
     *
     * @param target
     */
    handleFormInputChange = ({target}) => {
        const {name, value} = target;
        this.setState(
            (state) => ({
                ...state,
                [name]: value,
                loadingSearch: true
            }), () => {
                this.findAll();
            }
        );
    };

    handleOnPagePerPageChange = (p) => {
        this.setState(
            (state) => ({
                ...state,
                itemPerpage: p,
                page: 1,
                loading: true,
                pageSizeOptions: {
                    ...state.pageSizeOptions,
                    sizePerPage: p,
                    page: 1,
                }
            }),
            () => {
                this.setState(
                    (state) => ({
                        ...state,
                        loading: true
                    }), () => { 
                        this.findAll();
                    }
                );
            }
        );
    }

    render() {
        return <>
            <div className="page-content">
                <MetaTags>
                    <title>Projects</title>
                </MetaTags>
                <Container fluid={true}>
                    <LoadingContainer loading={this.state.loading}>
                        <Card>
                            <CardBody>
                                <div className="mb-4">
                                    <Row>
                                        <Col xs="12">
                                            <div className="page-title-box justify-content-between">
                                                <div className="">
                                                    <ol className="breadcrumb m-0">
                                                        <BreadcrumbItem>.</BreadcrumbItem>
                                                        <BreadcrumbItem>
                                                            <Link to="#">{this.props.t("PROJECTS")}</Link>
                                                        </BreadcrumbItem>
                                                    </ol>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col md="8" className="d-flex gap-2 align-items-center">
                                            {AuthorizationChecker.isGranted(["VENDOR_CREATE_PROJECT"]) &&  <Link className="btn btn-primary" to="/pages-projects/add"> {(this.props.t("ADD_NEW_PROJECT"))}</Link>}
                                            {AuthorizationChecker.isGranted(["VENDOR_SEND_TO_MONDOU"]) &&  <button className="btn btn-primary" disabled={this.state.selectedProjectsIds.length==0} onClick={()=>{this.sendToMondou()}}>{(this.props.t("SEND_TO_MONDOU"))}</button>}
                                            {AuthorizationChecker.isGranted(["VENDOR_SEND_TO_REN_PET"]) &&  <button className="btn btn-primary" disabled={this.state.selectedProjectsIds.length==0} onClick={()=>{this.sendToRenPet()}}>{(this.props.t("VENDOR_SEND_TO_REN_PET"))}</button>}

                                            {AuthorizationChecker.isGranted(["BUYER_EDIT_PROJECT"]) &&  <button className="btn btn-primary"  disabled={this.state.selectedProjectsIds.length==0} onClick={()=>{this.sendBackToVendor()}}>{(this.props.t("SEND_BACK_TO_VENDOR"))} </button>}

                                            {AuthorizationChecker.isGranted(["BUYER_SENT_TO_DM"]) &&  <button className="btn btn-primary" onClick={()=>{this.sendToDM()}} disabled={this.state.selectedProjectsIds.length==0}>{(this.props.t("SEND_TO_DM"))} </button>}

                                            { ( AuthorizationChecker.isGranted(["MD_EDIT_PROJECT"]) || AuthorizationChecker.isGranted(["ECOM_READ_PROJECT"]) ) &&  <button className="btn btn-primary" disabled={this.state.selectedProjectsIds.length==0} onClick={()=>{this.sendBackToBuyer()}}>{(this.props.t("SEND_BACK_TO_PURCHASER"))}</button>}

                                            {AuthorizationChecker.isGranted(["MD_EXPORT_FOR_SAP"]) &&  <button className="btn btn-primary"  onClick={()=>{this.exportForSAP()}} disabled={this.state.selectedProjectsIds.length==0}>{(this.props.t("EXPORT_SAP"))} </button>}

                                            {AuthorizationChecker.isGranted(["BUYER_EXPORT_TO_MICROSOFT"]) &&  <button className="btn btn-primary"  onClick={()=>{this.exportToMicrosoft()}} disabled={this.state.selectedProjectsIds.length==0}>{(this.props.t("BUYER_EXPORT_TO_MICROSOFT"))} </button>}

                                            {AuthorizationChecker.isGranted(["BUYER_EXPORT_TO_MICROSOFT"]) && this.state.companyId==2  && <button className="btn btn-primary"  onClick={()=>{this.buyerBackToModification()}} disabled={this.state.selectedProjectsIds.length==0}>{(this.props.t("BACK_TO_MODIFICATION"))} </button>}

                                            {AuthorizationChecker.isGranted(["MD_CONFIRM_SAP"]) &&  <button className="btn btn-primary"  onClick={()=>{this.backToModification()}} disabled={this.state.selectedProjectsIds.length==0}>{(this.props.t("BACK_TO_MODIFICATION"))} </button>}

                                            {AuthorizationChecker.isGranted(["MD_CONFIRM_SAP"]) &&  <button className="btn btn-primary"  onClick={()=>{this.confirmSapCreation()}} disabled={this.state.selectedProjectsIds.length==0}>{(this.props.t("CONFIRM_CREATION_SAP"))} </button>}

                                            { AuthorizationChecker.isGranted(["ECOM_READ_PROJECT"]) &&  <button className="btn btn-primary" onClick={()=>{this.exportToEcom()}} disabled={this.state.selectedProjectsIds.length==0}>{(this.props.t("EXPORT_FOR_ECOM"))} </button> } 

                                            {AuthorizationChecker.isGranted(["ECOM_READ_PROJECT"]) &&  <button className="btn btn-primary" onClick={()=>{this.exportToEcom(true)}} disabled={this.state.selectedProjectsIds.length==0}>{(this.props.t("END_PROJECT"))} </button>}

                                            { AuthorizationChecker.isGranted(["BUYER_EDIT_PROJECT"]) &&  <button className="btn btn-primary" onClick={()=>{this.sendToEcom()}}  disabled={this.state.selectedProjectsIds.length==0}>{(this.props.t("SEND_TO_ECOM"))}</button>}

                                        </Col>
                                        <Col md="4">
                                            <div className="search-box me-2 mb-2 d-inline-block float-end">
                                                <form className="search-box d-inline-block">
                                                    <div className="position-relative">
                                                        <label htmlFor="search-bar" className="search-label">
                                                            <span id="search-bar-label" className="sr-only">{this.props.t("FIND_IN_TABLE")}</span>
                                                            <input
                                                                id="search-bar"
                                                                type="text"
                                                                name="query"
                                                                className="form-control "
                                                                placeholder={this.props.t("SEARCH")}
                                                                onChange={(e) => this.handleFormInputChange(e)}
                                                                value={this.state.query}/>
                                                        </label>
                                                        <i className="bx bx-search-alt search-icon"/>
                                                    </div>
                                                </form>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <PaginationWrap
                                    page={this.state.page}
                                    paginationProps={this.state.pageSizeOptions}
                                    handleOnPagePerPageChange={(p) => this.handleOnPagePerPageChange(p)}
                                    itemPerpage={this.state.itemPerpage}
                                    onPageChange={(p) => this.onPageChange(p)}
                                    totalItems={this.state.total}
                                />
                                <LoadingContainer loading={this.state.loadingSearch}>
                                    <Row>
                                        <Col xl="12">
                                            <div className="table-responsive">
                                                <div className="react-bootstrap-table">
                                                    <table
                                                        className="table table align-middle table-nowrap">
                                                        <thead className="thead-light">
                                                        <tr>
                                                            <th tabIndex="0">
                                                                 <Input type="checkbox" onChange={(e)=>{this.selectAllProject(e)}}/>
                                                            </th>
                                                            <th tabIndex="0"/>
                                                            <th tabIndex="0">{this.props.t("VENDOR")}</th>
                                                            {/*<th tabIndex="0">{this.props.t("PROJECT_ID")}</th>*/}
                                                            <th tabIndex="0">{this.props.t("PROJECT_NAME")}</th>
                                                            { this.state.companyId!=2 && (<th tabIndex="0">{this.props.t("LEVEL_1")} </th>)}
                                                            <th tabIndex="0">{this.props.t("STATUS")}</th>
                                                            {   (   AuthorizationChecker.isGranted(["BUYER_READ_PROJECT"]) || 
                                                                    AuthorizationChecker.isGranted(["MD_READ_PROJECT"]) || 
                                                                    AuthorizationChecker.isGranted(["ECOM_READ_PROJECT"])
                                                                ) &&
                                                                (<th tabIndex="0">{this.props.t("PURCHASER")}</th>)
                                                            }
                                                            {   (   AuthorizationChecker.isGranted(["BUYER_READ_PROJECT"]) || 
                                                                    AuthorizationChecker.isGranted(["MD_READ_PROJECT"]) || 
                                                                    AuthorizationChecker.isGranted(["ECOM_READ_PROJECT"])
                                                                ) &&
                                                                (<th tabIndex="0">{this.props.t("DM")}</th>)
                                                            }
                                                            
                                                            <th tabIndex="0">{this.props.t("LAST_COMMENT")} </th>
                                                            { ( AuthorizationChecker.isGranted(["BUYER_EDIT_PROJECT"])  || 
                                                                AuthorizationChecker.isGranted(["ECOM_READ_PROJECT"])
                                                              )&&
                                                                (
                                                                    <th tabIndex="0">{this.props.t("SELF_ASSIGN")}</th>
                                                                )
                                                            }
                                                            { AuthorizationChecker.isGranted(["MD_EDIT_PROJECT"]) &&
                                                                (
                                                                    <th tabIndex="0">{this.props.t("SELF_ASSIGN")}</th>
                                                                )
                                                            }
                                                            <th tabIndex="0"> {this.props.t("ASSOCIATED_PRODUCTS")}</th> 
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {this.state.items.length > 0 && this.state.items.map((item, index) =>
                                                            <tr key={index}>
                                                                <td> <Input type="checkbox" onClick={(e)=>{this.selectProject(item.id,e)}} onChange={()=>{}} checked={this.state.selectedProjectsIds.indexOf(item.id)>=0}/> </td>
                                                                <td>
                                                                    <ActionsFormatter
                                                                        editRole={
                                                                            AuthorizationChecker.isGranted(["VENDOR_READ_PROJECT"]) ? "VENDOR_EDIT_PROJECT" :
                                                                            AuthorizationChecker.isGranted(["BUYER_READ_PROJECT"]) ? "BUYER_EDIT_PROJECT" :
                                                                            AuthorizationChecker.isGranted(["MD_READ_PROJECT"]) ? "MD_EDIT_PROJECT" : 
                                                                            AuthorizationChecker.isGranted(["ECOM_READ_PROJECT"]) ? "ECOM_EDIT_PROJECT" : 
                                                                            ""
                                                                        }
                                                                        enableRemove = {
                                                                            AuthorizationChecker.isGranted(["VENDOR_EDIT_PROJECT"]) ? (this.state.CORRECT_STATUS.indexOf(item.statusId) >=0) :
                                                                            AuthorizationChecker.isGranted(["BUYER_EDIT_PROJECT"]) ?  ( (this.state.CORRECT_STATUS.indexOf(item.statusId) >=0) && item.purchaserId==UserProvier.get().id) : true
                                                                        }
                                                                        item={item}
                                                                        enableEdit={
                                                                            AuthorizationChecker.isGranted(["VENDOR_READ_PROJECT"]) ? (this.state.CORRECT_STATUS.indexOf(item.statusId) >=0) :
                                                                            AuthorizationChecker.isGranted(["BUYER_READ_PROJECT"]) ?  ( (this.state.CORRECT_STATUS.indexOf(item.statusId) >=0) && item.purchaserId==UserProvier.get().id) :
                                                                            AuthorizationChecker.isGranted(["MD_READ_PROJECT"]) ?  ( (this.state.CORRECT_STATUS.indexOf(item.statusId) >=0) && item.dmId==UserProvier.get().id) :
                                                                            AuthorizationChecker.isGranted(["ECOM_READ_PROJECT"]) ?  ( (this.state.CORRECT_STATUS.indexOf(item.statusId) >=0) && item.ecommId==UserProvier.get().id) : true
                                                                        }
                                                                        onDelete={(event, elt) => this.handleDelete(event, elt)}/>
                                                                </td>
                                                                <td>{item.vendorName}</td>
                                                                {/*<td>{item.id}</td>*/}
                                                                <td>{item.name}</td>
                                                                { this.state.companyId!=2 && ( <td>{item.niveau1 ? item.niveau1.description : ""}</td>)}
                                                                <td>
                                                                    <Badge className={"bg-primary"}>
                                                                        { this.parseState(item.statusId)}
                                                                    </Badge>
                                                                </td>
                                                                {   (   AuthorizationChecker.isGranted(["BUYER_READ_PROJECT"]) || 
                                                                        AuthorizationChecker.isGranted(["MD_READ_PROJECT"]) || 
                                                                        AuthorizationChecker.isGranted(["ECOM_READ_PROJECT"])
                                                                    ) &&
                                                                    (<td> {item.purchaserId ? item.purchaserName: ""}</td>)
                                                                }
                                                                {   (   
                                                                        AuthorizationChecker.isGranted(["BUYER_READ_PROJECT"]) || 
                                                                        AuthorizationChecker.isGranted(["MD_READ_PROJECT"]) || 
                                                                        AuthorizationChecker.isGranted(["ECOM_READ_PROJECT"])
                                                                    ) &&
                                                                    (<td> {item.dmId ? item.dmName : ""}</td>)
                                                                }
                                                               
                                                                <td>{
                                                                        (item.lastComment.comments && item.lastComment.isPrivate == 0) && 
                                                                            (
                                                                                <div className="flex-grow-1">
                                                                                    <p className="text-muted mb-0">
                                                                                        <i className="mdi mdi-calendar me-1">  </i>
                                                                                        {item.lastComment.createdAt}
                                                                                        </p>
                                                                                        <h5 className="font-size-13 mb-1">{item.lastComment.user.firstname} {item.lastComment.user.lastname}</h5>
                                                                                            <p className="text-muted mb-1  ms-3"><span dangerouslySetInnerHTML={{ __html: item.lastComment.comments }} /></p>
                                                                                
                                                                                </div>
                                                                            ) 
                                                                    }

                                                                    {  
                                                                        ((item.lastComment.comments && item.lastComment.isPrivate == 1) &&
                                                                        (
                                                                            AuthorizationChecker.isGranted(["BUYER_READ_PROJECT"]) || 
                                                                            AuthorizationChecker.isGranted(["MD_READ_PROJECT"]) || 
                                                                            AuthorizationChecker.isGranted(["ECOM_READ_PROJECT"])  
                                                                        )) &&
                                                                                
                                                                            (
                                                                                <div className="flex-grow-1">
                                                                                <p className="text-muted mb-0">
                                                                                  <i className="mdi mdi-calendar me-1">  </i>
                                                                                  {item.lastComment.createdAt + " "}{item.lastComment.isPrivate ? (<span className="badge rounded-pill bg-danger">
                                                                                                {this.props.t("_PRIVATE_")}
                                                                                              </span>):""} </p>
                                                                                <h5 className="font-size-13 mb-1">{item.lastComment.user.firstname} {item.lastComment.user.lastname}</h5>
                                                                                <p className="text-muted mb-1  ms-3"><span dangerouslySetInnerHTML={{ __html: item.lastComment.comments }} /></p>
                                                                                
                                                                              </div>
                                                                            )
                                                                    }
                                                                </td>

                                                                { AuthorizationChecker.isGranted(["BUYER_EDIT_PROJECT"]) &&
                                                                    (
                                                                        <td> 
                                                                            { !(item.purchaserId) ? (
                                                                                    <a style={{marginLeft: "15px"}} className="btn" href="#" onClick={(event) => this.assignProject(item.id)}>
                                                                                        <i className='bx bxs-hand-up action-icon'></i>
                                                                                    </a> 
                                                                                ) :
                                                                                this.props.t("ASSIGNED")
                                                                            }
                                                                        </td>
                                                                    )
                                                                }

                                                                { AuthorizationChecker.isGranted(["MD_EDIT_PROJECT"]) &&
                                                                    (
                                                                        <td> 
                                                                            { !(item.dmId) ? (
                                                                                    <a style={{marginLeft: "15px"}} className="btn" href="#" onClick={(event) => this.assignProject(item.id)}>
                                                                                        <i className='bx bxs-hand-up action-icon'></i>
                                                                                    </a> 
                                                                                ) :
                                                                                this.props.t("ASSIGNED")
                                                                            }
                                                                        </td>
                                                                    )
                                                                }

                                                                { AuthorizationChecker.isGranted(["ECOM_READ_PROJECT"]) &&
                                                                    (
                                                                        <td> 
                                                                            { !(item.ecommId) ? (
                                                                                    <a style={{marginLeft: "15px"}} className="btn" href="#" onClick={(event) => this.assignProject(item.id)}>
                                                                                        <i className='bx bxs-hand-up action-icon'></i>
                                                                                    </a> 
                                                                                ) :
                                                                                this.props.t("ASSIGNED")
                                                                            }
                                                                        </td>
                                                                    )
                                                                }
                                                                <td>
                                                                    <Link className="btn" to={ '/pages-projects/' + item.id}><i className="bx bxs-show action-icon"/></Link>
                                                                </td>
                                                            </tr>)}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </LoadingContainer>
                                <PaginationWrap
                                    page={this.state.page}
                                    paginationProps={this.state.pageSizeOptions}
                                    handleOnPagePerPageChange={(p) => this.handleOnPagePerPageChange(p)}
                                    itemPerpage={this.state.itemPerpage}
                                    onPageChange={(p) => this.onPageChange(p)}
                                    totalItems={this.state.total}
                                />
                            </CardBody>
                        </Card>
                    </LoadingContainer>
                </Container>
            </div>
            <DialogBoxComponent
                handleClose={() => this.closeDialogModal()}
                handleConfirm={() => this.state.dialog.onConfirm()}
                show={this.state.dialog.show}
                type={this.state.dialog.type}
                message={this.state.dialog.message}
            />
        </>;
    }
}

export default withTranslation()(PagesProjects)
