import React from 'react'
import {Link, useLocation} from "react-router-dom";
import AuthorizationChecker from "../../../security/AuthorizationChecker";
import { Button } from 'reactstrap';

const ActionsFormatterSupplierList = (props) => {
    const {user, supplier, enableEditSupplier = false, onClick={}, deleteRole = "SUPPLIER_UPLOAD", location = useLocation()} = props;
    return <div className="bx-pull-right file-system">
        {(enableEditSupplier && AuthorizationChecker.isGranted([deleteRole])) &&
        <Button onClick={(e) => onClick(e,supplier)} color="danger" className="btn btn-danger btn-sm">
            <i className="bx bx-trash"></i>
        </Button>}
    </div>
}

export default ActionsFormatterSupplierList

