import React, {useEffect, useState} from "react";

import LoadingContainer from "../Utility/Helpers/LoadingContainer";
import {DIALOG_TYPE, HTTP_RESPONSE} from "../Utility/Helpers/constants";
import DialogBoxComponent from "../Utility/Helpers/DialogBoxComponent";
import "../Utility/Helpers/style.css";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Label,
  Input} from "reactstrap"
import ProductRepository from "./Repository/product-repository";
import { withTranslation } from "react-i18next";

/**
 *
 * Modal utilisée dans toute l'application
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const PagesDetailProducts = (props) => {
  const {productId = 0, apiUrl} = props;
  const [loading, setLoading] = useState(true);

  const[product, setProduct] = useState(null)


  useEffect(() => {
    if (productId) {
      setLoading(true);
      ProductRepository.findIncludeAll(productId).then((item) => {
        setLoading(false);
        setProduct(item);
      })
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <React.Fragment>
      <LoadingContainer className="mb-4" loading={loading}>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle>{props.t("PRODUCT")}</CardTitle>
                <div>
                  <div className="row">
                    <div className="col-md-8 col-12">
                       
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LoadingContainer>
    </React.Fragment>
  )
};

export default withTranslation()(PagesDetailProducts)