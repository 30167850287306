import axios from "axios";
import {HTTP_RESPONSE} from "../../Utility/Helpers/constants";
import UserProvier from "../../../security/UserProvier";
import AuthorizationChecker from "../../../security/AuthorizationChecker";

UserProvier.setToken();

export const DATA_API_URL = process.env.REACT_APP_API_BASE_URL + 'skucodes';

const SkuRepository = {

    uploadAttachment: async (item) => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        const formData = new FormData();
        // Update the formData object
        formData.append(
            "product",
            item,
            item.name,
        );
        try {
            const response = await axios
                .post(DATA_API_URL + '/importsku', formData, config);
                console.log(response);
            return response.data;
        } catch (e) {
            if (e.response) {
                throw e.response;
            } else {
                throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND };
            }
        }
    },
    getAll : async (cieId) => {
        try {
            const response = await axios.get(DATA_API_URL + '/all/' + cieId);
            return response.data;
        } catch (e) {
            if (e.response) {
                throw e.response;
            } else {
                throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND };
            }
        }
    },

    delete : async (id) => {
        try {
            const response = await axios.delete(DATA_API_URL + '/' + id);
            return response.data;
        } catch (e) {
            if (e.response) {
                throw e.response;
            } else {
                throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND };
            }
        }
    },

}

export default SkuRepository;
