/**
 *
 * Affiche une modale de confirmation d'action.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
import React, {Fragment} from "react";

const DialogBoxContentComponent = (props) => {
    // eslint-disable-next-line react/prop-types
    const {title = 'Une erreur s\'est produite', message = 'Veillez contacter l\'administrateur du system!', type = 'alert'} = props;
    const types = {
        info: {color: '#00a3ff', icon: 'ik-alert-circle'},
        alert: {color: '#f7286f', icon: 'ik-alert-triangle'},
        danger: {color: '#cd4841', icon: 'ik-alert-triangle'},
        success: {color: '#33ac6a', icon: 'ik-check-circle'},
        warning: {color: '#cd8c39', icon: 'ik-alert-triangle'}
    };
    const style = (type) => ({
        i: {fontSize: '20px', color: types[type].color,},
        title: {color: '#636363', fontSize: '14px',},
        header: {borderColor: types[type].color,}
    });
    return (
        <Fragment>
            <div className="toast-header" style={style(type).header}>
                <i className={`ik ${types[type].icon} mr-2`} style={style(type).i}/>
                <strong className="mr-auto" style={style(type).title}>{title}</strong>
                <button type="button" className="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="toast-body" style={style(type).title}>
                {message}
            </div>
        </Fragment>
    )
};

export default DialogBoxContentComponent;
