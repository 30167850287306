// src/components/filter.
import React, { useEffect, useState, useMemo } from "react"
import {useParams} from "react-router-dom";
import PropTypes from 'prop-types';

//import components
import TableContainer from '../../components/Common/TableContainer';
import { withTranslation } from "react-i18next";

const TableTimeline = (props) => {
    //use Memo to avoid re-rendering
    const {logs} = props;
    const [data, setData] = useState([]);
    const columns = useMemo(() => [
        {
            Header: "Date",
            accessor: "date"
        },
        {
            Header: "Action",
            accessor: "action"
        },
        {
            Header:props.t("_User_"),
            accessor: "user"
        },
        {
            Header: props.t("_LOG_TYPE_"),
            accessor:"type"
        },
        {
            Header: props.t("_MORE_INFO_"),
            accessor: "info"
        }
    ], []);

    useEffect(async () => {
        if (logs) {
            //map the logs to the table columns
            const mappedData = await logs.map(log => {
                return {
                    //display the date in a readable format
                    date: log.createdAt.split("T")[0] + " " + log.createdAt.split("T")[1].split(".")[0],
                    action: props.t(log.projectLogsType.key),
                    user: log.user.firstname + " " + log.user.lastname,
                    type: log.type,
                    info: log.info
                }
            });
            setData(mappedData);
        }
    
    }, [logs]);

    return (
        <TableContainer
                    columns={columns}
                    data={data}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    iscustomPageSizeOptions={true}
                    customPageSize={10}
                    isPagination={true}
                    tableClass="align-middle table-nowrap table-check table"
                    theadClass="table-light"
                    paginationDiv="col-12"
                    pagination="justify-content-center pagination pagination-rounded"
                />
    );
}
TableContainer.propTypes = {
    logs: PropTypes.array,
    t: PropTypes.func
}
export default withTranslation()(TableTimeline);

