import React from 'react'
import {Col, Row} from "reactstrap";
import {SizePerPageDropdownStandalone} from "react-bootstrap-table2-paginator";
import PaginationComponent from "./PaginationComponent";
import { withTranslation } from "react-i18next";

const PaginationWrap = (props) => {
    // eslint-disable-next-line react/prop-types
    const {paginationProps, handleOnPagePerPageChange, itemPerpage = 10, onPageChange, totalItems, page = 1} = props;
    return <>
        <Row className="align-items-md-center">
            <Col className="inner-custom-pagination d-flex">
                <div className="text-md-start">
                    <SizePerPageDropdownStandalone
                        {...paginationProps}
                        sizePerPageList={
                            [
                                {text: '5', value: 5},
                                {text: '10', value: 10},
                                {text: '15', value: 15},
                                {text: '20', value: 20},
                                {text: '25', value: 25},
                                {text: '50', value: 50},
                                {text: '100', value: 100},
                            ]
                        }
                        onSizePerPageChange={(p) => handleOnPagePerPageChange(p)}
                    />
                </div>
            </Col>
            <Col className="inner-custom-pagination d-flex">
                <div className="text-md-right ms-auto">
                    <div className="align-items-md-center row">
                        <div className="inner-custom-pagination d-flex col">
                            <span className="text-md-right" style={{marginRight: "20px", marginTop: '10px'}}>
                                {totalItems > 0 ? <>
                                    {totalItems} {props.t("FOUND_LINES")}
                                </> : <>
                                {props.t("NO_FOUND_LINES")}
                                </>}
                            </span>
                            <div className="text-md-right ms-auto">
                                <PaginationComponent
                                    activePage={page}
                                    itemsPerPage={itemPerpage}
                                    onPageChange={(p) => onPageChange(p)}
                                    totalItems={totalItems}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    </>
};

export default withTranslation()(PaginationWrap);
