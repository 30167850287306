/**
 * Helper pour manager les champs select autocomplete des formulaires.
 * Dans une mésure du possible, ce helper peut être modularisé si les méthode prennent du nombre.
 */

import ReferenceValueRepository from "../pages/Products/Repository/reference-value-repository";
import ProjectsRepository from "../pages/Projects/Repository/project-repository";
import ProductsRepository from "../pages/Products/Repository/product-repository";

const Autocomplete = {
    /**
     * Autocomplete sur les tags
     * @param inputValue
     */
    referencesValues: (inputValue) => {
        return ReferenceValueRepository.findAll( 1,10, inputValue,0).then((data) => {return data.items})
    },

    productReferencesValues: (inputValue,field) => {
        return ProductsRepository.findReferences( 1,30, inputValue,field).then((data) => {return data.items})
    },

    supplierAccounts:  (inputValue) => {
        return ProjectsRepository.getSupplierAccounts(inputValue?inputValue:"").then((data) => {return data})
    },

    productNiveaux:  (niveau, parentId=0,search="") => {
        return ProductsRepository.findNiveauN(niveau, parentId,search).then((data) => {return data})
    },

    referencesValuesWithHierachie:  (field, parentId=0,search="") => {
        return ProductsRepository.findReferencesWithHierachie(field, parentId,search).then((data) => {return data})
    },
};

export default Autocomplete;
