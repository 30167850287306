import React, { useEffect, useState } from "react"
import {useParams} from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Label,
    Input,
    Button} from "reactstrap"
import MetaTags from 'react-meta-tags';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import LoadingContainer from "../Utility/Helpers/LoadingContainer";
import {Link} from "react-router-dom";
import ProjectsRepository from "./Repository/project-repository";
import ProjectCommentsRepository from "./Repository/project-comments-repository";
import ProjectLogsRepository from "./Repository/project-logs-repository";
import {DIALOG_TYPE, HTTP_RESPONSE} from "../Utility/Helpers/constants";
import AuthorizationChecker from "../../security/AuthorizationChecker";
import AutocompleteSelectType from "../../helpers/Form/AutocompleteSelectType";

import { withTranslation } from "react-i18next";
import UserProvier from "security/UserProvier";
import Comments from "./comments";
import TeamMembers from "./teamMembers";
import HorizontalTimeline from "./horizontal-timeline";
import VerticalTimeline from "./vertical-timeline"
import TableTimeline from "./table-timeline";
import TimelineTable from "./timeline-table";

const PagesDetailProjects = (props) =>{
    const params = useParams();
    const {id = 0} = props;
    const companyId = UserProvier.get().companyId

    const [project, setProject] = useState(null);
    const [projectLogs, setProjectLogs] = useState([]);
    //state to mange timeline style, horizontal or vertical
    const [timelineStyle, setTimelineStyle] = useState("horizontal");
    const [loading, setLoading] = useState(true);
    const [commentaires,setCommentaires] = useState([]);   //TODO: get comments
    const [team, setTeam] = useState([]);
    const user = UserProvier.get();
    const [dialog, setDialog] = useState({
        show: false,
        onConfirm: () => {
        },
        handleClose: () => {
        },
        message: "",
        type: DIALOG_TYPE.ALERT
    });

    useEffect( () => {
        if (id) {
            ProjectCommentsRepository.findWithFullMembers(id).then(item => {
                console.log("ITEM", item);
                setLoading(false);
                
                ProjectsRepository.getSupplierAccount(item.compteSupplierId).then(account => {
                    setProject({
                        ...item, 
                        compteSupplierId: account
                    });
                    setLoading(false);

                }).catch(response => {
                    catchError(response);
                });
            }).catch(response => {
                catchError(response);
            });
            

            //get team
            //check if DM and Ecom, BUYER, are set if yes create member object and add to team
            
        }
    },[id]);

    useEffect(() => {
        //get team
            //check if DM and Ecom, BUYER, are set if yes create member object and add to team
            if (project){
                let teams = [];
                if (project.vendorId){
                    teams.push({name: project.vendorName, img: "Null", skills: [{name: "Vendor"}]});
                }
                if (project.dmId ){
                    teams.push({name: project.dmName, img: "Null", skills: [{name: "DM"}]});
                }
                if (project.purchaserId){
                    teams.push({name: project.purchaserName, img: "Null", skills: [{name: "Buyer"}]});
                }
                if (project.ecomId){
                    teams.push({name: project.ecomName, img: "Null", skills: [{name: "Ecom"}]});
                }
                setTeam(teams);

                //get comments
            ProjectCommentsRepository.findAll(project.id).then(item => {
                
                //loop trought all comment, if comment is private, check if user is the VendorId, if yes, conitnue do not add it to temps variable
                //if comment is public, add it to temps variable
                let temp_comment = [];
                for (let i = 0; i < item.length; i++) {
                    if (item[i].isPrivate && (AuthorizationChecker.isGranted(["VENDOR_CREATE_PROJECT"]))) {
                        continue;
                    }
                    else {
                        temp_comment.push(item[i]);
                    }
                }
                setCommentaires(temp_comment);
                //setCommentaires(item);
            }).catch(response => {
                catchError(response);
            });
            }
            //get the project logs
            ProjectLogsRepository.findAll(id).then(logs => {
                console.log("LOGS", logs);
                setLoading(false);
                setProjectLogs(logs);
            }
            ).catch(response => {
                catchError(response);
            }
            );

    },[project]);

    const closeDialogModal = () => {
        setDialog({...dialog, show: false});
        setLoading(false);
    };

    const catchError = (response) => {
        const currentDialog = {
            show: true,
            onConfirm: () => closeDialogModal(),
            handleClose: () => closeDialogModal(),
            type: DIALOG_TYPE.ALERT
        };
        let status = HTTP_RESPONSE.HTTP_NOT_FOUND;
        if (response.status != undefined) {
            status = response.status;
        } else if (response.error != undefined && response.error.statusCode != undefined) {
            status = response.error.statusCode;
        }
        switch (status) {
            case HTTP_RESPONSE.HTTP_BAD_REQUEST: {
                currentDialog.type = DIALOG_TYPE.ALERT;
                break;
            }
            case HTTP_RESPONSE.HTTP_NOT_FOUND: {
                currentDialog.type = DIALOG_TYPE.ALERT;
                break;
            }
            case HTTP_RESPONSE.HTTP_INTERNAL_SERVER_ERROR: {
                currentDialog.type = DIALOG_TYPE.SERVER_ERROR;
                currentDialog.message = '';
                if (response.data.message) {
                    currentDialog.message = response.data.message;
                }
                break;
            }
            case HTTP_RESPONSE.HTTP_UNAUTHORIZED: {
                dialog.type = DIALOG_TYPE.UNAUTHORIZED;
                dialog.message = response.data.error.message
                break;
            }
            case HTTP_RESPONSE.HTTP_UNPROCESSABLE_ENTITY: {
                currentDialog.type = DIALOG_TYPE.SERVER_ERROR;
                if( response.data.error.code=="ER_DUP_ENTRY"){
                    currentDialog.message = props.t("PROJECT_ALREADY_EXIST");
                }else{
                    currentDialog.message = props.t("CHECK_FORM_FEILDS");
                }
                break;
            }
            default:
        }
        setDialog(currentDialog);
    };

    //add comment handle
    const addComment = (comment) => {
      
        const data = {
            userId: user.id,
            comments: comment.comments,
            isPrivate: comment.isPrivate,
            isReply: comment.isReply,
            projectId: id
        }
        ProjectCommentsRepository.add(data).then(item => {
            if (item && item.error) {
                catchError(item);
            }
            if (item){
                setCommentaires([...commentaires, item]);
            }
            else{
                catchError(item);
            }
        }
        ).catch(response => {
            catchError(response);
        });
    }

    return(
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Project | Detail</title>
                </MetaTags>
                <Container fluid={true}>
                    <div className="mb-4">
                        <Breadcrumbs title="Forms" breadcrumbItem={props.t("PROJECT_DETAIL")}/>
                        <Link className="btn btn-info" to={'/pages-projects'}>{props.t("BACK_TO_LIST")}</Link>
                        {  project &&  (project[ProjectsRepository.getUserIdColumnName()]==ProjectsRepository.getUserId() )  && (<Link className="btn btn-info ms-3" to={'/pages-projects/'+id+'/edit'} >{props.t("EDIT")}</Link>) }
                    </div>
                    <LoadingContainer className="mb-4" loading={loading}>
                        <Row>
                            <Col className="col-6">
                                <Card>
                                    <CardBody>
                                        <CardTitle>{props.t("PROJECT_DETAIL")}</CardTitle>
                                        <div className="row">
                                                <div className="col-md-8 col-12">
                                                    <div className="mb-3">
                                                        <Label className="form-label">{props.t("SUPPLIER_ACCOUNT")}</Label>
                                                        <AutocompleteSelectType
                                                            className={"input-form"}
                                                            choiceAttr={"nom"}
                                                            value={project? project.compteSupplierId : null}
                                                            placeholder={props.t("SELECT_SUPPLIER_ACCOUNT")}
                                                            isDisabled={true}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label className="form-label">{props.t("PROJECT_NAME")}</Label>
                                                        <Input
                                                            className="form-control"
                                                            type="text"
                                                            value={project ? project.name :  ""}
                                                            disabled={true}
                                                        />
                                                    </div>

                                                    {
                                                        companyId!=2 && (
                                                            <div className="mb-3">
                                                                <Label className="form-label">{props.t("LEVEL1")}</Label>
                                                                <AutocompleteSelectType
                                                                    className={"input-form"}
                                                                    choiceAttr={"description"}
                                                                    value={project && project.niveau1 ? project.niveau1 : null}
                                                                    placeholder={props.t("SELECT_LEVEL1")}
                                                                    isDisabled={true}
                                                                />
                                                            </div>
                                                        )
                                                    }

                                                    {// (!AuthorizationChecker.isGranted(["VENDOR_CREATE_PROJECT"])) && (
                                                        //<div className="mb-3">
                                                         //   <Label className="form-label">{props.t("PRIVATE_COMMENT")}</Label>
                                                         //   <Input
                                                           //     className="form-control"
                                                          //      type="textarea"
                                                          //      value={project ? project.private_comment :  ""}
                                                         //       disabled={true}
                                                        //    />
                                                       // </div>
                                                   // )
                                                    }

                                                    { /*(!AuthorizationChecker.isGranted(["VENDOR_CREATE_PROJECT"])) && (

                                                        <div className="mb-3">
                                                            <Label className="form-label">{props.t("PUBLIC_COMMENT")}</Label>
                                                            <Input
                                                                className="form-control"
                                                                type="textarea"
                                                                value={project ? project.public_comment :  ""}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    ) */}
                                                </div>
                                            </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="col-6">
                                <TeamMembers team={team} />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <Comments comments={commentaires}
                                            addComment={addComment}
                                            AuthorizationChecker={AuthorizationChecker}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <CardTitle>{props.t("PROJECT_LOGS")}</CardTitle>
                                        <div className="row">
                                            <div className="col-md-12 col-12">
                                               
                                                <div className="mb-3">
                                                    <Button color="primary" className="btn btn-primary" onClick={() => setTimelineStyle("horizontal")}>{props.t("HORIZONTAL")}</Button>
                                                    <Button color="primary" className="btn btn-primary ms-2" onClick={() => setTimelineStyle("vertical")}>{props.t("VERTICAL")}</Button>
                                                    <Button color="primary" className="btn btn-primary ms-2" onClick={() => setTimelineStyle("table")}>{props.t("TABLE")}</Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {timelineStyle === "horizontal" && (
                                                <HorizontalTimeline logs={projectLogs} />
                                            )}
                                            {timelineStyle === "vertical" && (
                                                <VerticalTimeline logs={projectLogs} />
                                            )}
                                            {timelineStyle === "table" && (
                                                <TimelineTable logs={projectLogs} name={project.name}/>
                                            )}
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </LoadingContainer>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default  withTranslation()(PagesDetailProjects)
