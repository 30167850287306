import React from "react";
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import {ReferenceViewComponent} from "ppm-react-references";
import {REFERENCES_API_URL} from "../../helpers/api-urls";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import AuthorizationChecker from "security/AuthorizationChecker";
import { withTranslation } from "react-i18next";

const PagesDetailReferences = (props) =>{
    const {id = 0} = props;
    const baseUrl = "/pages-references"

    return ( 
        <>
            <div className="page-content">
                <MetaTags>
                    <title>{props.t("REFERENCE")}</title>
                </MetaTags>
                
                <div className="mb-4">
                    <Breadcrumbs title="Reference" breadcrumbItem={props.t("REFERENCE_DETAIL")}/>
                    <Link className="btn btn-info" to={baseUrl}>{props.t("RETURN_TO_LIST")}</Link>

                    { AuthorizationChecker.isGranted(["EDIT_REFERENCES_TEMP"]) && ( <Link className="btn btn-info ms-3" to={baseUrl+"/"+id+"/edit"}>{props.t("EDIT")}</Link> )}
                    
                    { AuthorizationChecker.isGranted(["ADD_REFERENCES_TEMP"]) && (<Link className="btn btn-info ms-3" to={baseUrl+"/add"}>{props.t("ADD")}</Link> )}
                </div>
                <ReferenceViewComponent
                    id={id}
                    apiUrl={REFERENCES_API_URL}
                    addUrl={baseUrl+"/add"}
                    path={baseUrl}
                />
            </div>
        </>
    );
}

export default withTranslation()(PagesDetailReferences)
