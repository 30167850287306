/* eslint-disable react/prop-types */
import React, {Fragment, useEffect, useState} from "react";
import AutocompleteSelectType from "helpers/Form/AutocompleteSelectType";
import TextType from "helpers/Form/TextType";
import TextareaType from "helpers/Form/TextareaType";
import Autocomplete from "helpers/select.autocomplete";
import {USERS_API_URL} from "helpers/api-urls";
import DatePickerType from "helpers/Form/DatePickerType";
//import SelectType from "../../../helpers/Form/SelectType";
import {Label} from "reactstrap";
import { withTranslation } from "react-i18next";

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const CellEditor = (props) => {
    const {
        type = 'text',
        chooseAttr, field,
        value = null,
        disabled = false,
        handleCloseUpdate = {},
        handleCloseWithoutSave={},
        onUpdated = (e) => {
        },
        parentId = null,
    } = props;
    const [entity, setEntity] = useState("");
    const [editEnabled, setEditEnabled] = useState(true);
    useEffect(() => {
        if (type === "object" && value != null) {
            let item = value;
            try {
                item = JSON.parse(value);
            } catch (e) {
            }
            setEntity(item);
        } else {
            setEntity(value);
        }
    }, [value])

    /**
     *  Methode appelée quand la valeur d'un champ de formulaire change
     *
     * @param target
     */
    const handleFormInputChange = ({target}) => {
        console.log(target);
        onUpdated(target);
        const {name, value} = target;
        setEntity(value);
    };
    /**
     *  Methode appelée quand la valeur d'un champ de formulaire change
     *
     * @param target
     * @param field
     */
    const toogleEnable = ({target}) => {
        let newValue = !entity
        setEntity(newValue);
        onUpdated(newValue);
    };
    
    /**
     *  Quand on valide
     *
     * @param e
     */
    const handleClose = (e) => {
        e.preventDefault();
        setEditEnabled(false);
        handleCloseUpdate(e, entity);
    };
    /**
     *
     * @param inputValue
     * @todo Autocomplete object
     */
    const autocompleteObject = (inputValue,parent) => {
        if (parent)
        {
            return Autocomplete.referencesValuesWithHierachie(field,parent.id, inputValue);
        }
        else
        {
            return Autocomplete.productReferencesValues(inputValue,field);
        }
    };

    /**
     *
     */
    const selectPlaceholder = () => {
        switch (field) {
            case 'customer':
                return props.t("GET_CLIENT");
            default:
                return props.t("INPUT") + "..."
        }
    };

    return (
        <div style={{minWidth: "50px", position: "static", zIndex: "999999"}} className={"edit-inline-field d-flex " + (editEnabled ? " enabled " : "")}>
            {(type === "text" || type === "file" || type === "number") &&
                <TextType
                    id={field}
                    name={field}
                    value={entity}
                    className="edit-inline-field form-control"
                    placeholder={props.t("INPUT") + "..."}
                    onChange={(e) => handleFormInputChange(e)}
                    defaultCss={""}
                    disabled={disabled}
                />
            }
            {(type === "date") &&
                <DatePickerType
                    id={field}
                    name={field}
                    value={entity}
                    className="edit-inline-field form-control"
                    placeholder={props.t("CHOICE") + "..."}
                    onChange={(e) => handleFormInputChange(e)}
                    defaultCss={""}
                    disabled={disabled}
                />
            }
            {type === "textarea" &&
                <TextareaType
                    id={field}
                    name={field}
                    value={entity}
                    className="edit-inline-field form-control"
                    placeholder={props.t("INPUT") + "..."}
                    onChange={(e) => handleFormInputChange(e)}
                    defaultCss={""}
                    disabled={disabled}
                />
            }
            {type === "checkbox" && 
                <div className="form-check form-switch form-switch-lg">
                    <input
                        id={field}
                        name={field}
                        className="form-check-input"
                        type="checkbox"
                        onClick={(e) => toogleEnable(e)}
                        onChange={(e)=>{}}
                        checked={entity}
                        disabled={disabled}
                    />
                    <label
                        className="form-check-label"
                        htmlFor="customSwitchsizelg"
                    >
                        {entity ? props.t("YES") : props.t("NO")}
                    </label>
                </div>
            }

            { (type === "object") &&
            <Fragment>
                <AutocompleteSelectType
                    id={field}
                    name={field}
                    multiple={false}
                    choiceAttr={chooseAttr}
                    value={entity}
                    parentId={parentId}
                    remoteChoices={(inputValue => autocompleteObject(inputValue,parentId))}
                    className="edit-inline-field"
                    placeholder={selectPlaceholder()}
                    onChange={(e) => handleFormInputChange(e)}
                    isDisabled={disabled}
                    style={{position: "static", zIndex: "999999"}}
                />
            </Fragment>
            }
            <button className="close-edition" onClick={event => handleClose(event)}>
                <i className="bx bx-check"/>
            </button>
            <button className="close-edition" onClick={event => handleCloseWithoutSave(event)}>
                <i className="bx bx-x"/>
            </button>
        </div>
    );
};
export default withTranslation()(CellEditor);
