import React, {Fragment, useEffect, useState} from "react"
import {
    Row,
    Col,
    Form,
    Card,
    CardBody,
    CardSubtitle,
    Label,
    Input} from "reactstrap"
//Import Attachment Cards
import {Link} from "react-router-dom";
import LoadingContainer from "../Utility/Helpers/LoadingContainer";
import {DIALOG_TYPE, HTTP_RESPONSE} from "../Utility/Helpers/constants";
import DialogBoxComponent from "../Utility/Helpers/DialogBoxComponent";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {useFormik} from "formik";
import * as Yup from "yup";
import { withTranslation } from "react-i18next";
import Carousel from 'react-bootstrap/Carousel';

import AutocompleteSelectType from "../../helpers/Form/AutocompleteSelectType";
import SelectType from "../../helpers/Form/SelectType";
import DatePickerType from "../../helpers/Form/DatePickerType";
import TextareaType from "../../helpers/Form/TextareaType";

import Autocomplete from "../../helpers/select.autocomplete";

import ProductRepository from "./Repository/product-repository";
import ProjectRepository from "../Projects/Repository/project-repository"
import UserProvier from "../../security/UserProvier";
import AuthorizationChecker from "../../security/AuthorizationChecker";
import moment from "moment-timezone";
import LightBoxComponent from "../Utility/Helpers/LightBoxComponent";

/**
 *
 * Modal utilisée dans toute l'application
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const PagesEditProducts= (props) => {
  //set timzone to UTC
  moment.tz.setDefault("UTC")
  const {productId = 0, projectId=0, onEditSuccess = {}} = props;
  const [loading, setLoading] = useState(true);
  const [loadingProject, setLoadingProject] = useState(true)
  const [fields, setFields] = useState([])

  const [addedPics, setAddedPics] = useState([])
  const [productPics, setProductPics] = useState([])
  const [productPicsUrls, setProductPicsUrls] = useState([])
  const [project, setProject] = useState(null)

  const [shippingDelay, setShippingDelay] = useState(0)

  const [showSettingPage, setShowSettingPage] = useState(false)

  const [nbColumn, setNbColumn] = useState(6)
  
  const [companyId, setCompanyId] = useState(UserProvier.get().companyId)

  const nbToList = ()=>{
    let list = []
    for (let index = 1; index < nbColumn; index++) {
      list.push(index)
    }
    return list
  }

  const [showError, setShowError] = useState(false)
  const [errors, setErrors] = useState([])

  const displayErrorBox = (message)=>{
    setErrors(message.split(";"))
    setShowError(true)
    setLoading(false)
    document.documentElement.scrollTop = 0
  }

  const fieldWithErrorClicked = (error)=>{
    let field = error.split(":")[0].trim()
   
    let fieldDOM = document.getElementById(field+"_box")
    if(fieldDOM){
      fieldDOM.scrollIntoView({block:"center", inline:"center"})
      fieldDOM.classList.add("field-contains-error")
      setTimeout(()=>{
        fieldDOM.classList.remove("field-contains-error")
      },3000)
    }
  }
  const [dialog, setDialog] = useState({
    show: false,
    handleConfirm: () => {},
    handleClose: () => {},
    message: "",
    type: DIALOG_TYPE.ALERT
  });

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {},
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      setLoading(true)
      let newItem = {...values, projectId: projectId}
      
      fields.filter(field=> field.type=="object").forEach(field=>{
        if (newItem[field.columnId]) {
            newItem[field.columnId] = newItem[field.columnId].id
        }
      })
      fields.filter(field=> field.type=="date").forEach(field=>{
        console.log("before",newItem[field.columnId])
        //show the timezone in the date
        console.log("moment",moment(newItem[field.columnId]).format())
        if (newItem[field.columnId]) {
          newItem[field.columnId] = moment(newItem[field.columnId]).format("YYYY-MM-DD")
        }
        console.log("after",newItem[field.columnId])
      })
      
      ProductRepository.flush({...newItem, delais:shippingDelay }).then(async (response) => {
        if(addedPics.length){
          const formData = new FormData()
          addedPics.forEach((pic)=>{
            let nameToUse = pic.name
            formData.append(
              nameToUse,
              new File([pic], nameToUse)
            )
          })
          ProductRepository.savePics(formData, productId ? productId : response.id).then((res)=>{

            if(res && res.error){
              catchError({
                status: HTTP_RESPONSE.HTTP_BAD_REQUEST,
                data: {
                  error : {
                    message: res.error.code ? props.t(res.error.code) : props.t(res.error),
                    code: res.error.code ? props.t(res.error.code) : props.t(res.error) ,
                    title : props.t("ERROR_SAVING_PICS")
                  }
                }
              })
            }else{
              onAllDoneSuccessfully(response)
            }
          }).catch((resp)=>{
            catchError(resp)
          })
        }else{
          onAllDoneSuccessfully(response)
        }

       
      }).catch((response) => {
        catchError(response)
      })
  
    }
  });

  const init = async ()=>{
    if (productId) {
      setLoading(true);
      ProductRepository.findIncludeAll(productId, projectId ).then((response) => {

        ProductRepository.getPics(productId).then((response)=>{
          console.log("response",response)
          //loop through the response and add the link to the productPicsUrls array process.env.REACT_APP_API_BASE_URL+pic.link
          let tmpUrls = [];
          response.forEach((pic)=>{
            //add the link to the productPicsUrls array
            //add the pic to the productPics array
            tmpUrls.push(
              {
                link : process.env.REACT_APP_API_BASE_URL+pic.link,
                name : pic.name
              }
            )
          })
          setProductPics(response)
          setProductPicsUrls(tmpUrls)
        })

        setFields(response.fields)

        if(response.totalCount==0){
          let initialValues = {}
  
          response.fields.forEach((field)=>{
            initialValues[field.columnId] = field.type=="number" ? 0 :
                                            field.type=="object" ? null :
                                            field.type=="checkbox" ? false :
                                            ""
          })
    
          delete initialValues["vendorNo"]
          validation.setValues({...validation.values, ...initialValues})
        }else{
          let item = response.items[0]

          response.fields.filter(field=> field.type=="object").forEach(field=>{
            let column = field.columnId.replace("Id","")
            if (item[field.columnId]) {
                item[field.columnId] = item[column]
            }
            delete item[column]
          })
          let currentTZ = new Date().getTimezoneOffset()
          if(item.dateLivraisonPossible && item.dateLivraisonPossible.includes("T")){
            item.dateLivraisonPossible = moment(item.dateLivraisonPossible).add(currentTZ, 'minutes').format()
          }
          if(item.availableToShipToRen && item.availableToShipToRen.includes("T")){
            item.availableToShipToRen = moment(item.availableToShipToRen).add(currentTZ, 'minutes').format()
          }

          delete item["vendorNo"]

          validation.setValues( {...validation.values, ...item})
        }

        setLoading(false);
      }).catch((error)=>{
        catchError(error);
      })
    } else {
      ProductRepository.findFields().then((response)=>{
        setFields(response.fields)
  
        let initialValues = {}
  
        response.fields.forEach((field)=>{
          initialValues[field.columnId] = field.type=="number" ? 0 :
                                          field.type=="object" ? null :
                                          field.type=="checkbox" ? false :
                                          ""
        })
  
        delete initialValues["niveau1Id"]
        delete initialValues["vendorNo"]
        
        let defaultSeason = null

        Autocomplete.productReferencesValues("YEAR_ROUND", "seasonCodeId").then((data)=>{
          if (data.length){
            defaultSeason = data[0]
          }
        }).catch((err)=>{
          console.log("error on loading default season : ", err)
        }).finally(()=>{        
          validation.setValues({...validation.values, ...initialValues, seasonCodeId : defaultSeason })
          setLoading(false);
        })
      })
    }
  }

  useEffect(async () => {    
    let tmp = await ProjectRepository.find(projectId).then( (item) =>{
      // Get and save shipping delay
      ProjectRepository.getSupplierAccount(item.compteSupplierId).then(account => {setShippingDelay(account.shipping_delay)})
      setProject(item)

      let values = validation.values
      values["niveau1Id"] = item.niveau1
      values["vendorNo"] = item.compteSupplierId
      validation.setValues({...values})

      setLoadingProject(false)
    })

    await init()
  }, []);

  const onAllDoneSuccessfully = (response)=>{
    setLoading(false);
    setErrors([])
    setShowError(false)
    let message = productId ? props.t("PRODUCT_UPDATED_SUCCESSFULLY") : props.t("NEW_PRODUCT_CREATED_SUCCESSFULLY")
    setDialog({
      show: true,
      handleConfirm: () => { (typeof onEditSuccess == 'function') ? onEditSuccess(response) :  closeDialogModal()},
      handleClose: () => { (typeof onEditSuccess == 'function') ? onEditSuccess(response) :  closeDialogModal()},
      type: DIALOG_TYPE.SUCCESS,
      message: message,
      title: props.t("OPERATION_SUCCESSED"),
      textToCopy: message
    });
  }
  /**
   * Gestion de la soumission du formulaire
   * @param event
   * @returns {Promise<void>}
   */
  const handleSubmitForm = (event) => {
    event.preventDefault();
    validation.handleSubmit();
  };

  const catchError = (response) => {
    let currentDialog = {
      show: true,
      handleConfirm: () => closeDialogModal(),
      handleClose: () => closeDialogModal(),
      type: DIALOG_TYPE.ALERT,
      message: "",
      title: ""
    };

    switch (response.status) {
      case HTTP_RESPONSE.HTTP_BAD_REQUEST: {
        currentDialog.type = DIALOG_TYPE.ALERT;

        if(response.data.message){
          currentDialog.message= response.data.message
        }else if(response.data.error.message){
          currentDialog.message= response.data.error.message
        }

        if(response.data.error.title){
          currentDialog.title = response.data.error.title
        }
        break;
      }
      case HTTP_RESPONSE.HTTP_NOT_FOUND: {
        currentDialog.type = DIALOG_TYPE.ALERT;
        
        if(response.data.message){
          currentDialog.message= response.data.message
        }else if(response.data.error.message){
          currentDialog.message= response.data.error.message
        }

        if(response.data.error.title){
          currentDialog.title = response.data.error.title
        }
        break;
      }
      case HTTP_RESPONSE.HTTP_INTERNAL_SERVER_ERROR: {
        currentDialog.type = DIALOG_TYPE.SERVER_ERROR;
        currentDialog.message = '';
        if (response.data.message) {
          currentDialog.message = response.data.message;
        }
        break;
      }
      case HTTP_RESPONSE.HTTP_NOT_ACCEPTABLE : {
        displayErrorBox(response.data.error.message)
        return
      }
      case HTTP_RESPONSE.HTTP_UNAUTHORIZED: {
        currentDialog.type = DIALOG_TYPE.UNAUTHORIZED;
        currentDialog.message = response.data.error.message
        break;
      }
      case HTTP_RESPONSE.HTTP_UNPROCESSABLE_ENTITY: {
        currentDialog.type = DIALOG_TYPE.SERVER_ERROR;
        if(response.data){
          if( response.data.error.code=="ER_DUP_ENTRY"){
              currentDialog.message = props.t("PRODUCT_ALREADY_EXIST");
          }else{
              currentDialog.message = props.t("CHECK_FORM_FEILDS");
          }
        }else{
            currentDialog.message = response.message
        }
        break;
      }
      default:
    }
    
    setDialog(currentDialog);
  };

  /**
     *  Methode appelée au click sur le bouton paramètre
     * @param e
  */
  const toggleSettingPage = (e) => {
    if (e !== undefined && typeof e.preventDefault === "function") {
        e.preventDefault();
    }
    setShowSettingPage(!showSettingPage)
  };

  const closeDialogModal = () => {
    setDialog({...dialog, show: false})
    setLoading(false)
  };

  const buildAutoCompteSelect = (field) =>{
    // S'il s'agit d'un des champs niveau1Id, niveau2Id, ... , niveau6Id,
    if(field.columnId.includes("niveau")){
      let parent = parseInt( field.columnId.replace("niveau","").replace("Id","") ) - 1
      return(
        <AutocompleteSelectType
          id={field.columnId}
          name={field.columnId}
          className={"input-form"}
          key={parent ? ( "niveau"+parent + (validation.values["niveau"+parent+"Id"] ? validation.values["niveau"+parent+"Id"].id : "null") ): "niveau1Id"}
          isDisabled={ (parent!=0 && validation.values["niveau"+parent+"Id"]==null)||field.isDisabled}
          remoteChoices={(inputValue) => Autocomplete.productNiveaux(
            parent+1, 
            (parent && validation.values["niveau"+parent+"Id"])  ? validation.values["niveau"+parent+"Id"].id : 0,
            inputValue)
          }
          choiceAttr={field.chooseAttr}
          value={validation.values[field.columnId] || null}
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          placeholder={props.t("CHOICE") + "..." }
        />
      )
    } // Hiérachies sur Ren's Pet
    else if(field.columnId=="divisionCodeId"){
      return(
        <AutocompleteSelectType
          id={field.columnId}
          name={field.columnId}
          className={"input-form"}
          remoteChoices={(inputValue) => Autocomplete.productReferencesValues(inputValue, field.columnId)}
          choiceAttr={field.chooseAttr}
          value={validation.values[field.columnId] || null}
          onChange={(e)=>{validation.setValues({...validation.values, itemCategoryCodeId : null, productGroupCodeId: null}) ; validation.handleChange(e)}}
          onBlur={validation.handleBlur}
          placeholder={props.t("CHOICE") + "..." }
          disabled={field.isDisabled}
        />
      )
    }else if(field.columnId=="itemCategoryCodeId"){
      return(
        <AutocompleteSelectType
          id={field.columnId}
          name={field.columnId}
          className={"input-form"}
          key={ ( validation.values["divisionCodeId"] ? validation.values["divisionCodeId"].id : "null-" ) + "itemCategoryCodeId" }
          isDisabled={field.isDisabled}
          remoteChoices={(inputValue) => Autocomplete.referencesValuesWithHierachie(
            "itemCategoryCodeId",
            validation.values["divisionCodeId"] ? validation.values["divisionCodeId"].id : 0,
            inputValue
          )}
          choiceAttr={field.chooseAttr}
          value={validation.values[field.columnId] || null}
          onChange={(e)=>{; validation.setValues({...validation.values, productGroupCodeId: null}) ; validation.handleChange(e) }}
          onBlur={validation.handleBlur}
          placeholder={props.t("CHOICE") + "..." }
        />
      )
    }
    else if(field.columnId=="productGroupCodeId"){
      return(
        <AutocompleteSelectType
          id={field.columnId}
          name={field.columnId}
          className={"input-form"}
          key={ ( validation.values["itemCategoryCodeId"] ? validation.values["itemCategoryCodeId"].id : "null-" ) + "productGroupCodeId" }
          isDisabled={ validation.values["itemCategoryCodeId"]==null || field.isDisabled}
          remoteChoices={(inputValue) => Autocomplete.referencesValuesWithHierachie(
            "productGroupCodeId",
            validation.values["itemCategoryCodeId"] ? validation.values["itemCategoryCodeId"].id : 0,
            inputValue
          )}
          choiceAttr={field.chooseAttr}
          value={validation.values[field.columnId] || null}
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          placeholder={props.t("CHOICE") + "..." }
        />
      )
    }
    else{
      return(
        <AutocompleteSelectType
          id={field.columnId}
          name={field.columnId}
          className={"input-form"}
          remoteChoices={(inputValue) => Autocomplete.productReferencesValues(inputValue, field.columnId)}
          choiceAttr={field.chooseAttr}
          value={validation.values[field.columnId] || null}
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          placeholder={props.t("CHOICE") + "..." }
          disabled={field.isDisabled}
        />
      )
    }
  }

  const buildColumn = (field)=>{
    return (
      <div className="mb-3" id={field.columnId+"_box"}>
        <Label className="form-label col-12">{props.t(field.name)}</Label>    
        {
          field.type=="object" ? 
            buildAutoCompteSelect(field)
          :
          field.type=="number" ? (
              <Input
                id={field.columnId}
                name={field.columnId}
                multiple={false}
                type={field.type}
                min={0}
                step={"any"}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values[field.columnId] == null ? '' :  validation.values[field.columnId]}
                placeholder={props.t("INPUT") + "..." }
                disabled={field.isDisabled}
              />
          ):
          field.type=="date" ? (
            <DatePickerType
              id={field.columnId}
              name={field.columnId}
              className="form-control"
              placeholder= {props.t("ENTER_DATE")}
              type="date"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values[field.columnId] == null ? '' :  validation.values[field.columnId]}
              disabled={field.isDisabled}
            />
          ):
          field.type=="textarea" ? (
            <TextareaType
              id={field.columnId}
              name={field.columnId}
              className="form-control"
              placeholder={props.t("INPUT") + "..." }
              onChange={validation.handleChange}
              value={validation.values[field.columnId] == null ? '' :  validation.values[field.columnId]}
              disabled={field.isDisabled}
            />
          ):
          field.type=="checkbox" ? (
            <div className="form-check form-switch form-switch-lg">
              <Input
                id={field.columnId}
                name={field.columnId}
                type={field.type}
                className="form-check-input"
                onClick={()=>{
                  let tmp = JSON.parse(JSON.stringify(validation.values))
                  tmp[field.columnId] = !tmp[field.columnId]
                  validation.setValues({...tmp})}
                }
                checked={validation.values[field.columnId]? true : false}
                placeholder={props.t("INPUT") + "..." }
                onChange={()=>{}}
                disabled={field.isDisabled}
              />
                <label className="form-check-label" htmlFor="customSwitchsizelg">
                {validation.values[field.columnId] ? props.t("YES") : props.t("NO")}
              </label>
            </div>
          ):(
              <Input
                id={field.columnId}
                name={field.columnId}
                multiple={false}
                type={field.type}
                min={0}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values[field.columnId] || ""}
                placeholder={props.t("INPUT") + "..." }
                disabled={field.isDisabled}
              />
          )
        }
      </div>
    )
  }

  const productPicsAdded = (e)=>{
    const pics = e.target.files
    console.log("pics",pics)
    //check iffile is an image and if it is not already added
   
      
    const allowedFormats = ["jpeg", "jpg", "png", "bmp", "tiff", "gif"];
    //we need to loop trought the files and check if they are images in the allowedFormats, if they are not we need to remove them from the list
    //we also need to check if the file is already added
    //if the file is already added we need to remove it from the list
    //we save into array the rejected files and after the loop we display into the dialog box
    let rejectedFiles = []
    let addedFiles = []
    for (let i = 0; i < pics.length; i++) {
      const pic = pics[i]
      let picFormat = pic.name.split(".").pop().toLowerCase()
      if(allowedFormats.includes(picFormat)){
        if(addedPics.some((addedPic)=>{
          return addedPic.name == pic.name
        })){
          rejectedFiles.push(pic)
        }else{
          addedFiles.push(pic)
        }
      }else{
        rejectedFiles.push(pic)
      }
    }
    setAddedPics([...addedPics, ...addedFiles])
    if(rejectedFiles.length){
      let message = props.t("THESE_FILES_ARE_NOT_ALLOWED_IMAGES") + " : " + rejectedFiles.map((pic)=> pic.name).join(", ")
      setDialog({
        show: true,
        handleConfirm: () => {closeDialogModal()},
        handleClose: () => {closeDialogModal()},
        type: DIALOG_TYPE.ALERT,
        message: message,
        title: props.t("ERROR")
      });
    }

  }

  const buildProductPicPreview = ()=>{
    let urlObjects = addedPics.map((pic)=>{
      return URL.createObjectURL(pic)
    })

    return (
      urlObjects.map((pic,i)=>{
        return (
          <div key={i}>
            <img
              className="d-block"
              //height={"350px"}
             
              src={pic}
              alt={'Added Pic ' + i + ' preview'}
              onLoad={
                ()=>{
                  URL.revokeObjectURL(pic)
                }
              }
            />
            <button type="button" className="btn btn-danger w-100 remove-product-pic-btn" onClick={()=>{
              setAddedPics([...addedPics.slice(0,i), ...addedPics.slice(i+1)])
            }}>
              <i className='bx bx-no-entry' ></i>
              {props.t("REMOVE_THIS_PIC")}
            </button>
          </div>
        )
      })
    )
  }

  const delProductPic = (name)=>{
    setLoading(true)
    ProductRepository.delPic(productId, name).then(()=>{
      setProductPics(productPics.filter(p=>{
        return p.name!=name
      }))
      setProductPicsUrls(productPicsUrls.filter(p=>{
        return p.name!=name
      }
      ))
      
    }).catch(response=>{
      catchError(response)
    }).finally(()=>{
      setLoading(false)
    })
  }

  return (
    <Fragment>
      <LoadingContainer className="mb-4" loading={loading} style={{marginTop:"100px"}}>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>

                <div className="row mb-4">
                  <Breadcrumbs title={props.t("PRODUCT")} breadcrumbItem={props.t("PRODUCT") + " - " + ( productId ? props.t("MODIFICATION") : props.t("ADD"))}/>
                  <div>
                    <Link className="btn btn-info" to={"/pages-projects/"+projectId}>{props.t("RETURN_TO_LIST")}</Link>
                  </div>
                </div>

                <CardSubtitle className="mb-3">
                {props.t("UPDATE_PRODUCT_DESCRIPTION")}
                </CardSubtitle>
                
                <div className="row">
                  <div className="col-3 d-flex gap-1 justify-content-start align-items-center">
                    <Label className="m-0">{props.t("NB_COLUMNS_TO_DISPLAY")}</Label>
                    <SelectType
                      choices={
                        [
                          { value: 2, text: 2},
                          { value: 3, text: 3},
                          { value: 4, text: 4},
                          { value: 6, text: 6}
                        ]
                      }
                      value={nbColumn}
                      onChange={(e)=>{
                        setNbColumn(e.target.value)
                      }}/>
                  </div>
                </div>
                {/*
                <a
                    onClick={(event => toggleSettingPage(event))}
                    style={{marginLeft: "20px", padding: "4px"}}
                    className="btn btn-secondary" href="#">
                    <i className="bx bx-cog" style={{fontSize: "25px"}}/>
                </a>
              */}
                <hr />
                {
                  showError && (
                    <div className="row error-box">
                      <div className="error-box-title">
                        <p> <strong> {props.t("ERRORS_OCCURED")}</strong></p>
                        <button type="button" className="btn-close" aria-label="Close" onClick={()=>{setShowError(false)}}></button>
                      </div>
                      <ul>
                          {errors.map((error,i)=>{
                              return (
                                  <li key={i}> 
                                      <button type="button" className="btn" onClick={()=>{fieldWithErrorClicked(error)}}>{error.split(":")[0].trim()}</button> 
                                      <code>{ " : " + error.split(":")[1]}</code>
                                  </li>
                              )
                          })}
                      </ul>
                    </div>
                  )
                }
                <Form onSubmit={(e) => handleSubmitForm(e)}>
                  <div className="row">
                    <div className={"col-"+(12/nbColumn).toFixed(0)}>
                      <div className="mb-3">
                        <Label className="form-label">{props.t("ASSOCIATED_PROJECT")}</Label>
                          {
                            loadingProject  ?
                            ( <p>Loading ...</p> ) :
                              <AutocompleteSelectType
                                id={"projectId"}
                                name={"projectId"}
                                isDisabled={true}
                                choiceAttr={"name"}
                                value={project}
                              />
                          }
                      </div>

                      {
                        fields.length > 0 && fields.slice(0, fields.length/nbColumn).map((field)=> <Fragment key={field.id}>
                            {buildColumn(field)}
                          </Fragment>
                        )
                      }
                    </div>

                    {
                      fields.length > 0 && nbToList().map((index)=> <div className={"col-"+(12/nbColumn).toFixed(0)} key={index}>
                          {
                            fields.length > 0 && fields.slice(index*fields.length/nbColumn, (index +1)*fields.length/nbColumn).map((field)=> <Fragment key={field.id}>
                              {buildColumn(field)}
                              </Fragment>
                            )
                          }
                        </div>
                      )
                    }
                  </div>

                    {
                    AuthorizationChecker.isGranted(["VENDOR_CREATE_PROJECT"]) &&
                  <div className="row mb-2">
                    <strong>{props.t("MANAGE_PRODUCT_PICS")}</strong>
                    <div className="d-flex mt-2">
                      <label htmlFor="upload-product-pic" className="upload-product-pic-label"> 
                          <i className='bx bxs-image-add'></i>
                          {props.t("ADD_PRODUCT_PIC")}
                      </label>
                      <input id="upload-product-pic" type="file" hidden={true} onChange={productPicsAdded} accept="image/*" multiple={true}/>
                    </div>
                  

                  </div>}
                  <div className="d-flex" style={{overflow:"scroll"}}>
                      {buildProductPicPreview()}
                      <LightBoxComponent images={productPicsUrls}
                      showDelBtn ={true}
                      HandleDelBtn ={delProductPic} />
                  </div>               
                   

                  <div className="mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary">
                      {props.t("SAVE_MODIFICATIONS")}
                    </button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LoadingContainer>
      <DialogBoxComponent {...dialog} />
    </Fragment>
  )
};
export default withTranslation()(PagesEditProducts)