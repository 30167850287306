import React from "react";
import {Link, useParams} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import {CompanyViewComponent} from "ppm-react-authentication";
import {COMPANY_API_URL,USERS_COMPANY_API_URL} from "../../helpers/api-urls";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import AuthorizationChecker from "security/AuthorizationChecker";
import { withTranslation } from "react-i18next";
const PagesDetailCompanies = (props) =>{
    const params = useParams();
    const {id = 0} = params;

    return ( 
        <>
            <div className="page-content">
                <MetaTags>
                    <title>{props.t("COMPANY")}</title>
                </MetaTags>
                
                <div className="mb-4">
                    <Breadcrumbs title={props.t("COMPANY")} breadcrumbItem={props.t("CIE_DETAIL")}/>
                    <Link className="btn btn-info" to={"/pages-companies"}>{props.t("RETURN_TO_LIST")}</Link>

                    { 
                        AuthorizationChecker.isGranted(["EDIT_COMPANIES"]) && 
                        (
                            <Link className="btn btn-info ms-3" to={"/pages-companies/"+id+"/edit"}>Modifier</Link>
                        )
                    }
                    { 
                        AuthorizationChecker.isGranted(["ADD_COMPANIES"]) && 
                        (
                            <Link className="btn btn-info ms-3" to={"/pages-companies/add"}>Ajouter</Link>
                        )
                    }
                </div>
                <CompanyViewComponent 
                    id={id}
                    apiUrl={COMPANY_API_URL}
                    apiUserCompagnyUrl={USERS_COMPANY_API_URL}
                    userPath="/pages-users"
                    Link={Link}
                    showEditAssociatedUser = {AuthorizationChecker.isGranted(["EDIT_USERS"])}
                    showViewAssociatedUser = {AuthorizationChecker.isGranted(["READ_USERS"])}
                    showDeleteAssociatedUser = {AuthorizationChecker.isGranted(["DELETE_USERS"])}
                />
            </div>
        </>
    );
}

export default withTranslation()(PagesDetailCompanies)
