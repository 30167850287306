import * as React from "react";
import {Fragment} from "react";
import Select from 'react-select';

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const SelectType = (props) => {
    const {id, name, choices = [], onChange, placeholder = '', error = '', className = '', value = '', showErrorText = true} = props;
    const options = choices.map((item) => {
        return {value: item.value, label: item.text};
    });
    const oldValue = options.find((item) => item.value == value);
    const defaultValue = (oldValue) ? oldValue : placeholder;
    return (
            <Select
                options={options}
                defaultValue={defaultValue}
                placeholder={placeholder}
                className={`${(error.length>0 ? "is-invalid" : "")} ${className}`}
                onChange={(e) => {
                    const target = {
                        'name': name,
                        'value': e.value || 0
                    };
                    onChange({target: target, e: e});
                }}
            />
    );
};
export default SelectType;



