/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState,useMemo } from "react"
import PropTypes from 'prop-types';
import {
    Container,
} from "reactstrap"
import MetaTags from 'react-meta-tags';
import LoadingContainer from "../Utility/Helpers/LoadingContainer";
import TableContainer from "../Utility/Helpers/TableContainer";
import {DIALOG_TYPE, HTTP_RESPONSE} from "../Utility/Helpers/constants";
import DialogBoxComponent from "../Utility/Helpers/DialogBoxComponent";
import "../Utility/Helpers/style.css";
import SupplierRepository from "./Repository/supplier-repository";
import ActionsFormatterSupplierList from "pages/Utility/Helpers/ActionsFormatterSupplierList";
import { withTranslation } from "react-i18next";
import UserProvier from "security/UserProvier";
//import AutocompleteSelectType from "../form/core/type/AutocompleteSelectType";

const SuppliersList = (props) => {
    const BtnActions = (props) => {

        
        return (
            <ActionsFormatterSupplierList
                user={user}
                supplier={props.row.original}
                onClick={onDelete}
                enableEditSupplier={(props.row.original.project_count > 0 || props.row.original.username != null) ? false : true}
            />
        );
    }

    const [data, setData] = useState([]);
    const user = UserProvier.get();
    const columns = useMemo(() => [
        {
            Header: props.t("_ID_"),
            accessor: "id",
            disableFilters: true

        },
        {
            Header: props.t("_CODE_"),
            accessor: "CODE"
        },
        {
            Header: props.t("_NAME_"),
            accessor: "nom"
        },
        {
            Header: props.t("_SHIPPING_DELAY_"),
            accessor: "shipping_delay"
        },
        {
            Header: props.t("_USERNAME_"),
            accessor: "username"
        },
        {
            Header: props.t("_COMPANY_NAME_"),
            accessor: "companyName"
        },
        {
            Header: props.t("_PROJECT_COUNT_"),
            accessor: "project_count"
        },
        {
            Header: props.t("_ACTIONS_"),
            accessor: "actions",
            Cell: BtnActions,
            disableFilters: true
        }
        
    ], []);


    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(15);
    const [search, setSearch] = useState('');
    const [fkCieId, setFkCieId] = useState(UserProvier.get().companyId);
    const [selected, setSelected] = useState([]);

    const [showDialog, setShowDialog] = useState(false);
    const [dialogType, setDialogType] = useState(DIALOG_TYPE.INFO);
    const [dialogMessage, setDialogMessage] = useState('');
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialog, setDialog] = useState({
        show: false,
        onConfirm: () => {
        },
        handleClose: () => {
        },
        message: "",
        type: DIALOG_TYPE.ALERT,
        title: ""
    });

    const closeDialogModal = () => {
        setDialog({...dialog, show: false});
        setLoading(false);
    };

    const catchError = (response) => {
        const currentDialog = {
            show: true,
            onConfirm: () => closeDialogModal(),
            handleClose: () => closeDialogModal(),
            type: DIALOG_TYPE.ALERT
        };
        let status = HTTP_RESPONSE.HTTP_NOT_FOUND;
        if (response.status != undefined) {
            status = response.status;
        } else if (response.error != undefined && response.error.statusCode != undefined) {
            status = response.error.statusCode;
        }
        switch (status) {
            case HTTP_RESPONSE.HTTP_BAD_REQUEST: {
                currentDialog.type = DIALOG_TYPE.ALERT;
                break;
            }
            case HTTP_RESPONSE.HTTP_NOT_FOUND: {
                currentDialog.type = DIALOG_TYPE.ALERT;
                break;
            }
            case HTTP_RESPONSE.HTTP_INTERNAL_SERVER_ERROR: {
                currentDialog.type = DIALOG_TYPE.SERVER_ERROR;
                currentDialog.message = '';
                if (response.data.message) {
                    currentDialog.message = response.data.message;
                }
                break;
            }
            case HTTP_RESPONSE.HTTP_UNAUTHORIZED: {
                dialog.type = DIALOG_TYPE.UNAUTHORIZED;
                dialog.message = response.data.error.message
                break;
            }
            case HTTP_RESPONSE.HTTP_UNPROCESSABLE_ENTITY: {
                currentDialog.type = DIALOG_TYPE.SERVER_ERROR;
                if( response.data.error.code=="ER_DUP_ENTRY"){
                    currentDialog.message = props.t("PROJECT_ALREADY_EXIST");
                }else{
                    currentDialog.message = props.t("CHECK_FORM_FEILDS");
                }
                break;
            }
            default:
        }
        setDialog(currentDialog);
    };


    useEffect(() => {

    const fetchData = async () => {
        try {
            const response = await SupplierRepository.getAll(fkCieId);
            console.log(response);
            setData(response.suppliers);
            setTotal(response.total);
            setLoading(false);
        } catch (e) {
            if (e.status === HTTP_RESPONSE.HTTP_NOT_FOUND) {
                setError("No data found");
            } else {
                setError("An error occurred while fetching data");
            }
            setLoading(false);
        }
    }
    fetchData();
    }
    ,[]);

    const showSucessDialog = (message) => {
        const currentDialog = {
            show: true,
            onConfirm: () => closeDialogModal(),
            handleClose: () => closeDialogModal(),
            type: DIALOG_TYPE.SUCCESS,
            title: 'Success',
            message: message
        };
        setDialog(currentDialog);
    };
    const onDelete = async (e,supplier) => {
        try {
            e.preventDefault();
            console.log(e);
            console.log("SUPPLIER: ", supplier)
            const id = supplier.id;
           
           const currentDialog = {
                show: true,
                title: 'Delete Supplier',
                onConfirm: async () => {
                    closeDialogModal();
                    setLoading(true);
                    await SupplierRepository.delete(id).then(() => {
                            setData((prev) => prev.filter((i) => i.id !== id));
                            setTimeout(() => {
                            setLoading(false);
                        }, 1000);
                        showSucessDialog(props.t("DELETE_SUPPLIER_SUCCESS"));
                        
                    }).
                    catch((e) => catchError(e));
                    
                },
                handleClose: () => closeDialogModal(),
                type: DIALOG_TYPE.CONFIRM,
                message: props.t("DELETE_SUPPLIER_CONFIRMATION") + ' ' + supplier.nom + '?'
            };
            setDialog(currentDialog);
           
         
        } catch (e) {
           const currentDialog = {
                show: true,
                onConfirm: () => closeDialogModal(),
                handleClose: () => closeDialogModal(),
                type: DIALOG_TYPE.ALERT,
                title: 'Error',
                message: props.t("DELETE_SUPPLIER_ERROR")
            };
            setDialog(currentDialog);

        }
    }

    
    
    return (
        <React.Fragment>
          <div className="page-content">
            <MetaTags>
              <title></title>
            </MetaTags>
            <Container fluid={true}>
                <LoadingContainer loading={loading} error={error}>
                    <TableContainer
                            columns={columns}
                            data={data}
                            isGlobalFilter={true}
                            isAddOptions={false}
                            iscustomPageSizeOptions={true}
                            customPageSize={50}
                            isPagination={true}
                            tableClass="align-middle table-nowrap table-check table"
                            theadClass="table-light"
                            paginationDiv="col-12"
                            pagination="justify-content-center pagination pagination-rounded"
                    />
                </LoadingContainer>
            </Container>
            </div>

            <DialogBoxComponent
                    handleClose={() => closeDialogModal()}
                    handleConfirm={() => dialog.onConfirm()}
                    title={dialog.title}
                    show={dialog.show}
                    type={dialog.type}
                    message={dialog.message}
                />
            </React.Fragment>
    
                )

}    


SuppliersList.propTypes = {
    t: PropTypes.func
};
export default withTranslation()(SuppliersList);