import React, {useEffect, useState} from "react"
import MetaTags from 'react-meta-tags';
import {CompanyListComponent} from "ppm-react-authentication";
import {COMPANY_API_URL, USERS_COMPANY_API_URL} from "../../helpers/api-urls";
import { Link } from "react-router-dom";
import AuthorizationChecker from "security/AuthorizationChecker";
import { withTranslation } from "react-i18next";

const PagesCompanies = (props) => {
    return (
    <>
        <div className="page-content">
            <MetaTags>
                <title>{props.t("COMPANY")}</title>
            </MetaTags>
            <CompanyListComponent 
                Link={Link} 
                apiUserCompagnyUrl={USERS_COMPANY_API_URL} 
                apiUrl={COMPANY_API_URL} 
                addUrl="/pages-companies/add" 
                path="/pages-companies" 
                showAdd={AuthorizationChecker.isGranted(["ADD_COMPANIES"])} 
                showEdit ={AuthorizationChecker.isGranted(["EDIT_COMPANIES"])} 
                showView ={AuthorizationChecker.isGranted(["READ_COMPANIES"])} 
                showDelete ={AuthorizationChecker.isGranted(["DELETE_COMPANIES"])}
            />            
        </div>
    </>
    )
}

export default withTranslation()(PagesCompanies)
