import axios from "axios";
import {HTTP_RESPONSE} from "../../Utility/Helpers/constants";
import UserProvier from "../../../security/UserProvier";
import AuthorizationChecker from "../../../security/AuthorizationChecker";

UserProvier.setToken();

export const DATA_API_URL_VENDOR = process.env.REACT_APP_API_BASE_URL + 'projects-vendor';
export const DATA_API_URL_BUYER = process.env.REACT_APP_API_BASE_URL + 'projects-buyer';
export const DATA_API_URL_DM = process.env.REACT_APP_API_BASE_URL + 'projects-dm';
export const DATA_API_URL_ECOM = process.env.REACT_APP_API_BASE_URL + 'projects-ecom';
export const DATA_API_URL_ADMIN = process.env.REACT_APP_API_BASE_URL + 'projects-admin';
export const DATA_API_URL_SUPPLIER_ACCOUNTS = process.env.REACT_APP_API_BASE_URL + 'user-company-suppliers/get-accounts';
export const DATA_API_URL_SUPPLIER_ACCOUNT = process.env.REACT_APP_API_BASE_URL + 'user-company-suppliers/get-account/';

export const EXPORT_PICS_URL = process.env.REACT_APP_API_BASE_URL + 'product-photos/export-pics/p=';
export const DOWNLOAD_FILE_LINK = process.env.REACT_APP_API_BASE_URL + "files/f="
//for project-comments 
export const DATA_API_URL_PROJECT_COMMENTS = process.env.REACT_APP_API_BASE_URL + 'project-comments';
const queries = (itemPerPage = 15, page = 1, search = '', querying = '') => {
    return getApiUrl() + '?filter[where][or][0][name][like]=%'+search+'%' +
        '&filter=[where][name][options]=i' +
        '&filter[where][or][1][projectId][like]=%'+search+'%' +
        '&filter=[where][projectId][options]=i' +
        "&filter[include][]=compteSupplier"+
        "&filter[include][]=niveau1"+
        '&filter[order]=id DESC'+
        '&filter[limit]=' + itemPerPage + '&filter[skip]=' + (((page - 1) * itemPerPage) > 0 ? ((page - 1) * itemPerPage) : 0);
}
const queriesAll = (search = '', querying = '') => {
    return getApiUrl() + '?filter[where][or][0][name][like]=%'+search+'%' +
        '&filter=[where][name][options]=i' +
        '&filter[where][or][1][projectId][like]=%'+search+'%' +
        '&filter=[where][projectId][options]=i' +
        "&filter[include][]=compteSupplier"+
        "&filter[include][]=niveau1"+
        '&filter[order]=id DESC';
}
const getApiUrl = ()=>{
    return  AuthorizationChecker.isGranted(["VENDOR_READ_PROJECT"]) ? DATA_API_URL_VENDOR :
            AuthorizationChecker.isGranted(["BUYER_READ_PROJECT"]) ? DATA_API_URL_BUYER :
            AuthorizationChecker.isGranted(["MD_READ_PROJECT"]) ? DATA_API_URL_DM :
            AuthorizationChecker.isGranted(["ECOM_READ_PROJECT"]) ? DATA_API_URL_ECOM :
            AuthorizationChecker.isGranted(["ADMIN_READ_PROJECT"]) ? DATA_API_URL_ADMIN :
            ""
}

const ProjectsRepository = {
    getSupplierAccounts: (search = "")=>{
        return axios.get(DATA_API_URL_SUPPLIER_ACCOUNTS + "?s="+search).then(
            response =>{
                return response.data
            }
        ).catch((e) => {
            if (e.response) {
                throw e.response;
            } else {
                throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
            }
        });
    },
    getSupplierAccount: (id)=>{
        return axios.get(DATA_API_URL_SUPPLIER_ACCOUNT + id).then(
            response =>{
                return response.data
            }
        ).catch((e) => {
            if (e.response) {
                throw e.response;
            } else {
                throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
            }
        });
    },

    getUserId : ()=>{
        return UserProvier.get().id
    },
    getUserIdColumnName: ()=>{
        return  AuthorizationChecker.isGranted(["VENDOR_READ_PROJECT"]) ? "vendorId" :
                AuthorizationChecker.isGranted(["BUYER_READ_PROJECT"]) ? "purchaserId" :
                AuthorizationChecker.isGranted(["MD_READ_PROJECT"]) ? "dmId" :
                AuthorizationChecker.isGranted(["ECOM_READ_PROJECT"]) ? "ecommId" :
                AuthorizationChecker.isGranted(["ADMIN_READ_PROJECT"]) ? "" :
                ""
    },
    getStatus: ()=>{
        return axios.get( getApiUrl()+"/status/"+ (localStorage.getItem("I18N_LANGUAGE")? localStorage.getItem("I18N_LANGUAGE") : "en" )).then(
            response =>{
                return response.data
            }
        ).catch((e) => {
            if (e.response) {
                throw e.response;
            } else {
                throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
            }
        });
    },
    add : (item) => {
        return axios
            .post(getApiUrl(), item).then(response => {
                return response.data;
            }).catch((e) => {
                if (e.response) {
                    throw e.response;
                } else {
                    throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
                }
            });
    },
    edit: (item) => {
        return axios
            .put(getApiUrl() + '/' + item.id, item).then(response => {
                return response.data;
            }).catch((e) => {
                if (e.response) {
                    throw e.response;
                } else {
                    throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
                }
            });
    },
    findAll : (page = 1, itemPerpage = 10, search = '') => {
        return axios
            .get(queries(itemPerpage, page, search))
            .then(response => {
                return response.data;
            }).catch((e) => {
                if (e.response) {
                    throw e.response;
                } else {
                    throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
                }
            });
    },
    getList : (search = '') => {
        return axios
            .get(queriesAll(search))
            .then(response => {
                return response.data;
            }).catch((e) => {
                if (e.response) {
                    throw e.response;
                } else {
                    throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
                }
            });
    },
    flush: (item) => {
        /*let user =  UserProvier.get()
        item["companyId"] =user?.companyId

        let userIdColumn =  getUserIdColumnName()
        if(userIdColumn){
            item[userIdColumn] = user?.id
        }*/
        if (item.id) {
            return ProjectsRepository.edit(item)
        } else {
            return ProjectsRepository.add(item);
        }
    },
    find : (id) => {
        return axios
            .get(getApiUrl() + '?filter[where][id]=' + id + "&filter[include][]=niveau1")
            .then(response => {
                return response.data.items.length ? response.data.items[0] : null;
            }).catch((e) => {
                if (e.response) {
                    throw e.response;
                } else {
                    throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
                }
            });
    },
    remove : (id) => {
        return axios.delete(getApiUrl() + '/' + id).then((response) => {
            return id
        }).catch((e) => {
            if (e.response) {
                throw e.response;
            } else {
                throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
            }
        });
    },

    sendToMondo : (ids)=>{
        return axios.post(getApiUrl()+'/send-to-mondou',ids).then((e)=>{
            if(e.error){
                throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
            }
            return e
        }).catch((e) => {
            if (e.response) {
                throw e.response;
            } else {
                throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
            }
        });
    },

    sendToRenPet : (ids)=>{
        return axios.post(getApiUrl()+'/send-to-ren-pet',ids).then((e)=>{
            if(e.error){
                throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
            }
            return e
        }).catch((e) => {
            if (e.response) {
                throw e.response;
            } else {
                throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
            }
        });
    },

    sendToEcom : (ids)=>{
        return axios.post(getApiUrl()+'/send-to-ecom',ids).then((e)=>{
            if(e.error){
                throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
            }
            return e
        }).catch((e) => {
            if (e.response) {
                throw e.response;
            } else {
                throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
            }
        });
    },

    sendToVendor : (ids)=>{
        return axios.post(getApiUrl()+'/send-to-vendor',ids).then((e)=>{
            if(e.error){
                throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
            }
            return e
        }).catch((e) => {
            if (e.response) {
                throw e.response;
            } else {
                throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
            }
        });
    },

    sendToBuyer : (ids)=>{
        return axios.post(getApiUrl()+'/send-to-buyer',ids).then((e)=>{
            if(e.error){
                throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
            }
            return e
        }).catch((e) => {
            if (e.response) {
                throw e.response;
            } else {
                throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
            }
        });
    },

    sendToDM: (ids)=>{
        return axios.post(getApiUrl() + '/send-to-dm',ids).then((e)=>{
            if(e.error){
                throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
            }
            return e
        }).catch((e) => {
            if (e.response) {
                throw e.response;
            } else {
                throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
            }
        });
    },

    confirmSapCreation: (ids)=>{
        return axios.post(getApiUrl() + '/confirm-sap-creation',ids).then((e)=>{
            if(e.error){
                throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
            }
            return e
        }).catch((e) => {
            if (e.response) {
                throw e.response;
            } else {
                throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
            }
        });
    },

    exportForSAP: (ids)=>{
        return axios.post(getApiUrl() + '/export-sap',ids).then((e)=>{
            if(e.error){
                throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
            }
            return e
        }).catch((e) => {
            if (e.response) {
                throw e.response;
            } else {
                throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
            }
        });
    },

    exportToMicrosoft: (ids)=>{
        return axios.post(getApiUrl() + '/export-to-microsoft',ids).then((e)=>{
            if(e.error){
                throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
            }
            return e
        }).catch((e) => {
            if (e.response) {
                throw e.response;
            } else {
                throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
            }
        });
    },

    exportProject: (ids)=>{
        return axios.post(getApiUrl() + '/export-project',ids).then((e)=>{
            if(e.error){
                throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
            }
            return e
        }).catch((e) => {
            if (e.response) {
                throw e.response;
            } else {
                throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
            }
        });
    },

    exportProjectPics: (id, isVendor=false)=>{
        return axios.post(EXPORT_PICS_URL+id+"/as=" + (isVendor ? "vendor" : "m") ).then((e)=>{
            if(e.error){
                throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
            }
            return e
        }).catch((e) => {
            if (e.response) {
                throw e.response;
            } else {
                throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
            }
        });
    },

    oneTimeDownloadLink : (filename)=>{
        return DOWNLOAD_FILE_LINK+filename+"/d"
    },

    backToModification: (ids)=>{
        return axios.post(getApiUrl() + '/back-to-modification',ids).then((e)=>{
            if(e.error){
                throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
            }
            return e
        }).catch((e) => {
            if (e.response) {
                throw e.response;
            } else {
                throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
            }
        });
    },

    exportToEcom: (ids, finishProject=false)=>{
        return axios.post(getApiUrl() + (finishProject ? '/finish' : '/export-to-ecom'),ids).then((e)=>{
            if(e.error){
                throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
            }
            return e
        }).catch((e) => {
            if (e.response) {
                throw e.response;
            } else {
                throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
            }
        });
    },

    assignProject : (projectId)=>{
        return axios.post(getApiUrl()+'/' + projectId + '/assign-to-me/' ,{}).then((e)=>{
            if(e.error){
                throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
            }
            return e
        }).catch((e) => {
            if (e.response) {
                throw e.response;
            } else {
                throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
            }
        });
    }
}
export default ProjectsRepository;
