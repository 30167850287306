/* eslint-disable react/prop-types,react/no-unescaped-entities */
import React, {Fragment} from 'react'
import {DIALOG_TYPE} from "./constants";
import AppModalComponent from "./AppModalComponent";
import { withTranslation } from "react-i18next";

/**
 *
 * Affiche une modale de confirmation d'action.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
 const DialogBoxComponent = (props) => {

const ConfirmContent = ({bulk = false}) => <>
    <div className="icon-box">
        <i className='bx bx-question-mark'></i>
    </div>
    <h4 className="w-100">{props.t("ARE_YOUR_SURE")}</h4>
    {bulk ? <p>{props.t("BULK_DELETE_CONFIRM")}</p> :
        <p>{props.t("DELETE_CONFIRM")}</p>}
</>;

const SessionExpiredContent = () => <>
    <div className="icon-box">
        <i className='bx bxs-shield-minus'></i>
    </div>
    <h4 className="w-100">{props.t("SESSION_EXPIRE")}</h4>
    <p>{props.t("SESSION_EXPIRE_TEXT")}</p>
</>;

const UnAuthorizeContent = ({message = ""}) => {
    let parts = message.split("->")
    return (
        <>
            <div className="icon-box">
                <i className='bx bxs-shield-minus'></i>
            </div>
            <h4 className="w-100">{props.t("UNAUTHORIZED_ACCESS")}</h4>
            {
                parts.length==1 ?
                    <p>
                        {   
                            (message.length > 0) ? message : props.t("UNAUTHORIZED_ACCESS")
                                
                        }
                    </p>
                :
                <>
                    <h5>{parts[0]}</h5>
                    <p>{parts[1]}</p>
                </>
            }
        </>
    )
};

const ServerErrorContent = ({message = ''}) => <>
    <div className="icon-box">
        <i className='bx bxs-error-alt' ></i>
    </div>
    <h4 className="w-100">{props.t("ERROR_OCCURRED")}</h4>
    <p>{props.t("ERROR_OCCURRED_TEXT")}</p>
    {message.length > 0 && <code>{message}</code>}
</>;

const NotAcceptable = ({message = ""}) => {
    const errors = message.split(";")
    return (
        <>
            <div className="icon-box"> <i className='bx bx-confused'></i> </div>

            <h4 className="w-100">{props.t("ERROR_OCCURRED")}</h4>
            <p>{props.t("ERROR_OCCURRED_TEXT")}</p>
            <ul>
                {errors.map((error,i)=>{
                    return (
                        <li key={i}> 
                            <code>{error}</code>
                        </li>
                    )
                })}
            </ul>
            
        </>
    )
}

const AlertContent = ({title = props.t("NOT_FOUND"), message = props.t("NOT_FOUND_TEXT")}) => <>
    <div className="icon-box">
        <i className='bx bx-error-circle' ></i>
    </div>
    <h4 className="w-100">{title}</h4>
    <p>{message}.</p>
</>;

const SuccessContent = ({title="", message = ""}) => <>
    <div className="icon-box">
        <i className="bx bx-happy-beaming" style={{color: '#0070c0'}}/>
    </div>
    <h4 className="w-100">{title}</h4>
    {message.length > 0 &&  <p style={{color: '#0070c0'}}> {message}</p>}
</>;

const ConfirmTextContent = ({message = ""}) => <>
    <div className="icon-box">
        <i className="bx bx-question-mark" style={{color: '#0070c0'}}/>
    </div>
    <h4 className="w-100">{props.t("OPERATION")}</h4>
    {message.length > 0 && <code style={{color: '#0070c0'}}>{message}</code>}
</>;


    const {handleClose = {}, handleConfirm, show, message = "", children, title = '', type,textToCopy} = props;
    let className = ' ';
    let confirmText = '';
    let boxTitle = '';
    let content = '';
    let closeTitle = props.t("CANCEL");
    let showConfirmButton = true;
    //handle message cariage return
    
    switch (type) {
        case DIALOG_TYPE.CONFIRM: {
            className += 'confirm-dialog';
            confirmText = props.t("CONFIRM");
            content = <ConfirmTextContent message={message}/>;
            break;
        }
        case DIALOG_TYPE.CONFIRM_DELETION: {
            className += 'confirm-dialog';
            confirmText = props.t("CONFIRM_DELETE");
            boxTitle = props.t("DELETE_OPERATION");
            content = <ConfirmContent/>;
            break;
        }
        case DIALOG_TYPE.CONFIRM_BULK_DELETION: {
            className += 'confirm-dialog';
            confirmText = props.t("CONFIRM_DELETE");
            boxTitle = props.t("DELETE_OPERATION");
            content = <ConfirmContent bulk={true}/>;
            break;
        }
        case DIALOG_TYPE.ALERT: {
            className += 'alert-dialog';
            showConfirmButton = false;
            closeTitle = props.t("CLOSE_AND_RETURN");
           
            content = <AlertContent message={message} title={title.length ? title : props.t("NOT_FOUND")}/>;
            break;
        }
        case DIALOG_TYPE.SESSION_EXPIRED: {
            className += 'alert-session-expired';
            confirmText = props.t("RECONNECT");
            content = <SessionExpiredContent/>;
            break;
        }
        case DIALOG_TYPE.UNAUTHORIZED: {
            className += 'alert-session-expired';
            confirmText = props.t("CLOSE");
            content = <UnAuthorizeContent message={message}/>;
            break;
        }
        case DIALOG_TYPE.AUTH_REQUIRED: {
            className += 'alert-session-expired';
            confirmText = props.t("RECONNECT");
            content = <UnAuthorizeContent/>;
            break;
        }
        case DIALOG_TYPE.SERVER_ERROR: {
            className += 'alert-session-expired';
            confirmText = props.t("RECONNECT");
            content = <ServerErrorContent message={message}/>;
            showConfirmButton = false;
            closeTitle = props.t("CLOSE");
            break;
        }
        case DIALOG_TYPE.NOT_ACCEPTABLE: {
            className += 'alert-session-expired';
            confirmText = props.t("RECONNECT");
            content = <NotAcceptable message={message}/>;
            showConfirmButton = false;
            closeTitle = props.t("CLOSE");
            break;
        }
        case DIALOG_TYPE.SUCCESS: {
            className += 'success-dialog';
            content = <SuccessContent title={title} message={message}/>;
            showConfirmButton = false;
            closeTitle = props.t("CLOSE");
            break;
        }
        default:
    }

    const closeDialog = () => {
        if (handleClose) {
            handleClose(); //Appel du callback lorsque le dialog est fermé.
        }
    };
    const confirmDialog = (e) => {
        // if (type == DIALOG_TYPE.SESSION_EXPIRED) {
        //     window.location.replace("/login");
        // } else {
        if (handleConfirm) {
            handleConfirm(e); //Appel du callback lorsque le bouton confirm est cliqué
        }
        // }
    };
    return (
        <Fragment>
            <AppModalComponent
                enabledLoading={false}
                handleClose={closeDialog}
                show={show}
                className={'dialog ' + className}
                cssConfirmBtnClass={className}
                saveTitle={confirmText}
                closeTitle={closeTitle}
                enableConfirmBtn={showConfirmButton}
                handleClickSaveButton={(e) => confirmDialog(e)}
                title={boxTitle}
                textToCopy={textToCopy}>
                <div className="row no-gutters pt-4">
                    <div className="col-12">
                        <div className="main-form-card pt-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="content-form-item">
                                        <div className="tab-content">
                                            <div id="leaner-identity" className="tab-pane active">
                                                <div className="mx-auto text-center">
                                                    {content}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AppModalComponent>
        </Fragment>
    )
};

export default withTranslation()(DialogBoxComponent);
