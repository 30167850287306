import React, {Fragment} from 'react'
/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const TextType = (props) => {
    const keyPressed = () => {
    };
    const {id, name, inputRef = null, onChange, error = '', type = 'text', placeholder = '', onKeyPress = keyPressed, onFocus = keyPressed, iconClass = '', useIcon = false, className = '', value = '', disabled = false, text = '', useLabel = false, defaultCss = 'form-control', showErrorText = true} = props;
    return (
        <Fragment>
            {useIcon && (iconClass.length > 0) && <i className={iconClass}/>}
            {useLabel && <label htmlFor={id} className="required">{text}</label>}
            <input type={type}
                   id={id}
                   disabled={disabled}
                   placeholder={placeholder}
                   value={value}
                   name={name}
                   onChange={(e) => onChange(e)}
                   className={(error.length && "is-invalid") + " " + defaultCss + " " + className}
                   onKeyPress={onKeyPress}
                   onFocus={onFocus}
                   autoComplete={"off"}
                   ref={inputRef}
            />
            {(error && showErrorText) && (
                <p className="invalid-feedback d-block">
                    {error}
                </p>)}
        </Fragment>
    );
};
export default TextType;
