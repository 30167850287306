import axios from "axios";
import {HTTP_RESPONSE} from "../../Utility/Helpers/constants";
import UserProvier from "../../../security/UserProvier";
import AuthorizationChecker from "../../../security/AuthorizationChecker";

UserProvier.setToken();

export const DATA_API_URL_FILE_MANAGER = process.env.REACT_APP_API_BASE_URL + "/file-explorer";


const FileExplorerRepository = {

    async upload (formData) {
        return await axios.post(DATA_API_URL_FILE_MANAGER + "/vendordoc", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            return response.data;
        }).catch((e) => {
            if (e.response) {
                throw e.response;
            } else {
                throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
            }
        });
    },

    async delete (fileName) {
        return await axios.delete(DATA_API_URL_FILE_MANAGER + "/VendorFiles/" + encodeURIComponent(fileName)).then(response => {
            return response.data;
        }).catch((e) => {
            if (e.response) {
                throw e.response;
            } else {
                throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
            }
        });
    }
}

export default FileExplorerRepository;