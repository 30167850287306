/* eslint-disable react/prop-types */
import React, {Fragment} from "react";
import {Button, Modal} from "react-bootstrap";
import "./app-modal.css";
import LoadingContainer from "./LoadingContainer";



/**
 *
 * Modal utilisée dans toute l'application
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const AppModalComponent = (props) => {
    const {
        enabledLoading = false,
        textFooter = null,
        size = 'lg',
        useHeader = true,
        useFooter = true,
        centered = true,
        enableConfirmBtn = true,
        enableSecondConfirmBtn = false,
        handleClickSaveButton,
        handleClickSecondSaveButton,
        saveTitle = 'Enregistrer',
        secondSaveTitle = '',
        className = '',
        cssConfirmBtnClass = '',
        cssSecondConfirmBtnClass = '',
        handleClose,
        closeTitle = 'Annuler',
        show,
        title, children,textToCopy
    } = props;
    return (
        <Fragment>
            <Modal
                show={show}
                onHide={handleClose}
                className={className}
                size={size}
                aria-labelledby="contained-modal-title-vcenter"
                centered={centered}
                backdrop="static"
                enforceFocus={false}
                keyboard={false}>
                <LoadingContainer loading={enabledLoading}>
                    {useHeader &&
                    <Modal.Header>
                        <div>{title}</div>

                    </Modal.Header>}
                    <Modal.Body className={"app-modal"}>
                        <div className="modal-body-content" onClick={() => {navigator.clipboard.writeText(textToCopy)}}>
                            {children}
                        </div>
                    </Modal.Body>
                    {useFooter &&
                    <Modal.Footer>
                        {textFooter != null && <div className="modal-footer-text">{textFooter}</div>}
                        <Button variant="outline-secondary me-5" onClick={handleClose}> {closeTitle} </Button>
                        {enableConfirmBtn && <Button className={cssConfirmBtnClass} variant="primary" onClick={(e)=>{handleClickSaveButton(e)}}>{saveTitle}</Button>}
                        {enableSecondConfirmBtn && <button className={cssSecondConfirmBtnClass} onClick={(e)=>{handleClickSecondSaveButton(e)}}>{secondSaveTitle}</button>}
                    </Modal.Footer>}
                </LoadingContainer>
            </Modal>
        </Fragment>
    );
};
export default AppModalComponent;
