import React, {Fragment, useEffect, useState} from 'react'
import DateComponent from "../../Utility/Helpers/DateComponent";
import { withTranslation } from "react-i18next";

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const CellValue = (props) => {
    const {chooseAttr, onClick, className = 'edit-inline-field', value = '', disabled = false, type = 'text',style={}, columnId, brand, description} = props;
    const [fieldValue, setFieldValue] = useState("");
    let fieldsToExcludes = [ "brandId", "numeroProduit", "briefDescription"]
    useEffect(() => {
        if (type === "object" && value != null && value) {
            let item = value;
            try {
                item = JSON.parse(value);
            } catch (e) {
            }
            if (item && item[chooseAttr]) {
                if (chooseAttr === "username" && item["firstname"] !== undefined && item["lastname"] !== undefined) {
                    let fullname = (item["firstname"] + ' ' + item["lastname"]).replace(/\s+$/, '');
                    if (fullname.length === 0) {
                        fullname = item[chooseAttr];
                    }
                    setFieldValue(fullname);
                } else {
                    setFieldValue(item[chooseAttr]);
                }
            } else {
                setFieldValue("");
            }
        } else {
            setFieldValue(value? ""+value : "");
        }
    }, [])

    const handleClick = (e) => {
        if (disabled) {
            e.preventDefault();
        } else {
            switch (e.detail) {
                case 1:
                    onClick(e);
                    break;
                case 2:
                    onClick(e);
                    break;
                case 3:
                    break;
                default:
                    return;
            }
        }
    };

    return (
        <span onClick={event => handleClick(event)} className={className + (disabled ? " disabled" : "")}
                style={ (columnId && (fieldsToExcludes.indexOf(columnId) >= 0)) ? {} : {display: "block", minWidth: "50px", width: "100%", minHeight: "30px", maxWidth: "150px", overflow: "hidden", textOverflow:"ellipsis",...style}}
                title={brand + " - " + description}
        >
            {(type === "date" && fieldValue != 'n/r' && fieldValue != null && fieldValue != '') ? <DateComponent date={fieldValue}/> : 
                type=="checkbox" ? (fieldValue ? props.t("YES") : props.t("NO")):
                fieldValue
            }
        </span>
    );
};
export default withTranslation()(CellValue);
