import React from 'react'
import {Link, useLocation} from "react-router-dom";
import AuthorizationChecker from "../../../security/AuthorizationChecker";

const ActionsFormatterSupplier = (props) => {
    const {user,  enableEdit = true, enableEditSupplier = true,  editRole = "BUYER_APPROVE_VENDOR", deleteRole = "BUYER_APPROVE_VENDOR", location = useLocation()} = props;
    return <div className="bx-pull-right file-system">
        {(enableEdit && AuthorizationChecker.isGranted([editRole])) &&
        <Link className="btn" to={ '/supplier/' + user.user_company + '/assign'}><i className="bx bxs-edit action-icon"/></Link>}
        {(enableEdit && AuthorizationChecker.isGranted(["EDIT_USERS"])) &&
        <Link className="btn" to={ '/pages-users/' + user.id + '/edit'}><i className="bx bxs-user action-icon"/></Link>}
    </div>
}

export default ActionsFormatterSupplier

