import PropTypes from 'prop-types'
import React from "react"
import { Card, CardBody, CardTitle, Table } from "reactstrap"
import { Link } from "react-router-dom"
import { map } from "lodash"
//swiper
// Core modules imports are same as usual
//import { Navigation, Pagination } from 'swiper/modules';
// Direct React component imports
import { Swiper, SwiperSlide } from 'swiper/react';

// Styles must use direct files imports
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import { withTranslation } from 'react-i18next'

const HorizontalTimeline = (props) => {
    const { logs } = props

    return (
                    <div className="hori-timeline">
                        <Swiper
                        slidesPerView={1}
                        // spaceBetween={10}
                        navigation
                        pagination={{
                            clickable: true,
                        }}
                        breakpoints={{
                            678: {
                            slidesPerView: 2,
                            },
                            992: {
                            slidesPerView: 3,
                            },
                            1400: {
                            slidesPerView: 4,
                            }
                        }}                     
                        loop={false}
                       // modules={[Pagination, Navigation]}
                        className="owl-carousel owl-theme  navs-carousel events"
                        id="timeline-carousel"
                        >
                                        {map(logs, (log, k) => (
                                             <SwiperSlide
                                             className="item event-list"
                                             style={{ display: "inline-table" }}
                                             key={k}
                                           >
                                             <div>
                                               <div className="event-date">
                                                 <div className="text-primary mb-1">
                                                   {//display only the date and hour:minute in format AAAA-MM-DD HH:MM
                                                   log.createdAt.split("T")[0] + " " + log.createdAt.split("T")[1].split(".")[0]}
                                                 </div>
                                                 <h5 className="mb-4">{props.t(log.projectLogsType.key)}</h5>
                                               </div>
                                               <div className="event-down-icon">
                                                 <i className={"mdi " + log.projectLogsType.iconClass + " h1 text-primary down-arrow-icon"} />
                                               </div>
                     
                                               <div className="mt-3 px-3">
                                                 <p className="text-muted">
                                                   {props.t("_BY_")} {log.user.firstname} {log.user.lastname} 
                                                 </p>
                                               </div>
                                             </div>
                                           </SwiperSlide>
                                        ))}
                                    </Swiper>
                    </div>
    )
}
HorizontalTimeline.propTypes = {
    logs: PropTypes.array,
    t: PropTypes.func
}
export default withTranslation()(HorizontalTimeline)
