import React, {Fragment, useEffect} from "react";
import DatePicker, {setDefaultLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const DatePickerType = (props) => {
    const {name, onChange, error = '', type = 'text', placeholder = '', value = '', disabled = false, text = '', useLabel = false, className = 'form-control'} = props;
    useEffect(()=>{
    },[value])
    
    // TODO : Locale en fonction de la langue courante de l'utilisateur

    //setDefaultLocale('FR');
    moment.tz.setDefault("UTC")
    const setCurrentDate = (e) => {//return date in format YYYY-MM-DD
        const target = {
            'name': name,
           // 'value': new Date(e).toISOString().slice(0, 10)
           'value':  moment.tz(new Date(e).toISOString().slice(0, 10)+" 12:00", "UTC").format()
        };
        onChange({
            "target": target
        });
    }

    return (
        <Fragment>
            <DatePicker
                className={className}
                disabled={disabled}
                selected={(value && value.length)? new Date(value) : null}
                onChange={date => setCurrentDate(date)}
                dateFormat={'yyyy-MM-dd'}
            />
        </Fragment>
    );
};
export default DatePickerType;
