import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Form,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    Label,
    Input,
    FormFeedback, Alert
} from "reactstrap"
import MetaTags from 'react-meta-tags';
//Import Attachment Cards
import {Link} from "react-router-dom";
import LoadingContainer from "../Utility/Helpers/LoadingContainer";
import {DIALOG_TYPE, HTTP_RESPONSE} from "../Utility/Helpers/constants";
import DialogBoxComponent from "../Utility/Helpers/DialogBoxComponent";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {useFormik} from "formik";
import * as Yup from "yup";
import ProjectsRepository from "./Repository/project-repository";
import ProjectCommentsRepository from "./Repository/project-comments-repository";
import AuthorizationChecker from "../../security/AuthorizationChecker";
import AutocompleteSelectType from "../../helpers/Form/AutocompleteSelectType";
import Autocomplete from "../../helpers/select.autocomplete";
import Comments from "./comments";
import { withTranslation } from "react-i18next";

import UserProvier from "security/UserProvier";

const PagesEditProjects = (props) => {
    const {history, id = 0} = props;

    const companyId = UserProvier.get().companyId

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [dialog, setDialog] = useState({
        show: false,
        onConfirm: () => {
        },
        handleClose: () => {
        },
        message: "",
        type: DIALOG_TYPE.ALERT
    });
    const user = UserProvier.get();
    const [VENDOR_INPUT_STATUS,setVendorInputStatus] = useState(0)
    const [commentaires,setCommentaires] = useState([]);   //TODO: get comments

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            name: '',
            compteSupplierId: null,
            niveau1Id: null,
            public_comment: '',
            private_comment: '',
            statusId: 0,
            
        },
        validationSchema: Yup.object({
            name: Yup.string().required( props.t("REQUIRED_FIELD")),
            compteSupplierId: Yup.object().required( props.t("REQUIRED_FIELD")),
        }),
        onSubmit: (values) => {
            setLoading(true);
            delete values["statusId"]

            if(id){
                values["id"] = id
            }

            if(values.compteSupplierId){
                values.compteSupplierId = values.compteSupplierId.id
            }

            if(values.niveau1Id){
                values.niveau1Id = values.niveau1Id.id
            }

            ProjectsRepository.flush(values).then(item => {
                setLoading(false);
                history.push(getPagesUrl());
            }).catch(response => {
                catchError(response);
            });
        }
    });

    const params = useParams();

    useEffect(() => {
        ProjectsRepository.getStatus().then(data=>{
            //console.log("Status:",data)
            setVendorInputStatus(data["VENDOR_INPUT"])
            validation.setValues({...validation.values, statusId:data["VENDOR_INPUT"]})
            if (id) {
                setLoading(true);
                ProjectsRepository.find(id).then(item => {

                    ProjectsRepository.getSupplierAccount(item.compteSupplierId).then(account => {
                        validation.setValues({
                            name: item.name,
                            private_comment: item.private_comment,
                            public_comment: item.public_comment,
                            statusId: item.statusId,
                            compteSupplierId: account,
                            niveau1Id: item.niveau1Id ? item.niveau1 : null
                        });
                        setLoading(false);
                    }).catch(response => {
                        catchError(response);
                    });

                }).catch(response => {
                    catchError(response);
                });

                ProjectCommentsRepository.findAll(id).then(item => {
                
                    //loop trought all comment, if comment is private, check if user is the VendorId, if yes, conitnue do not add it to temps variable
                    //if comment is public, add it to temps variable
                    let temp_comment = [];
                    for (let i = 0; i < item.length; i++) {
                        if (item[i].isPrivate && (AuthorizationChecker.isGranted(["VENDOR_CREATE_PROJECT"]))) {
                            continue;
                        }
                        else {
                            temp_comment.push(item[i]);
                        }
                    }
                    setCommentaires(temp_comment);
                    //setCommentaires(item);
                }).catch(response => {
                    catchError(response);
                });

            } else {
                setLoading(false);
            }
        })
    }, [id]);

    /**
     * Gestion de la soumission du formulaire
     *
     * @param event
     * @returns {Promise<void>}
     */
    const handleSubmitForm = (event) => {
        event.preventDefault();
        validation.handleSubmit();
    };

    const catchError = (response) => {
        const currentDialog = {
            show: true,
            onConfirm: () => closeDialogModal(),
            handleClose: () => closeDialogModal(),
            type: DIALOG_TYPE.ALERT
        };
        let status = HTTP_RESPONSE.HTTP_NOT_FOUND;
        if (response.status != undefined) {
            status = response.status;
        } else if (response.error != undefined && response.error.statusCode != undefined) {
            status = response.error.statusCode;
        }
        switch (status) {
            case HTTP_RESPONSE.HTTP_BAD_REQUEST: {
                currentDialog.type = DIALOG_TYPE.ALERT;
                break;
            }
            case HTTP_RESPONSE.HTTP_NOT_FOUND: {
                currentDialog.type = DIALOG_TYPE.ALERT;
                break;
            }
            case HTTP_RESPONSE.HTTP_INTERNAL_SERVER_ERROR: {
                currentDialog.type = DIALOG_TYPE.SERVER_ERROR;
                currentDialog.message = '';
                if (response.data.message) {
                    currentDialog.message = response.data.message;
                }
                break;
            }
            case HTTP_RESPONSE.HTTP_UNAUTHORIZED: {
                dialog.type = DIALOG_TYPE.UNAUTHORIZED;
                dialog.message = response.data.error.message
                break;
            }
            case HTTP_RESPONSE.HTTP_UNPROCESSABLE_ENTITY: {
                currentDialog.type = DIALOG_TYPE.SERVER_ERROR;
                if( response.data.error.code=="ER_DUP_ENTRY"){
                    currentDialog.message = props.t("PROJECT_ALREADY_EXIST");
                }else{
                    currentDialog.message = props.t("CHECK_FORM_FEILDS");
                }
                break;
            }
            default:
        }
        setDialog(currentDialog);
    };

    /**
     */
    const closeDialogModal = () => {
        setDialog({...dialog, show: false});
        setLoading(false);
    };
    /**
     *  Methode appelée quand un dossier est ajouté
     *
     */
    const getPagesUrl = () => {
        return '/pages-projects';
    }

    //add comment handle
    const addComment = (comment) => {
      
        const data = {
            userId: user.id,
            comments: comment.comments,
            isPrivate: comment.isPrivate,
            isReply: comment.isReply,
            projectId: id
        }
        ProjectCommentsRepository.add(data).then(item => {
            if (item && item.error) {
                catchError(item);
            }
            if (item){
                setCommentaires([...commentaires, item]);
            }
            else{
                catchError(item);
            }
        }
        ).catch(response => {
            catchError(response);
        });
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{id ? props.t("EDIT_PROJECT") : props.t('ADD_PROJECT')}</title>
                </MetaTags>
                <Container fluid={true}>
                    <div className="mb-4">
                        <Breadcrumbs title="Projets" breadcrumbItem={ id ? props.t("EDIT_PROJECT") : props.t('ADD_PROJECT')}/>
                        <Link className="btn btn-info" to={getPagesUrl()}>{props.t("BACK_TO_LIST")}</Link>
                    </div>
                    <LoadingContainer className="mb-4" loading={loading}>
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <CardTitle>{ id ? props.t("EDIT_PROJECT") : props.t('ADD_PROJECT')}</CardTitle>
                                        <CardSubtitle className="mb-3">
                                            {id ? props.t("UPDATE_PROJECT_DESCRIPTION") : props.t("ADD_PROJECT_DESCRIPTION")}
                                        </CardSubtitle>
                                        <Form onSubmit={(e) => handleSubmitForm(e)}>
                                            {error.length ? (
                                                <Alert color="danger">
                                                    {error}
                                                </Alert>
                                            ) : null}
                                            <div className="row">
                                                <div className="col-md-8 col-12">
                                                    <div className="mb-3">
                                                        <Label className="form-label">{props.t("SUPPLIER_ACCOUNT")} *</Label>
                                                        <AutocompleteSelectType
                                                            id={"compteSupplierId"}
                                                            name={"compteSupplierId"}
                                                            className={"input-form"}
                                                            remoteChoices={(inputValue) => Autocomplete.supplierAccounts(inputValue)}
                                                            choiceAttr={"nom"}
                                                            value={validation.values.compteSupplierId || null}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            placeholder={props.t("SELECT_SUPPLIER_ACCOUNT")}
                                                            isDisabled={!(AuthorizationChecker.isGranted(["VENDOR_CREATE_PROJECT"]) && validation.values.statusId==VENDOR_INPUT_STATUS)}
                                                        />
                                                        {validation.touched.compteSupplierId && validation.errors.compteSupplierId ? (
                                                            <FormFeedback type="invalid" className="d-block">{props.t("REQUIRED_FIELD")}</FormFeedback>
                                                        ) : null}
                                                    </div>

                                                    <div className="mb-3">
                                                        <Label className="form-label">{props.t("PROJECT_NAME")}</Label>
                                                        <Input
                                                            id="name"
                                                            name="name"
                                                            className="form-control"
                                                            placeholder={props.t("ENTER_NAME")}
                                                            type="text"
                                                            disabled={!(AuthorizationChecker.isGranted(["VENDOR_CREATE_PROJECT"]) && validation.values.statusId==VENDOR_INPUT_STATUS)}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.name || ""}
                                                            invalid={
                                                                validation.touched.name && validation.errors.name ? true : false
                                                            }
                                                        />
                                                        {validation.touched.name && validation.errors.name ? (
                                                            <FormFeedback type="invalid" className="d-block">{props.t("REQUIRED_FIELD")}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                    {
                                                        companyId!=2 && (
                                                            <div className="mb-3">
                                                                <Label className="form-label">{props.t("LEVEL1")}</Label>
                                                                <AutocompleteSelectType
                                                                    id={"niveau1Id"}
                                                                    name={"niveau1Id"}
                                                                    className={"input-form"}
                                                                    remoteChoices={(inputValue) => Autocomplete.productReferencesValues(inputValue, "niveau1Id")}
                                                                    choiceAttr={"description"}
                                                                    value={validation.values.niveau1Id || null}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    placeholder={props.t("SELECT_LEVEL1")}
                                                                    isDisabled={!(AuthorizationChecker.isGranted(["VENDOR_CREATE_PROJECT"]) && validation.values.statusId==VENDOR_INPUT_STATUS)}
                                                                />
                                                                {validation.touched.niveau1Id && validation.errors.niveau1Id ? (
                                                                    <FormFeedback type="invalid" className="d-block">{props.t("REQUIRED_FIELD")}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        )
                                                    }

                                                </div>
                                            </div>

                                            <div className="mt-4">
                                                <button
                                                    disabled={(AuthorizationChecker.isGranted(["VENDOR_CREATE_PROJECT"]) && validation.values.statusId!=VENDOR_INPUT_STATUS)}
                                                    type="submit"
                                                    className="btn btn-primary">
                                                        {props.t("SAVE_MODIFICATIONS")}                                                    
                                                </button>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        {(id) && (
                            <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <Comments comments={commentaires}
                                            addComment={addComment}
                                            AuthorizationChecker={AuthorizationChecker}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>)}
                    </LoadingContainer>
                </Container>
                <DialogBoxComponent
                    handleClose={() => closeDialogModal()}
                    handleConfirm={() => dialog.onConfirm()}
                    show={dialog.show}
                    type={dialog.type}
                    message={dialog.message}
                />
            </div>
        </React.Fragment>
    )
}
export default  withTranslation()(PagesEditProjects)
