import MetaTags from "react-meta-tags";
import React, { useState, useEffect,setState,Fragment } from "react";
import {
  Row,
  Col,
  Form,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  FormFeedback, Alert
} from "reactstrap"

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/avatar-1.jpg";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";
import UsersRepository from "pages/Users/repository/users-repository";
import LoadingContainer from "../Utility/Helpers/LoadingContainer";
import {DIALOG_TYPE, HTTP_RESPONSE} from "../Utility/Helpers/constants";
import DialogBoxComponent from "../Utility/Helpers/DialogBoxComponent";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next";









const UserProfile = (props) => {
  //new code
  const {id = 0, onEditSuccess = {}, apiUrl = "asas"} = props;
  const [success,setSuccess] = useState()
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [dialog, setDialog] = useState({
    show: false,
    onConfirm: () => {
    },
    handleClose: () => {
    },
    message: "",
    type: DIALOG_TYPE.ALERT
});
  const [myProfile,setMyProfile] = useState({});
  const [cie,setCie] = useState([])
  const [enabled, setEnabled] = useState(false);
  const manager =  UsersRepository;
  const [isReadonly, setIsReadonly] = useState(true);
  const [cieNb, setCieNb] = useState(0);
  const [btn, setBtn] = useState("")
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      email: '',
      username: '',
      lastname: '',
      firstname: '',
      enabled: 1,
      password: null,
    },
    validationSchema: Yup.object({
      //email: Yup.string().required(props.t("REQUIRED_FIELD")),
      username: Yup.string().required(props.t("REQUIRED_FIELD")),
    //  lastname: Yup.string().required(props.t("REQUIRED_FIELD"))
    }),
    onEditSuccess: (values) => {
      console.log("success");
    },
    onSubmit: (values) => {
      setLoading(true);
     // const user = new Access();
      let tmp = values;
      delete tmp.companies
      if(tmp.password){}
      else{
        delete tmp.password;
      }
      console.log(tmp);
   
      
      manager.edit(tmp).then((user) => {
        setLoading(false);
        if (typeof onEditSuccess == 'function') {
         
          onEditSuccess(user);
        }
        const currentDialog = {
          show: true,
          handleClose: () => {
              redirectTo('/dashboard');
          },
          onConfirm: () => {
            redirectTo('/dashboard');
        },
        message: props.t("PROFILE_UPDATED"),
        title: '',
          type: DIALOG_TYPE.SUCCESS
      };
      setDialog(currentDialog);
      }).catch(response => {
        const {data = null} = response;
        if (data != null && data.code && data.code == HTTP_RESPONSE.HTTP_NOT_ACCEPTABLE || data.code == HTTP_RESPONSE.HTTP_BAD_REQUEST) {
          setError(data.message);
        }
        setLoading(false);
      })
    }
  });
  useEffect( () => {
 //   console.log("ici");
    setLoading(true);  
     manager.me().then(user => {
  //    console.log(user);
  //    console.log(user.companies.length);
      let cietmp = [];
      user.companies.map((element, index) => {
      //  console.log(element);
        cietmp.push(element);
      });
 //     console.log(cietmp);
     setMyProfile({...user})
     setCie(cietmp)
     setCieNb(cietmp.length)
     setLoading(false);
      validation.setValues(user);
    })
  
},[]);
  
  
  /**
   *  Methode appelée quand la valeur d'un champ de formulaire change
   *
   * @param target
   */
   const toogleEnable = ({target}) => {
    setEnabled(!enabled);
  };

  const remove_readonly = (target) => {
    console.log(target)
    setIsReadonly(prevState => !prevState)
  }
  /**
   * Gestion de la soumission du formulaire
   *
   * @param event
   * @returns {Promise<void>}
   */
  const handleSubmitForm = (event) => {
    event.preventDefault();
    validation.handleSubmit();
  };

  const catchError = (response) => {
    const currentDialog = {
      show: true,
      onConfirm: () => closeDialogModal(),
      handleClose: () => closeDialogModal(),
      type: DIALOG_TYPE.ALERT
    };
    switch (response.status) {
      case HTTP_RESPONSE.HTTP_BAD_REQUEST: {
        currentDialog.type = DIALOG_TYPE.ALERT;
        break;
      }
      case HTTP_RESPONSE.HTTP_NOT_FOUND: {
        currentDialog.type = DIALOG_TYPE.ALERT;
        break;
      }
      case HTTP_RESPONSE.HTTP_INTERNAL_SERVER_ERROR: {
        currentDialog.type = DIALOG_TYPE.SERVER_ERROR;
        currentDialog.message = '';
        if (response.data.message) {
          currentDialog.message = response.data.message;
        }
        break;
      }
      default:
    }
    setDialog(currentDialog);
  };

  /**
   */
  const closeDialogModal = () => {
    setDialog({...dialog, show: false})
  };

  const toggleDialogModal = () => {
    setDialog({...dialog, show: false})
  };

  const addAccess = (e) => {
    
    
    manager.requestAccess((cie[0].id == 1) ? 2 : 1).then((response) => {

      const requestDialog = {
        show: true,
        onConfirm: () => closeDialogModal(),
        handleClose: () => closeDialogModal(),
        type: DIALOG_TYPE.SUCCESS,
        message : props.t("REQUEST_SENT")
      };
      //console.log(requestDialog);
      document.getElementById("req").classList.add("d-none")
      setDialog(requestDialog);
    })
  }
  //end new code



 

 


  return (
    
    <React.Fragment>
      <div className="page-content">
      <MetaTags>
          <title>{props.t("MY_PROFILE")}</title>
        </MetaTags>
        <Breadcrumb title="." breadcrumbItem={props.t("MY_PROFILE")} />
      <LoadingContainer className="mb-4" loading={loading}>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle>{props.t("MY_PROFILE")}/{myProfile.username} </CardTitle>
                <CardSubtitle className="mb-3">
                  
                </CardSubtitle>
                <Form autoComplete="off" onSubmit={(e) => handleSubmitForm(e)}>
                  {error.length ? (
                    <Alert color="danger">
                      {error}
                    </Alert>
                  ) : null}
                  <div className="row">
                    <div className="col-md-12 col-12">
                      <div className="mb-3">
                        <Label className="form-label">{props.t("FIRST_NAME")}</Label>
                        <Input
                          id="firstname"
                          name="firstname"
                          className="form-control"
                          placeholder={props.t("FIRST_NAME")}
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.firstname || ""}
                        />
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">{props.t("NAME")}</Label>
                        <Input
                          id="lastname"
                          name="lastname"
                          className="form-control"
                          placeholder={props.t("NAME")}
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.lastname || ""}
                          invalid={
                            validation.touched.lastname && validation.errors.lastname ? true : false
                          }
                        />
                        {validation.touched.lastname && validation.errors.lastname ? (
                          <FormFeedback
                            type="invalid">{validation.errors.lastname}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">{props.t("YOUR_COMPANY")}</Label>
                        <Input
                          id="companyName"
                          name="companyName"
                          className="form-control"
                          placeholder={props.t("YOUR_COMPANY")}
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.companyName || ""}
                          invalid={
                            validation.touched.companyName && validation.errors.companyName ? true : false
                          }
                        />
                        {validation.touched.companyName && validation.errors.companyName ? (
                          <FormFeedback
                            type="invalid">{validation.errors.companyName}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">{props.t("ATTITRED_COMPANY")}</Label>
                        <div className="table-responsive">
                          <div className="react-bootstrap-table">
                            <table className="table table align-middle table-nowrap">
                              <thead className="thead-light">
                                    <tr>
                                        <th tabIndex={0}>{props.t("COMPANY_NAME")}</th>
                                        <th tabIndex={0}>{props.t("SUPPLIERS")} </th>
                                    </tr>
                              </thead>
                              <tbody>
                               {cie.map((c,index) => <tr key={index}> 
                                <td><b>{c.name}</b></td>
                                <td> {c.usercompany[0].suppliers.length >0 ? c.usercompany[0].suppliers.map((sup,indexsup) =>
                                  <Fragment key={indexsup}><span className="badge bg-info p-2">{sup.nom}</span><br></br></Fragment>
                                  )  : <Fragment><span className="badge bg-info p-2">{props.t("_NONE")}</span><br></br></Fragment>}
                                   
                                </td>
                               </tr>)}
                               {cieNb == 1 ? <tr><td>{cie[0].id == 1 ?  <b>Ren&apos;s Pet</b> : <b>MONDOU</b>}</td><td><span id="request"><button id="req" type="button"  onClick={(e) =>addAccess(e)} className="btn btn-primary">{props.t("REQUEST_ACCESS")}</button></span></td></tr>: <tr></tr>}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      
                      <div className="mb-3">
                        <Label className="form-label">{props.t("EMAIL")}</Label>
                        <Input
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder={props.t("EMAIL")}
                          type="email"
                          disabled={true}
                          
                          value={validation.values.email || ""}
                          
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback
                            type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label
                          className="form-label">{props.t("USERNAME")}</Label>
                        <Input
                          id="username"
                          name="username"
                          className="form-control"
                          placeholder={props.t("USERNAME")}
                          type="text"
                          disabled={true}
                          
                          value={validation.values.username || ""}
                          
                        />
                        {validation.touched.username && validation.errors.username ? (
                          <FormFeedback
                            type="invalid">{validation.errors.username}</FormFeedback>
                        ) : null}
                      </div>
                      

                      <div className="mb-3">
                        <Label
                          className="form-label">{props.t("PASSWORD")}</Label>
                        <Input
                          id="password"
                          name="password"
                          className="form-control"
                          placeholder={props.t("PASSWORD")}
                          type="password"
                          autoComplete = "new_pass"
                          readOnly = {isReadonly}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          onFocus ={()=> setIsReadonly(false)}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid"> { '<p>' + validation.errors.password + '</p>'}</FormFeedback>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary">
                      {props.t("SAVE_MODIFICATION")}                    
                    </button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LoadingContainer>
    </div>
    <DialogBoxComponent
                    handleClose={() => toggleDialogModal()}
                    handleConfirm={() => dialog.onConfirm()}
                    show={dialog.show}
                    type={dialog.type}
                    message={dialog.message}
                />
    </React.Fragment>
  )
};

export default withTranslation()(withRouter(UserProfile));
