import React, { Component,  useState,useEffect } from 'react';
import { Card, CardBody, CardTitle, Col, Container, Row ,Table,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,Form,Label,FormFeedback} from 'reactstrap';


import SelectType from "../../helpers/Form/SelectType";

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import axios from "axios";
import {HTTP_RESPONSE} from "../Utility/Helpers/constants";
import fileDownload from 'js-file-download';
import AppModalComponent from '../Utility/Helpers/AppModalComponent';
import { set } from 'lodash';
import {useFormik} from "formik";
import * as Yup from "yup";
import FileExplorerRepository from "./Repository/file-explorer-repository";
const VendorFiles = (props) => {
  
    const authUser = JSON.parse(localStorage.getItem('authUser'));
    const company = authUser.companyId;
    const [files, setFiles] = useState([]);
    const [enabledModalLoading, setEnabledModalLoading] = useState(false);

    const [errors, setErrors] = useState([])
    const [backendResponse, setBackendResponse] = useState("")
    const [showError, setShowError] = useState(false)
    const [showResponse, setShowResponse] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [fileToImport, setFileToImport]= useState(null)


    const fileSelected = (e)=>{
        console.log(e)

        const file = e.target.files[0]
        setFileToImport(file)
      }

     
    
    const handleSubmitForm = (event) => {
        event.preventDefault()
        console.log(event)
        uploadValidation.handleSubmit(event);
    };

const uploadValidation = useFormik({
    enableReinitialize: true,
        initialValues: {
            file: ''
        },

        validationSchema: Yup.object({
            file: Yup.string(props.t("REQUIRED_FIELD")).required(props.t("REQUIRED_FIELD"))
        }),
        onSubmit: (values) => {
            setEnabledModalLoading(true)
            console.log("1-",values)
            setEnabledModalLoading(false)
            const formData = new FormData()
            formData.append("file", fileToImport)
            FileExplorerRepository.upload(formData).then( response => {
                    setBackendResponse(response)
                    const transformedData =  transformData(response);
                    setFiles(transformedData);
                    setShowResponse(true)
                    setShowModal(false)
                    setEnabledModalLoading(false)
                    //reset form and file
                    uploadValidation.resetForm()
                    setFileToImport(null)
               
            }
            ).catch(error => {
                setErrors(error)
                setShowError(true)
                setEnabledModalLoading(false)
            })
            return;
        }


})

const hideImportPage = () => {
    setShowError(false)
    setShowResponse(false)
    setShowModal(false)
}

const closeModal = () => {
    setShowError(false)
    setShowResponse(false)
    setShowModal(false)
}

const showform = () => {
    setShowModal(true)
}
    //download with axios file from /api/userlg/download/:files
 useEffect(async () => {
    //get the file liste from api /api/file-explorer/VendorFiles
    const url = process.env.REACT_APP_API_BASE_URL + 'file-explorer/VendorFiles';
    await axios.get(url).then(async response => {
        console.log(response);
        if (response.status === 200) {
            console.log(response.data);
            const transformedData = await transformData(response.data);
            setFiles(transformedData);
        }
    }).catch(error => {
        console.log(error);
    });

 }, []);

 const getFileIcon = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    switch(extension) {

        case 'xlsx':
            return "mdi mdi-file-excel font-size-16 align-middle text-primary me-2";
        case 'pdf':
            return "mdi mdi-file-pdf font-size-16 align-middle text-primary me-2";
        case 'doc':
            return "mdi mdi-file-word font-size-16 align-middle text-primary me-2";
        case 'ppt':
            return "mdi mdi-file-powerpoint font-size-16 align-middle text-primary me-2";
        case 'zip':
            return "mdi mdi-file-zip font-size-16 align-middle text-primary me-2";
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
            return "mdi mdi-file-image font-size-16 align-middle text-primary me-2";
      
        default:
            return "mdi mdi-file-document font-size-16 align-middle text-primary me-2";
    }
  };
  
  const transformData = (data) => {
    return data.map((item, index) => {
      return {
        id: index + 1,
        icon: getFileIcon(item.name),
        file: item.name,
        path: item.path,
        date: new Date(item.createdAt).toLocaleDateString("fr-FR", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        }),
        size: (item.size / 1000).toFixed(2) + " KB", // convert size to KB
      };
    });
  };

    const download = async(file) => {
        console.log(file)
        const url = process.env.REACT_APP_API_BASE_URL + 'userlg/download/f=' + encodeURIComponent(file);
        //get the name of the file
        const fileName = file.split('/').pop();
        axios({
            url: url,
            method: 'GET',
            responseType: 'blob', // Important
        }).then((response) => {
            fileDownload(response.data, fileName);
        });
        }
    
    const deletefile = async(file) => {
        console.log(file)
        await FileExplorerRepository.delete(file).then( response => {
            console.log(response);
            const transformedData =  transformData(response);
            setFiles(transformedData);
        }
        ).catch(error => {
            console.log(error);
        });
        }
        


        return (
            <React.Fragment>
              <div className="page-content">
              <Container fluid>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                               <Row>
                                <Col className='col-10'>
                                <CardTitle className="mb-4">{props.t("_FILE_MANAGER_")}</CardTitle>
                                </Col>
                                <Col className='col-2'>
                                    <div className="mb-4">
                                        <div className="mb-3">
                                            <UncontrolledDropdown>
                                            <DropdownToggle
                                                className="btn btn-light w-100"
                                                color="#eff2f7"
                                            >
                                                <i className="mdi mdi-plus me-1"></i> {props.t("_CREATE_NEW_")}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <Link className="dropdown-item" to="#">
                                                <i className="bx bx-folder me-1"></i> {props.t("_FOLDER_")}
                                                </Link>
                                                <Link className="dropdown-item" to="#" onClick={showform}>
                                                <i className="bx bx-file me-1"></i> {props.t("_FILE_")}
                                                </Link>
                                            </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                     </div>
                                     </Col>
                                    </Row> 
                                    <div className="table-responsive">
                                        <Table className="table align-middle table-nowrap table-hover mb-0">
                                            <thead>
                                                <tr>
                                                    <th scope="col">{props.t("_NAME_")}</th>
                                                    <th scope="col">{props.t("_LAST_MODIFIED_DATE")}</th>
                                                    <th scope="col">{props.t("_SIZE_")}</th>
                                                    <th scope="col">{props.t("_ACTION_")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {files.map((file, key) => (
                                                    <tr key={key}>
                                                        <td>
                                                            <Link to="#" className="text-dark fw-medium">
                                                                <i className={file.icon}></i> {file.file}
                                                            </Link>
                                                        </td>
                                                        <td>{file.date}</td>
                                                        <td>{file.size}</td>
                                                        <td>
                                                            <UncontrolledDropdown className="mb-2" direction="left">
                                                                <DropdownToggle
                                                                    color="secondary"
                                                                    className="btn btn-sm btn-secondary"
                                                                    type="button"
                                                                    id="action"
                                                                >
                                                                    {props.t("_ACTION_")}
                                                                    <i className="mdi mdi-chevron-down"></i>
                                                                </DropdownToggle>
                                                                <DropdownMenu>
                                                                    <Link to="#" className="dropdown-item" onClick={() => download(file.path)}>
                                                                        <i className="mdi mdi-download me-2 text-muted align-middle"></i> {props.t("_DOWNLOAD_")}
                                                                    </Link>
                                                                    <Link to="#" className="dropdown-item" onClick={() => deletefile(file.file)}>
                                                                        <i className="mdi mdi-delete me-2 text-muted align-middle"></i> {props.t("_DELETE_")}
                                                                    </Link>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                </Container>
              </div>


              <AppModalComponent
                enabledLoading={enabledModalLoading}
                handleClose={closeModal}
                handleClickSaveButton={handleSubmitForm}
                show={showModal}
                closeTitle={ props.t("CLOSE")}
                saveTitle={props.t("_UPLOAD_FILE_")}
                enableConfirmBtn={true}
                title={props.t("_UPLOAD_FILES_FORM")}
            >

<Form onSubmit={(e) => handleSubmitForm(e)}>
                    <div className="row main-form-card pt-3 pb-0 ps-5 pe-5">
                        <div className="col-md-8 col-12">
                            

                            <div className="mb-3">
                                <Label className="form-label">{props.t("FILE_TO_IMPORT")} *</Label>
                                <br />
                                <input
                                    id={"file"}
                                    name={"file"}
                                    type={"file"}
                                    className={"input-form"}
                                    value={uploadValidation.values.file || ""}
                                    onChange={(e)=>{fileSelected(e); uploadValidation.handleChange(e)}}
                                    placeholder={props.t("SELECT_FILE_TO_IMPORT")}
                                />
                                 {uploadValidation.touched.file && uploadValidation.errors.file && (
                                    <FormFeedback type="invalid" className="d-block invalid">{props.t("REQUIRED_FIELD")}</FormFeedback>
                                )}
                               
                            </div>
                        </div>
                    </div>
                </Form>
            </AppModalComponent>
            </React.Fragment>
        );

}

export default withTranslation()(VendorFiles);