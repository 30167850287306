import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Badge, Container, Row, Col, Card, CardBody, BreadcrumbItem } from "reactstrap";
import { Input } from "reactstrap";
import { Link } from "react-router-dom";
import LoadingContainer from "../Utility/Helpers/LoadingContainer";
import { DIALOG_TYPE, HTTP_RESPONSE } from "../Utility/Helpers/constants";
import DialogBoxComponent from "../Utility/Helpers/DialogBoxComponent";
import "../Utility/Helpers/style.css";
import ProjectsRepository from "./Repository/project-repository";
import TableContainerGrid from "pages/Utility/Helpers/TableContainerGrid";
import PaginationWrap from "../Utility/Helpers/PaginationWrap";
//import TableContainer from "components/Common/Grid/TableContainer";
import TableContainer from "pages/Utility/Helpers/TableContainer";
import ActionsFormatter from "../Utility/Helpers/ActionsFormatter";
import AuthorizationChecker from "../../security/AuthorizationChecker";
import UserProvier from "security/UserProvier";
import { withTranslation } from "react-i18next";

const PagesProjectsList = ( props ) => {
    const [CORRECT_STATUS, setCorrectStatus] = useState([]);
    const [projectStatus, setProjectStatus] = useState({});
    const [items, setItems] = useState([]);
    const [selectedProjectsIds, setSelectedProjectsIds] = useState([]);
    const [unSelectableProjectsIds, setUnSelectableProjectsIds] = useState([]);
    const [hiddenColumns, setHiddenColumns] = useState([]);
    const [errors, setErrors] = useState([]);
    const [total, setTotal] = useState(0);
    const [entity, setEntity] = useState(null);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [loading, setLoading] = useState(true);
    const [loadingSearch, setLoadingSearch] = useState(true);
    const [query, setQuery] = useState("");
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [companyId] = useState(UserProvier.get().companyId);
    useEffect(() => {
        ProjectsRepository.getStatus()
        .then((data) => {
            console.log("Status:", data);
            
            //loop trought data and add status into projectStatus
            
            setProjectStatus(data); 
            
            setCorrectStatus(data["CORRECT_STATUS"]);
           
          
            })
            .catch((response) => {
                console.log("Failed loading status: ", response);
            });
    }, []);

   

    const selectAllProject = (event) => {
      //  console.log(projectStatus)
        let {checked} = event.target;
        let selectedProjects= []

        if(checked){
            selectedProjects = items.filter((p) => unSelectableProjectsIds.indexOf(p.id) === -1).map((e) => e.id);
        }
        console.log(selectedProjects)
        setSelectedProjectsIds(selectedProjects)
    }
    
    const selectProject = (id) => {
        console.log("selectProject",id)
        console.log("INDEX",unSelectableProjectsIds.indexOf(id))
        if (unSelectableProjectsIds.indexOf(id) >= 0) {
            console.log("dans les projets non selectionnables");
            catchError({
                status: HTTP_RESPONSE.HTTP_UNAUTHORIZED,
                data: {
                    error: {
                        message: "{\"message\":\"PROJECT_UNMODIFIABLE\",\"name\":\"PROJECT_AT_INCOMPATIBLE_STATE\"}"
                    }
                }
            });
            return;
        }
    //    console.log("selectedProjectsIds",selectedProjectsIds);
        let checked = selectedProjectsIds.indexOf(id) === -1;
       
        let selectedProjects = selectedProjectsIds;

        if (checked) {
            selectedProjects.push(id);
        } else {
            selectedProjects = selectedProjects.filter((i) => i !== id);
        }

        setSelectedProjectsIds(selectedProjects);
    }


    const parseState = (stateId,projectStatus) =>{
        if( Object.keys(projectStatus).indexOf(""+stateId) >= 0){
            return projectStatus[stateId]
        }else{
            return projectStatus[0]
        }
    }


const toggleDialogModal = () => {};    

const handleDelete = (e, elt) => {
    e.preventDefault();
    setEntity(elt);
    setDialog({
        ...dialog,
        onConfirm: ()=> {}, // performDelete(),
        handleClose: ()=> {}, //closeDialogModal(),
        type: DIALOG_TYPE.CONFIRM_DELETION
    });
    toggleDialogModal();
}

    const BtnActions = (props) => {

        
        let item_row=props.row.original
        let canSelfAssign = false;
        if (AuthorizationChecker.isGranted(["BUYER_EDIT_PROJECT"]))
            {
                if (!item_row.purchaserId)
                    {
                        canSelfAssign = true;
                    }
            }
        return (
            <ActionsFormatter
            item={item_row}
            editRole={
                AuthorizationChecker.isGranted(["VENDOR_READ_PROJECT"]) ? "VENDOR_EDIT_PROJECT" :
                AuthorizationChecker.isGranted(["BUYER_READ_PROJECT"]) ? "BUYER_EDIT_PROJECT" :
                AuthorizationChecker.isGranted(["MD_READ_PROJECT"]) ? "MD_EDIT_PROJECT" : 
                AuthorizationChecker.isGranted(["ECOM_READ_PROJECT"]) ? "ECOM_EDIT_PROJECT" : 
                ""
            }
            enableRemove = {
                AuthorizationChecker.isGranted(["VENDOR_EDIT_PROJECT"]) ? (CORRECT_STATUS.indexOf(item_row.statusId) >=0) :
                AuthorizationChecker.isGranted(["BUYER_EDIT_PROJECT"]) ?  ( (CORRECT_STATUS.indexOf(item_row.statusId) >=0) && item_row.purchaserId==UserProvier.get().id) : true
            }
            enableEdit={
                AuthorizationChecker.isGranted(["VENDOR_READ_PROJECT"]) ? (CORRECT_STATUS.indexOf(item_row.statusId) >=0) :
                AuthorizationChecker.isGranted(["BUYER_READ_PROJECT"]) ?  ( (CORRECT_STATUS.indexOf(item_row.statusId) >=0) && item_row.purchaserId==UserProvier.get().id) :
                AuthorizationChecker.isGranted(["MD_READ_PROJECT"]) ?  ( (CORRECT_STATUS.indexOf(item_row.statusId) >=0) && item_row.dmId==UserProvier.get().id) :
                AuthorizationChecker.isGranted(["ECOM_READ_PROJECT"]) ?  ( (CORRECT_STATUS.indexOf(item_row.statusId) >=0) && item_row.ecommId==UserProvier.get().id) : true
            }
            enableAssign ={canSelfAssign}
            onDelete={(event, elt) => handleDelete(event, elt)}/>
        );
    }


   
    
    const onPageChange = (page) => {
        setPageSizeOptions((prevState) => ({
            ...prevState,
            page,
        }));
       // findAll(page);
    };

    const handleSearchChange = (value) => {
        setQuery(value);
        setLoadingSearch(true);
        findAll();
    };
    const [pageSizeOptions, setPageSizeOptions] = useState({
        totalSize: 0,
        custom: true,
        onPageChange: (page) => onPageChange(page),
        onSearchChange: handleSearchChange,
        page: 1,
        sizePerPage: 10,
        pageStartIndex: 1,
        sizePerPageList: 10,
        showTotal: true,
        onSizePerPageChange: {},
    });
    const [dialog, setDialog] = useState({
        show: false,
        onConfirm: () => {},
        handleClose: () => {},
        message: "",
        type: DIALOG_TYPE.ALERT,
    });
  



    const findAll = (page = 1) => {
        //check if CORRECT_STATUS is empty or not if empty return
        if (CORRECT_STATUS.length == 0) {
            return;
        }
        ProjectsRepository.getList(query)
            .then((data) => {
                setItems(data.items);
                console.log(data);
                /*setUnSelectableProjectsIds(
                    data.items.filter((p) => CORRECT_STATUS.indexOf(p.statusId) < 0).map((p) => p.id)
                );*/
                let unSelectableProjects = data.items.filter((p) => {
                    //console.log("STATUS :"+p.statusId+" ID : "+p.id);
                    //console.log("CORRECT_STATUS",CORRECT_STATUS);
                    if (CORRECT_STATUS.indexOf(p.statusId) < 0 ) {
                       // console.log("unsellable",p.id)
                        return p.id;
                    }
                });
                setUnSelectableProjectsIds(unSelectableProjects.map((p) => p.id));
                setTotal(data.totalCount);
                setPageSizeOptions((prevState) => ({
                    ...prevState,
                    sizePerPage: itemPerPage,
                    totalSize: 10,
                }));
                setLoading(false);
                setLoadingSearch(false);
                //test add hidden columns
                //setHiddenColumns(["checkbox","actions"]);
               //add with push hidden columns


            })
            .catch((response) => {
                catchError(response);
            });
    };

    useEffect(() => {
        findAll();
    }, [CORRECT_STATUS]);
   

    const catchError = (response) => {
        // Handle error
    };

   const isOwner = (row,user_id) => {
    return row.original.purchaserId == user_id || row.original.vendorId == user_id || row.original.dmId == user_id || row.original.ecommId == user_id
   }

  
    let user = UserProvier.get();
    
 

const columns =
useMemo(() => [
        {
            id: "checkbox",
            accessor: "",
          //  Header: <input type="checkbox" id="0" onChange={e=>selectAllProject(e)}/>,
            
          
            Cell: ({ row }) => {
                let item = row.original;
                if (unSelectableProjectsIds.indexOf(item.id) >= 0) {
                    return (
                        <div className="form-check">
                            <Input
                                type="checkbox"
                                className="form-check-input"
                                id={item.id}
                                disabled
                            />
                            <label className="form-check-label" htmlFor={item.id}></label>
                        </div>
                    );
                } else {
                    return (
                        <div className="form-check">
                            <Input
                                type="checkbox"
                                className="form-check-input"
                                id={item.id}
                                onChange={() => selectProject(item.id)}
                            />
                            <label className="form-check-label" htmlFor={item.id}></label>
                        </div>
                    );
                }
            },
            disableFilters: true,
            disableSortBy: true,
        },
        {
           // Header: props.t("_ACTIONS_"),
            accessor: "actions",
            Cell: BtnActions,
            disableFilters: true,
            
        },
        {
            Header: props.t("VENDOR"),
            accessor: "vendorName",
        },
        {
            Header: props.t("PROJECT_NAME"),
            accessor: "name",
           
        },
       
        {
            Header : props.t("STATUS"),
            accessor: "statusId",
            Cell: ({ row }) => {
                //get the project status state list and store it into statuss
                //assign the color base on these conditions
                //if the statusId is in the correct status list, and the user.id is the same as the purchaserId or vendorId or dmId or ecommId, the color will be green
                //if the statusId is not in the correct status list,  and the user.id is the same as the purchaserId or vendorId or dmId or ecommId, the color will be yellow (warning)
                //if the user.id is not the same as the purchaserId or vendorId or dmId or ecommId, the color will be red
                //if statusId is Finished, the color will be blue
                let color ="";
                let user_id = UserProvier.get().id;
                if ((CORRECT_STATUS.indexOf(row.original.statusId) >= 0)) {
                    //if the user.id is the same as the purchaserId or vendorId or dmId or ecommId
                    if (isOwner(row,user_id) ) {
                        color = "success"
                    }else{
                        color = "danger"
                    }
                }else{
                    if (isOwner(row,user_id)) {
                        color = "warning"
                    }else{
                        color = "danger"
                    }
                    //check if status is Finished
                    let status = parseState(row.original.statusId,projectStatus)
                    if (status == "Finished") {
                        color = "primary"
                    }
                }

               
                return <Badge className={"font-size-12 badge-" + color}
                color={color}
                pill>{parseState(row.original.statusId,projectStatus)}</Badge>;
                
            },
        },
        {
            Header: props.t("PURCHASER"),
            accessor: "purchaserName",
        
        },
        {
            Header: props.t("DM"),
            accessor: "dmName",
        },
        {
            Header: props.t("LAST_COMMENT"),
            accessor: "lastComment",
            Cell: ({ row }) => {
                let item = row.original;
                if (!item.lastComment.comments)
                    {
                        return null;
                    }
                if (item.lastComment.comments && item.lastComment.isPrivate == 0)
                    {
                        return (<div className="flex-grow-1">
                            <p className="text-muted mb-0">
                                <i className="mdi mdi-calendar me-1">  </i>
                                {item.lastComment.createdAt}
                            </p>
                            <h5 className="font-size-13 mb-1">{item.lastComment.user.firstname} {item.lastComment.user.lastname}</h5>
                            <p className="text-muted mb-1  ms-3"><span dangerouslySetInnerHTML={{ __html: item.lastComment.comments }} /></p>
                    
                        </div>)
                    }
                if ((item.lastComment.comments && item.lastComment.isPrivate == 1) &&  (
                    AuthorizationChecker.isGranted(["BUYER_READ_PROJECT"]) || 
                    AuthorizationChecker.isGranted(["MD_READ_PROJECT"]) || 
                    AuthorizationChecker.isGranted(["ECOM_READ_PROJECT"])  
                ))
                {
                    return (<div className="flex-grow-1">
                    <p className="text-muted mb-0">
                      <i className="mdi mdi-calendar me-1">  </i>
                      {item.lastComment.createdAt + " "}{item.lastComment.isPrivate ? (<span className="badge rounded-pill bg-danger">
                                    {props.t("_PRIVATE_")}
                                  </span>):""} </p>
                    <h5 className="font-size-13 mb-1">{item.lastComment.user.firstname} {item.lastComment.user.lastname}</h5>
                    <p className="text-muted mb-1  ms-3"><span dangerouslySetInnerHTML={{ __html: item.lastComment.comments }} /></p>
                    
                  </div>)

                } 
                    
            }

        }
        
    ],
    [projectStatus, CORRECT_STATUS,unSelectableProjectsIds,selectedProjectsIds,items,hiddenColumns]
);
    return (
        <div className="page-content">
       
        <Container fluid={true}>
            <LoadingContainer loading={loading} >
                <Card>
                    <CardBody>
                        <div className="mb-4">
                            <Row>
                                <Col xs="12">
                                    <div className="page-title-box justify-content-between">
                                        <div className="">
                                            <ol className="breadcrumb m-0">
                                                <BreadcrumbItem>.</BreadcrumbItem>
                                                <BreadcrumbItem>
                                                    <Link to="#">{props.t("PROJECTS")}</Link>
                                                </BreadcrumbItem>
                                            </ol>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>

                {/* Your JSX code here */}
                <TableContainer  columns={columns}
                            data={items}
                            isGlobalFilter={true}
                            isAddOptions={false}
                            iscustomPageSizeOptions={true}
                            customPageSize={10}
                            isPagination={true}
                            tableClass="align-middle table-nowrap table-check table"
                            theadClass="table-light"
                            paginationDiv="col-12"
                            pagination="justify-content-center pagination pagination-rounded" />
                </LoadingContainer>
            </Container>
    </div>
    );
};
PagesProjectsList.propTypes = {
    t: PropTypes.func
};
export default withTranslation()(PagesProjectsList);