import * as React from "react";
import {Fragment, useEffect, useState} from "react";
import {DIALOG_TYPE} from "../../Utility/Helpers/constants";
import AppModalComponent from "../../Utility/Helpers/AppModalComponent";
import DialogBoxComponent from "../../Utility/Helpers/DialogBoxComponent";
import { withTranslation } from "react-i18next"
import { Form, Label, FormFeedback} from "reactstrap"
import SelectType from "../../../helpers/Form/SelectType";

import {useFormik} from "formik";
import * as Yup from "yup";
import ProductsRepository from "../Repository/product-repository";
/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ImportProductModalPage = (props) => {
    // eslint-disable-next-line react/prop-types
    const {projectId, showModal = false, handleCloseModal = {}, onProductsImported = {}} = props;
    
    const [enabledModalLoading, setEnabledModalLoading] = useState(true);

    const [errors, setErrors] = useState([])
    const [backendResponse, setBackendResponse] = useState("")
    const [showError, setShowError] = useState(false)
    const [showResponse, setShowResponse] = useState(false)

    const [fileToImport, setFileToImport]= useState(null)

    const [projectUnmodifiable, setProjectUnmodifiable] = useState(false);

    const [projectUnmodifiableText, setProjectUnmodifiableText] = useState("");

    const fileSelected = (e)=>{
        const file = e.target.files[0]
        setFileToImport(file)
      }

    const importProductValidation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            separateur: null,
            file: ''
        },

        validationSchema: Yup.object({
            separateur: Yup.string(props.t("REQUIRED_FIELD")).required(props.t("REQUIRED_FIELD")),
            file: Yup.string(props.t("REQUIRED_FIELD")).required(props.t("REQUIRED_FIELD"))
        }),
        onSubmit: (values) => {
            setEnabledModalLoading(true)

            const formData = new FormData()
            formData.append("file", fileToImport)
            formData.append("separateur", values.separateur)

            ProductsRepository.importProducts(formData,projectId).then((response)=>{
                console.log("res:",response)
                //check isImported exist and is true
                if((response.isImported !=undefined && response.isImported==false) || response.logs.length>0)
                {
                    if (response.logs.length==1)
                    {
                        if (response.totalLines==0)
                            {
                                setBackendResponse(props.t("IMPORT_FAILED") );
                            }
                            else
                            {
                                setBackendResponse(props.t("IMPORT_FAILED") + ", " + response.logs.length + " " + props.t("INVALID_PRODUCT") + "." )
                            }
                    }
                    else
                    {
                        setBackendResponse(props.t("IMPORT_FAILED") + ", " + response.logs.length + "/"+ response.totalLines + " "  + props.t("INVALID_PRODUCTS") + "." )
                    }
                }
                else
                {
                    setBackendResponse( props.t("IMPORTED") + " " + response.importedLines +  " " + props.t("PRODUCTS") + " / " + response.totalLines + ".")
                }
                setErrors(response.logs)
                setEnabledModalLoading(false)
                setShowError(response.logs.length>0)
                setShowResponse(true)

            }).catch((response)=>{
                if(response.status==401){
                    setProjectUnmodifiable(true);
                    setProjectUnmodifiableText(response.data.error.message)
                }else{
                    console.log("error:",response)
                }
                setEnabledModalLoading(false)
            })

        }
      });

    useEffect(() => {
        setEnabledModalLoading(false)
    }, [projectId]);

    const closeModal = () => {
        onProductsImported();
        handleCloseModal();
    };
    
    const handleSubmitForm = (event) => {
        event.preventDefault()
        importProductValidation.handleSubmit(event);
    };

    return (
        <Fragment>
            <AppModalComponent
                enabledLoading={enabledModalLoading}
                handleClose={closeModal}
                handleClickSaveButton={handleSubmitForm}
                show={showModal}
                closeTitle={ props.t("CLOSE")}
                saveTitle={props.t("IMPORT_PRODUCTS")}
                enableConfirmBtn={true}
                title={props.t("IMPORT_PRODUCTS_FORM")}
            >
                 {
                showResponse && (
                    <div className={"mt-2 ms-1 me-1 row "+ (errors.length==0 ? "success" : "error") +"-box"}>
                        {backendResponse}
                    </div>
                )
                }
                {
                showError && (
                    <div className="row error-box ms-1">
                    <div className="error-box-title">
                        <p> <strong> {props.t("ERRORS_OCCURED")}</strong></p>
                        <button type="button" className="btn-close" aria-label="Close" onClick={()=>{setShowError(false)}}></button>
                    </div>
                    <ul>
                        {errors.map((error,i)=>{
                            //errors_details need to be parsed and the result need to have this structure
                            //lineIndex for the line of the error occured
                            // message for the error message nees to pass with transalation
                            // validationERROR list of message to display with ; separator

                            //show the errors in the following format  Message -> lineIndex -> validationERROR list of message to display with ; separator


                            let errors_details = JSON.parse(error);
                            /*let errorParts = error.split(",")
                           let subErrorParts = errorParts[1].split(":")
                            //we have error in json format, now let parse it
                            //let subErrorParts = JSON.parse(subErrorParts[1])*/
                            let err_mess = [];
                            if (errors_details.validationERROR)
                            {
                                err_mess = errors_details.validationERROR.split(";")
                            }
                            return (
                                <li key={i}> 
                                    <code>{props.t(errors_details.message,{lineIndex : errors_details.lineIndex})} : </code>
                                    

                                    
                                        <ul>
                                            {
                                                err_mess.map((subError, index)=> <li key={index}>
                                                        {subError}
                                                    </li>
                                                )
                                            }
                                        </ul>
                                  
                                </li>
                            )
                        })}
                    </ul>
                    </div>
                )
                }
                {
                projectUnmodifiable && (
                    <div className="row error-box ms-1">
                    <div className="error-box-title">
                        <p> <strong> {props.t("ERRORS_OCCURED")}</strong></p>
                        <button type="button" className="btn-close" aria-label="Close" onClick={()=>{setProjectUnmodifiable(false); handleCloseModal()}}></button>
                    </div>
                    <ul>
                        <li> 
                            <code> {projectUnmodifiableText} </code>
                        </li>
                    </ul>
                    </div>
                )
                }
                <Form onSubmit={(e) => handleSubmitForm(e)}>
                    <div className="row main-form-card pt-3 pb-0 ps-5 pe-5">
                        <div className="col-md-8 col-12">
                            <div className="mb-3">
                                <Label className="form-label">{props.t("FIELD_SEPARATOR")} *</Label>
                                <SelectType
                                    id={"separateur"}
                                    name={"separateur"}
                                    choices={
                                        [
                                            { value: ";", text: ";"},
                                            { value: ",", text: ","},
                                            { value: " ", text: "SPACE"}
                                        ]
                                    }
                                    value={importProductValidation.values.separateur || ""}
                                    className={"input-form"}
                                    onChange={importProductValidation.handleChange}
                                    placeholder={props.t("SELECT_FIELD_SEPARATOR")}
                                />
                                {importProductValidation.touched.separateur && importProductValidation.errors.separateur && (
                                    <FormFeedback type="invalid" className="d-block invalid">{props.t("REQUIRED_FIELD")}</FormFeedback>
                                )}
                            </div>

                            <div className="mb-3">
                                <Label className="form-label">{props.t("FILE_TO_IMPORT")} *</Label>
                                <br />
                                <input
                                    id={"file"}
                                    name={"file"}
                                    type={"file"}
                                    className={"input-form"}
                                    value={importProductValidation.values.file || ""}
                                    onChange={(e)=>{fileSelected(e); importProductValidation.handleChange(e)}}
                                    placeholder={props.t("SELECT_FILE_TO_IMPORT")}
                                />
                                {importProductValidation.touched.file && importProductValidation.errors.file && (
                                    <FormFeedback type="invalid" className="d-block invalid">{props.t("REQUIRED_FIELD")}</FormFeedback>
                                )}
                            </div>
                        </div>
                    </div>
                </Form>
            </AppModalComponent>
        </Fragment>
    );
};
export default withTranslation()(ImportProductModalPage);
