import React from "react";
import {Link, useParams} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import {ReferenceValueEditComponent} from "ppm-react-references";
import {REFERENCE_VALUES_API_URL, REFERENCE_COMPANIES_API_URL,REFERENCES_API_URL} from "../../helpers/api-urls";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next";

const PagesEditReferenceValues  = (props) =>{

    const {history, referenceId, referenceValueId} = props;
    const baseUrl = "/pages-references/"+referenceId
    const onEditSuccess = (response) => {
        history.push(baseUrl);
    }
    return (
        <>
            <div className="page-content">
                <MetaTags>
                    <title>{props.t("REFERENCE_VALUES")}</title>
                </MetaTags>

                <div className="mb-4">
                    <Breadcrumbs title={props.t("REFERENCE_VALUES")} breadcrumbItem={props.t("REFERENCE_VALUES")+ " - " + ( referenceValueId ? props.t("EDIT") : props.t("ADD"))}/>
                    <Link className="btn btn-info" to={baseUrl}>{props.t("RETURN_TO_LIST")}</Link>
                </div>
                <ReferenceValueEditComponent 
                    referenceValueId={referenceValueId}
                    referenceId={referenceId}
                    onEditSuccess={onEditSuccess}
                    apiUrl={REFERENCE_VALUES_API_URL}
                    apiCompanyUrl={REFERENCE_COMPANIES_API_URL}
                    apiReferenceUrl={REFERENCES_API_URL}
                    addUrl={baseUrl+"/add"}
                    path={baseUrl}
                />
            </div>
        </>
    )
}

export default withTranslation()(PagesEditReferenceValues)
