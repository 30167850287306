/**
 * UserProvider
 * Enable to manage user session and acces authorization.
 *
 * @since      2.0.0
 * @package    App
 * @subpackage App\Core\Authorization
 */
import axios from "axios";
export const DATA_API_URL = process.env.REACT_APP_API_BASE_URL + 'userlg';

const UserProvier = {
    /**
     * Récupère l'utilisateur courant ou renvoit null si non existant
     */
    get: () => {
        return JSON.parse(localStorage.getItem("authUser"));
    },

    roles: () => {
        const user = UserProvier.get();
        if (user != null){
            const myroles = []
            
            if(user.permissions){
                 /*axios.get(DATA_API_URL + '/getmyroles/'+user.id).then(
                 (items) =>{
                    
                    items.data.forEach(element => {
                        user.permissions.push(element.role)
                        myroles.push(element.role)
                    });
                    
                    
                }       
                );*/
             //   console.log(myroles);
                return [ ...user.permissions, "ROLE_USER"];
            }
        }else{
            return ["ROLE_USER"];
        }
        return [];
    },
    token: () => {
        const user = UserProvier.get();
        //console.log(user);
        if (user != null)
            return user.token;
        return null;
    },
    setToken: () => {
        const token = UserProvier.token();
        if (token != null) {
            axios.defaults.headers['Authorization'] = "Bearer " + token;
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}` 
        } else {
            console.log("Not token found.")
            delete axios.defaults.headers['Authorization']
            delete axios.defaults.headers.common['Authorization']
        }
    },
}
export default UserProvier;
