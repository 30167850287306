import axios from "axios";
import {HTTP_RESPONSE} from "../../Utility/Helpers/constants";
import UserProvier from "../../../security/UserProvier";
import AuthorizationChecker from "../../../security/AuthorizationChecker";

UserProvier.setToken();

export const DATA_API_URL_PROJECT_COMMENTS = process.env.REACT_APP_API_BASE_URL + 'project-comments';
export const DATA_API_URL_ECOM = process.env.REACT_APP_API_BASE_URL + 'projects-ecom';

const queries = (projectId,itemPerPage = 15, page = 1,search = '', querying = '') => {
    /*return getApiUrl() + '?filter[where][or][0][name][like]=%'+search+'%' +
        '&filter=[where][name][options]=i' +
        '&filter[where][or][1][projectId][like]=%'+search+'%' +
        '&filter=[where][projectId][options]=i' +
        "&filter[include][]=compteSupplier"+
        "&filter[include][]=niveau1"+
        '&filter[order]=id DESC'+
        '&filter[limit]=' + itemPerPage + '&filter[skip]=' + (((page - 1) * itemPerPage) > 0 ? ((page - 1) * itemPerPage) : 0);*/
      //build the query, projectId must be a number include the user into filter
        return DATA_API_URL_PROJECT_COMMENTS + '?filter[where][projectId]='+projectId+
        '&filter[include][]=user'+
        '&filter[limit]=' + itemPerPage + '&filter[skip]=' + (((page - 1) * itemPerPage) > 0 ? ((page - 1) * itemPerPage) : 0);

};

/**
 * Repository for project comments.
 * @namespace projectCommentsRepository
 */
const ProjectCommentsRepository = {
    /**
     * Find all comments for a project.
     * @memberof projectCommentsRepository
     * @param {string} projectId - The ID of the project.
     * @param {number} [page=1] - The page number (default: 1).
     * @param {number} [itemPerpage=10] - The number of items per page (default: 10).
     * @returns {Promise} A promise that resolves to the response data.
     * @throws {Object} If an error occurs, it throws an object with the error response.
     */
    findAll: (projectId, page = 1, itemPerpage = 10) => {
        return axios
            .get(queries(projectId, itemPerpage, page))
            .then(response => {
                return response.data;
            }).catch((e) => {
                if (e.response) {
                    throw e.response;
                } else {
                    throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND };
                }
            });
    },

    /**
     * Add a new comment to the project.
     * @memberof projectCommentsRepository
     * @param {Object} item - The comment object to add.
     * @returns {Promise} A promise that resolves to the response data.
     * @throws {Object} If an error occurs, it throws an object with the error response.
     */
    add: (item) => {
        return axios
            .post(DATA_API_URL_PROJECT_COMMENTS, item)
            .then(response => {
                return response.data;
            }).catch((e) => {
                if (e.response) {
                    throw e.response;
                } else {
                    throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND };
                }
            });
    },

    /**
     * Find a comment with full members.
     * @memberof projectCommentsRepository
     * @param {string} id - The ID of the comment.
     * @returns {Promise} A promise that resolves to the response data.
     * @throws {Object} If an error occurs, it throws an object with the error response.
     */
    findWithFullMembers: (id) => {
        return axios
            .get(DATA_API_URL_ECOM + '/findwithmember/' + id + "?filter[include][]=niveau1")
            .then(response => {
                console.log("response", response)
                return response.data;
            }).catch((e) => {
                if (e.response) {
                    throw e.response;
                } else {
                    throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND };
                }
            });
    },
}

export default ProjectCommentsRepository;
