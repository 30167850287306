import React, {Fragment} from 'react'

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const TextareaType = (props) => {
    const {
        id,
        name,
        onChange,
        error = '',
        placeholder = '',
        className = '',
        value = '', disabled = false,
        useLabel = false,
        text = '',
        onKeyPress = (e) => {
        },
        onFocus = (e) => {
        }
    } = props;

    const handleKeyPress = (e) => {
        if (onKeyPress != null)
            onKeyPress(e)
    }
    return (
        <Fragment>
            {useLabel && <label htmlFor={id} className="required">{text}</label>}
            <textarea id={id}
                      name={name}
                      disabled={disabled}
                      onChange={(e) => onChange(e)}
                      onFocus={(e) => onFocus(e)}
                      onKeyPress={(e) => handleKeyPress(e)}
                      className={(error.length && "is-invalid") + " form-control " + className}
                      placeholder={placeholder} value={value}/>
            {error && (
                <p className="invalid-feedback">
                    {error}
                </p>)}
        </Fragment>
    );
};
export default TextareaType;
