import { useHistory } from "react-router-dom";

/**
 * Permet de rediriger vers une page donnée
 * @param url
 */
import {SERVER_BASE_URL} from "./api-urls";

export const redirectTo = (url, refresh = true) => {
    // if(refresh)
    //     window.location.replace(url);
    // else{
    //     let history = useHistory();
    //     history.push(url);
    // }
    window.location.replace(url);
};


/**
 * Formats the size
 */
export const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}


/**
 * Renvoit le chemin vers l'image
 */
export const getFileUrl = (fileId) => {
    return SERVER_BASE_URL + "uploads/files/" + fileId
}
