const FILES_API_URL = process.env.REACT_APP_API_BASE_URL + 'files';
export const SERVER_BASE_URL = process.env.REACT_APP_BASE_URL;
export const USERS_API_URL = process.env.REACT_APP_API_BASE_URL + 'users';
export const TAGS_API_URL = process.env.REACT_APP_API_BASE_URL + 'tags';
export const FILES_SYSTEM_API_URL = process.env.REACT_APP_API_BASE_URL + 'files-system';
export const FILES_SYSTEM_FOLDER_API_URL = process.env.REACT_APP_API_BASE_URL + 'folders';
export const PARAMETERS_API_URL = process.env.REACT_APP_API_BASE_URL + 'parameters';


export default FILES_API_URL;
