import React, {Fragment} from 'react'
import AsyncSelect from 'react-select/async';


/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const AutocompleteSelectType = (props) => {
    // eslint-disable-next-line react/prop-types
    const {id, name, choiceAttr="name", remoteChoices, onChange, error = '', menuPlacement = 'bottom', className = 'w-100', isClearable = true, value = false, placeholder = '', getOptionLabel, showErrorText = true, noOptionMessage = 'Aucune entrée trouvée', multiple = false, locale = 'fr',
    isDisabled=false} = props;
    const defaultValue = ((value instanceof Object && value.id) || (value instanceof Array)) ? value : placeholder;

    return (
        <Fragment>
            <AsyncSelect
                id={id}
                onChange={(e) => {
                    const target = {
                        'name': name,
                        'value': e || []
                    };
                    onChange({target: target, e: e});
                }}
                menuPlacement={menuPlacement}
                isMulti={multiple}
                hideSelectedOptions={true}
                cacheOptions={true}
                // cacheOptions={true}
                // hideSelectedOptions={true}
                isClearable={isClearable}
                getOptionLabel={getOptionLabel ? getOptionLabel : (opt) => opt[choiceAttr]}
                getOptionValue={(opt) => opt.id}
                defaultOptions={true}
                defaultValue={defaultValue}
                value={defaultValue}
                className={className}
                placeholder={placeholder}
                loadOptions={remoteChoices}
                isDisabled={isDisabled}
                onClick={(e)=>{}}
            />
            {(error && showErrorText) && (
                <p className="invalid-feedback d-block">
                    {error}
                </p>)}
        </Fragment>
    );
};
export default AutocompleteSelectType;
