import axios from "axios";
import {HTTP_RESPONSE} from "../../Utility/Helpers/constants";
import UserProvier from "../../../security/UserProvier";
import AuthorizationChecker from "../../../security/AuthorizationChecker";

UserProvier.setToken();

export const DATA_API_URL = process.env.REACT_APP_API_BASE_URL + 'references';

const ReferenceRepository = { 

    uploadAttachment: async (item) => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        const formData = new FormData();
        // Update the formData object
        formData.append(
            "references",
            item,
            item.name,
        );
        try {
            const response = await axios
                .post(DATA_API_URL + '/import', formData, config);
                console.log(response);
            return response.data;
        } catch (e) {
            if (e.response) {
                throw e.response;
            } else {
                throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND };
            }
        }
    }

}

export default ReferenceRepository;