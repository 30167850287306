import React from "react";
import MetaTags from 'react-meta-tags';
import {UsersListComponent} from "ppm-react-authentication";
import {USERS_API_URL} from "../../helpers/api-urls";
import { Link } from "react-router-dom";
import AuthorizationChecker from "security/AuthorizationChecker";
import { withTranslation } from "react-i18next";
class PagesUsers extends React.Component {
    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        return <>
            <div className="page-content">
                <MetaTags>
                    <title>{this.props.t("USERS")}</title>
                </MetaTags>
                <UsersListComponent 
                    apiUrl={USERS_API_URL}
                    path="/pages-users"
                    addUrl="/pages-users/add"
                    Link={Link}
                    useLangue={true}
                    useCompanyName={true}
                    showEdit =  { AuthorizationChecker.isGranted(["EDIT_USERS"])} 
                    showDelete =  { AuthorizationChecker.isGranted(["DELETE_USERS"])} 
                    showView =  { AuthorizationChecker.isGranted(["READ_USERS"])}
                    showAdd =  { AuthorizationChecker.isGranted(["ADD_USERS"])}
                />
            </div>
        </>;
    }
}

export default withTranslation()(PagesUsers)
