import {toast} from "react-toastify";
import DialogBoxContentComponent from "./DialogBoxContentComponent";

const options = {
    position: "top-right",
    autoClose: 5000,
    closeButton: false,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
};

const Push = {
    info: (message) => {
        toast(DialogBoxContentComponent({title: 'Information', message: message.toString(), type: 'info'}), options);
    },
    success: (message) => {
        toast(DialogBoxContentComponent({title: 'Succès', message: message.toString(), type: 'success'}), options);
    },
    warning: (message) => {
        toast(DialogBoxContentComponent({title: 'Alerte', message: message.toString(), type: 'warning'}), options);
    },
    error: (message) => {
        toast(DialogBoxContentComponent({title: 'Erreur', message: message.toString(), type: 'danger'}), options);
    }
};

export default Push;
