import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { get, map } from "lodash";
import { withTranslation } from "react-i18next";

//i18n
import i18n from "../../../i18n";
import languages from "common/languages";
import axios from "axios";
import { USERS_API_URL } from "helpers/api-urls";
import UserProvier from "security/UserProvier";

const LanguageDropdown = () => {
  // Declare a new state variable, which we'll call "menu"
  const [selectedLang, setSelectedLang] = useState("");
  const [menu, setMenu] = useState(false);

  useEffect(() => {
    let currentLanguage = "en";
    if(localStorage.getItem("I18N_LANGUAGE")){
      currentLanguage = localStorage.getItem("I18N_LANGUAGE")
    }else{
      if(/^fr\b/.test(navigator.language)){
        currentLanguage = "fr";
      }
    }

    let user = UserProvier.get()
    if(user){
      // set language on backend
      axios.post(USERS_API_URL+"/"+user.id+"/setlangue/"+currentLanguage || en, {}).then((v)=>{
        //set language as i18n
        i18n.changeLanguage(currentLanguage);
        localStorage.setItem("I18N_LANGUAGE", currentLanguage);
        setSelectedLang(currentLanguage || "en");
      })
    }else{
      setSelectedLang(currentLanguage);
    }
  }, [])

  const changeLanguageAction = lang => {
    let user = UserProvier.get()
    if(user){
      // Update language on backend
      axios.post(USERS_API_URL+"/"+user.id+"/setlangue/"+lang || en, {}).then((v)=>{})
    }

    //set language as i18n
    i18n.changeLanguage(lang);
    localStorage.setItem("I18N_LANGUAGE", lang);
    setSelectedLang(lang || "en");
  }

  const toggle = () => {
    setMenu(!menu);
  }

  return (
    <>
      <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block">
        <DropdownToggle className="btn header-item " tag="button">
        <span className="align-middle">
                <b>{get(languages, `${selectedLang}.lang`)}</b>
              </span>
          
        </DropdownToggle>
        <DropdownMenu className="language-switch dropdown-menu-end">
          {map(Object.keys(languages), key => (
            <DropdownItem
              key={key}
              onClick={() => changeLanguageAction(key)}
              className={`notify-item ${selectedLang === key ? "active" : "none"
                }`}
            >
             
              <span className="align-middle">
                {get(languages, `${key}.label`)}
              </span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default withTranslation()(LanguageDropdown)
